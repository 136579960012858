import { Brands } from '@/types/student/design'
import _Vue from 'vue'

export default {
  install(Vue: typeof _Vue): void {
    const setSozo = function (isSozo: boolean): void {
      Vue.prototype.$cookies.set('gdlsSozo', { isSozo: isSozo })
    }
    const isSozo = function (): boolean {
      const isStudentOfSouzouAcademy = Vue.prototype.$cookies.get('dataGdls')?.isStudentOfSouzouAcademy
      const cookie = Vue.prototype.$cookies.get('gdlsSozo')
      if (!cookie) return false

      return cookie.isSozo && isStudentOfSouzouAcademy
    }

    const isPageSozo = function (): boolean {
      const cookie = Vue.prototype.$cookies.get('gdlsSozo')
      if (!cookie) return false
      return cookie.isSozo
    }

    const setV3 = function (isV3: boolean, isSozo = false): void {
      Vue.prototype.$cookies.set('gdlsV3', { isV3: isV3 }, '1y')
      setSozo(isSozo)
    }
    const isV3 = function (): boolean {
      const cookie = Vue.prototype.$cookies.get('gdlsV3')
      if (!cookie) return false

      return cookie.isV3
    }

    const setSiteBrand = function (brandName: Brands): void {
      Vue.prototype.$cookies.set('siteBrand', { siteBrand: brandName }, '1y')
    }

    const getSiteBrand = function (): string {
      const cookie = Vue.prototype.$cookies.get('siteBrand')

      return cookie?.siteBrand || ''
    }

    const setToolPage = function (isToolPage: Brands): void {
      Vue.prototype.$cookies.set('toolPage', { isToolPage }, '1y')
    }

    const isToolPage = function (): string {
      const cookie = Vue.prototype.$cookies.get('toolPage')
      return cookie?.isToolPage || ''
    }

    Vue.prototype.$gdlsCookiesV3 = {
      setV3,
      isV3,
      setSozo,
      isSozo,
      isPageSozo,
      setSiteBrand,
      getSiteBrand,
      setToolPage,
      isToolPage,
    }
  },
}
