



































































import { Component, Watch, Mixins, Ref, Vue } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import LabelBase from '@/components/atoms/LabelBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import FillRound from '@/components/atoms/FillRound.vue'
import InputWithSearch from '@/components/molecules/InputWithSearch.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import UnitInformation from '@/components/organisms/UnitInformation.vue'
import StudentBottomPanel from '@/components/organisms/StudentBottomPanel.vue'
import LessonStudent from '@/mixins/action-cable/LessonStudent/index'
import VueRouter from 'vue-router'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'
import ModalUnitCount from '@/components/organisms/ModalUnitCount.vue'

Component.registerHooks(['beforeRouteUpdate'])

export type Unit = {
  curriculumSUnitId: number
  subjectCode: string
  name: string
  isCompleted: boolean
}

@Component({
  components: {
    SidebarSwitcher,
    LabelBase,
    ColoredBox,
    ButtonBase,
    FillRound,
    InputWithSearch,
    SelectBase,
    UnitInformation,
    StudentBottomPanel,
    ModalUnitCount,
  },
})
export default class CurriculumSUnits extends Mixins(LessonStudent, ClassModeMethods, ClassModeChangeAble) {
  private gradeOptionDatas = [
    { text: '小1', value: 's1' },
    { text: '小2', value: 's2' },
    { text: '小3', value: 's3' },
    { text: '小4', value: 's4' },
    { text: '小5', value: 's5' },
    { text: '小6', value: 's6' },
    { text: '中1', value: 't1' },
    { text: '中2', value: 't2' },
    { text: '中3', value: 't3' },
  ]

  private subjectNames = {
    su: '算数/数学',
    ei: '英語',
    ko: '国語',
    sh: '社会',
    ri: '理科',
  }

  private gradeSelectedData = Vue.prototype.$cookies.get('dataGdls').curriculumSUnitsGrade || ''

  private statusOptionDatas = [
    { text: 'すべて', value: '0' },
    { text: '未学習', value: '1' },
    { text: '学習済み', value: '2' },
  ]

  private statusSelectedData = '0'

  private unitDatas: Unit[] = []

  public classMode(): string {
    return Vue.prototype.$cookies.get('dataGdls').classMode
  }

  private subjectCode = Vue.prototype.$cookies.get('dataGdls').subjectCode

  private pickUpUnitData: any | null = null

  private inputCurriculumSUnitName?: string

  private noticeCurrentPageText = '小項目選択中'

  private noticePageTitle = ''

  private skipKK = false
  private classPolicyKKIsEnabled = false

  private colorType = 'gray'

  @Ref() modalUnitCount!: ModalUnitCount

  private async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.checkClassModeAndSideMenuMode()
    await this.loadDatas()
    if (this.classMode() === this.CLASS_MODE.NORMAL) await this.classPolicies()
    this.noticeGroup()

    await this.getExistSupporterCallHistory()
    Vue.prototype.$loading.complete()
  }

  public beforeRouteUpdate(to: VueRouter, from: VueRouter, next: any) {
    console.info('-- CurriculumSUnits beforeRouteUpdate')

    this.noticeGroup()

    next()
  }

  private async noticeGroup() {
    // このページでは固定文言のためページ遷移時またはbeforeRouteUpdate時に現在ページを伝える
    this.noticeOrStackCurrentPage({ currentPage: this.noticeCurrentPageText, pageTitle: this.noticePageTitle })

    // router.pushではsetTimeoutがクリアされないためセットする前にクリアして初期化しておく
    const setTimeoutIdQueueSize = await this.$store.dispatch('queue/size', 'setTimeoutIdQueue')
    for (let i = 0; i < setTimeoutIdQueueSize; i++) {
      const setTimeoutId = await this.$store.dispatch('queue/dequeue', 'setTimeoutIdQueue')
      clearTimeout(setTimeoutId)
    }

    // 5分、10分経過後にポイントを付与する
    this.noticeOrStackCurrentPageDelayed(10, { point: 5 })
    this.noticeOrStackCurrentPageDelayed(20, { point: 10 })
  }

  private async loadDatas(): Promise<void> {
    await Vue.prototype.$http.httpWithToken
      .get('/publisher_curriculums_s_units', {
        params: {
          subjectCode: this.subjectCode,
          gradeCode: this.gradeSelectedData,
          status: this.statusSelectedData,
          curriculumSUnitName: this.inputCurriculumSUnitName,
        },
      })
      .then((res: any) => {
        this.unitDatas = res.data.publisherCurriculumsSUnits
        this.gradeSelectedData = res.data.meta.gradeCode
        if (res.data.meta.recommendCurriculumSUnit) {
          // おすすめ項目の設定
          this.pickUpUnitData = res.data.meta.recommendCurriculumSUnit
        } else {
          this.pickUpUnitData = null
        }
      })
      .catch((error: any) => {
        if (error.response.status !== 401) {
          this.$router.push('/error/error400')
          throw new Error(error)
        }
      })
  }

  private callSupporterClicked() {
    if (this.colorType === 'gray') {
      this.colorType = 'darkgray'
    }
    this.callSupporter(true)
  }

  private async getExistSupporterCallHistory() {
    if (this.lessonId) {
      try {
        const res = await Vue.prototype.$http.httpWithToken.get(`/lessons/${this.lessonId}/supporterCallHistory`)
        this.colorType = res.data.existSupporterCallHistory ? 'darkgray' : 'gray'
      } catch (e) {
        console.error(`サポーターを呼ぶボタンの履歴取得でエラーが発生しました。 ${e}`)
        throw e
      }
    }
  }

  private async classPolicies() {
    await Vue.prototype.$http.httpWithToken
      .get('/classPolicies/student', { params: { lessonId: this.lessonId } })
      .then((res: any) => {
        const classPolicyKK = res.data.find((policiy: { code: string; isEnabled: boolean }) => {
          return policiy.code === 'KK'
        })
        this.classPolicyKKIsEnabled = classPolicyKK.isEnabled
      })
  }

  @Watch('gradeSelectedData')
  private async gradeSelectedDataChange(newValue: string, oldValue: string): Promise<void> {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie['curriculumSUnitsGrade'] = newValue
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
    // 初回は除く
    if (oldValue) await this.changeConditions()
  }

  @Watch('statusSelectedData')
  private async statusSelectedDataChange(): Promise<void> {
    await this.changeConditions()
  }

  /**
   * 条件変更時の処理
   */
  private async changeConditions(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.loadDatas()
    Vue.prototype.$loading.complete()
  }

  @Watch('lessonId')
  private async lessonIdChanged(): Promise<void> {
    if (this.classMode() === this.CLASS_MODE.NORMAL) await this.classPolicies()
    await this.getExistSupporterCallHistory()
  }

  /**
   * 学習を終了するリンク
   * 授業中の場合は授業IDを、授業終了している場合は直近に終了した授業終了30分以内の授業IDを返す
   *
   * @return 学習週を終了するリンク
   */
  private get reviewUrl(): string {
    return `/student/review/${this.lessonId || this.justFinishedLessonId}`
  }

  /**
   * 前回の学習記録表示
   */
  public showModalUnitCount(unit: Unit) {
    this.modalUnitCount.showModal(unit.curriculumSUnitId, unit.name)
  }

  /**
   * クレジットリンク表示ステータス
   */
  private get isShowCreditLink() {
    return this.subjectCode == 'sh' && this.classMode() == this.CLASS_MODE.NORMAL
  }
}
