







































































































































































import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ScrollGuide from '@/components/modules/drills/atoms/ScrollGuide.vue'
import ImageExpansion from '@/components/modules/drillsv3/molecules/ImageExpansion.vue'
import ImageMixin from '@/components/modules/drillsv3/molecules/ImageMixin'
import ModalChat from '@/components/modules/drillsv3/organisms/ModalChatMath.vue'
import ModalHint from '@/components/modules/drillsv3/organisms/ModalHintMath.vue'
import ModalMemoMath from '@/components/modules/drillsv3/organisms/ModalMemoMath.vue'
import ModalMyAnswer from '@/components/modules/drillsv3/organisms/ModalMyAnswer.vue'
import RadioOkNg from '@/components/molecules/RadioOkNg.vue'
import DrillMethod from '@/mixins/drillsv3/DrillMethod'
import MemoMathMethod from '@/mixins/drillsv3/MemoMathMethod'
import DrillWebApi, { ResultProblem } from '@/mixins/drillsv3/WebApi'
import { PAGE_TYPE } from '@/store/modules/DrillsV3'
import { Component, Mixins, Prop, Ref, Vue } from 'vue-property-decorator'
import QuestionImageHorizontalV3 from './QuestionImageHorizontalV3.vue'

@Component({
  components: {
    ButtonBase,
    RadioOkNg,
    ImageExpansion,
    ScrollGuide,
    ModalHint,
    ModalMyAnswer,
    DrillMethod,
    ModalChat,
    QuestionImageHorizontalV3,
    ModalMemoMath,
  },
})
export default class DrillCorrectMath extends Mixins(ImageMixin, DrillWebApi, DrillMethod, MemoMathMethod) {
  @Ref() modalMyAnswer!: ModalMyAnswer
  @Ref() modalMemoMath!: ModalMemoMath
  @Ref() modalChat!: ModalChat
  @Ref() modalHint!: ModalHint

  private loadComplete = false
  private mysAnswers = {}
  private currentMyAnswer = { url: '' }
  private isEditModeMemo = false
  private currentMemoInfo = { pageNumber: 0, question_code: '' }
  // s3アクセス用フルURL
  private kaitouUrl: string[][] = []
  private hiddenUiMemoMath = {}
  private get curriculumSUnitId() {
    return this.$store.getters['drillsV3/curriculumSUnitIds'][0]
  }

  @Prop()
  customParam?: {
    currentPageType?: string
    classCategoryCode?: string
  }

  @Prop()
  isHiddenButtonQuestion!: boolean

  @Prop({ default: { problemInfoByProblemId: {}, isShowActionButton: false } })
  actionProps!: {
    problemInfoByProblemId: Partial<any>
    isShowActionButton?: boolean
  }

  @Prop({ default: false })
  isTeacher!: boolean

  @Prop({ default: false })
  isReport!: boolean

  @Prop()
  resultProblems!: {
    pageNum: number
    problems: ResultProblem[]
  }[]

  private get subjectCode() {
    return this.$store.getters['drillsV3/subjectCode']
  }

  private get isMathSubject() {
    return this.subjectCode === 'su'
  }

  private get isVertical() {
    // 国語の場合はtrue
    return this.subjectCode === 'ko'
  }

  private get isTypeResult(): boolean {
    // ページタイプが結果系画面の場合はtrue
    if (this.pageType === PAGE_TYPE.RESULT_ALL) {
      return true
    }
    if (this.pageType === PAGE_TYPE.RESULT_UNIT) {
      return true
    }
    return false
  }

  /**
   * クエリのページ指定(指定なしの場合は1)
   */
  private get queryPage(): number {
    return Number(this.$route.query.page) || 1
  }

  private get corrects(): any[] {
    return this.$store.getters['drillsV3/corrects']
  }

  private get correctsQuestionCode(): string[] {
    return this.$store.getters['drillsV3/correctsQuestionCode']
  }

  private get moviePlayed(): any[] {
    return this.$store.getters['drillsV3/moviePlayed']
  }

  /**
   * 該当の問題の解説動画が視聴済みかを判定
   */
  private isMoviePlayed(pageIndex: number, problemIndex: number): boolean {
    return this.moviePlayed[pageIndex][problemIndex] || false
  }

  private handleClickAction(
    questionCode: string,
    type: 'memo' | 'answer' | 'hint' | 'memoTeacher',
    options: {
      pageIndex: number
      questionIndex: number
    }
  ): void {
    const problemIndex = this.problemIndexFromQuestionIndex(options.pageIndex, options.questionIndex)
    const sProblemId = this.sProblemIds(options.pageIndex)[problemIndex]

    switch (type) {
      case 'answer':
        this.handleOpenAnswer(questionCode, { pageNumber: options.pageIndex + 1 })
        break

      case 'memo':
        this.handleOpenMemo(questionCode, sProblemId, { pageNumber: options.pageIndex + 1 })
        break

      case 'memoTeacher':
        this.openMemoMath(questionCode, 'teacher')  
        break

      default:
        this.openMemoMath(questionCode, 'student')
    }
  }

  private async handleOpenHint(
    questionCode: string,
    problemId: number,
    options: {
      pageNumber: number
    }
  ) {
    const isExistDataQuestion = !!(this.problemsInfo as any)[questionCode]
    if (isExistDataQuestion) {
      this.currentQuestion = (this.problemsInfo as any)[questionCode]
      this.modalHint.show()
      return
    }

    Vue.prototype.$loading.start()
    const result = await this.handleGetOneProblemInfo(this.actionProps.problemInfoByProblemId[problemId], {
      page: options?.pageNumber,
    })
    Vue.prototype.$loading.complete()
    this.problemsInfo = { ...this.problemsInfo, ...result }

    this.currentQuestion = this.problemsInfo[questionCode]
    this.modalHint.show()
  }

  private async handleOpenMemo(
    questionCode: string,
    problemId: number,
    options: {
      pageNumber: number
      type?: 'teacher' | 'student'
    }
  ) {
    Vue.prototype.$loading.start()
    const result = await this.getMemoInfo({
      page_num: options.pageNumber.toString(),
      result_drill_id: this.$route.query.resultDrillId as string,
      question_code: questionCode,
    })
    Vue.prototype.$loading.complete()
    const subjectCode = this.actionProps.problemInfoByProblemId[problemId].subjectCode
    this.currentMemoInfo = { ...this.currentMemoInfo, pageNumber: options.pageNumber, question_code: questionCode }
    const imageM = subjectCode === 'ko' ? (result.memoPath?.m || []).reverse() : result.memoPath?.m || []
    const imageD = subjectCode === 'ko' ? (result.memoPath?.d || []).reverse() : result.memoPath?.d || []
    const imgArr = [...imageM, ...imageD]
    this.isEditModeMemo = this.isTeacher ? (options.type === 'teacher' ? true : false) : false

  }

  private get getResultDrillId() {
    return this.$route.query.resultDrillId
  }

  private async handleOpenAnswer(
    questionCode: string,
    options: {
      pageNumber: number
    }
  ) {
    const isExistMyAnswer = !!this.mysAnswers[questionCode]

    if (isExistMyAnswer) {
      this.currentMyAnswer = this.mysAnswers[questionCode]
      this.modalMyAnswer.show()
      return
    }

    const resultProblem = await this.getResultProblem(
      parseInt(this.$route.query.resultDrillId as string),
      options.pageNumber
    )

    const result = await this.handleGetImageMyAnswer(
      resultProblem.problems,
      this.$route.query.resultDrillId as string,
      { isTeacher: this.isTeacher, encodeGdls: resultProblem?.userDrillCode }
    )

    this.mysAnswers = { ...this.mysAnswers, ...result }

    this.currentMyAnswer = this.mysAnswers[questionCode]

    this.modalMyAnswer.show()
  }

  private async handleSimilarOrRetryHint(type: 'challenge_similar' | 'challenge_retry') {
    try {
      Vue.prototype.$loading.start()
      const result = await Vue.prototype.$http.httpWithToken.post(`/v3/history/question_challenges`, {
        question_code: this.currentQuestion.questionCode,
        history_type: type,
        result_drill_id: this.getResultDrillId,
      })
      Vue.prototype.$loading.complete()
      if (result?.data?.status === 200) {
        alert('問題をチャレンジに保存しました。')
      }
    } catch (err) {
      const codeRes = (err as any)?.response?.data?.status
      if (codeRes === 400) {
        alert('問題は既にチャレンジに保存しました。')
      }
    }
  }

  private get propsRetry() {
    return {
      onClick: () => this.handleSimilarOrRetryHint('challenge_retry'),
      isHidden: !this.isTeacher,
    }
  }

  private get propsSimilar() {
    return {
      onClick: () => this.handleSimilarOrRetryHint('challenge_similar'),
      isHidden: !this.isTeacher,
    }
  }

  /**
   * ストアから現在のページタイプを取得する
   *
   * @returns ページタイプ
   */
  protected get pageType(): string {
    return this.$store.getters['drillsV3/pageType']
  }

  protected get paramsMemoMath() {
    return {
      curriculumSUnitId: this.curriculumSUnitId,
      resultDrillId: this.getResultDrillId
    }
  }

  private showTextExplain() {
    console.log('Show')
  }

  private async mounted() {
    Vue.prototype.$loading.startWhite()

    // s3アクセス用URL取得
    for (let i = 0; i < this.problemLength; i++) {
      this.kaitouUrl[i] = await Promise.all(
        this.kaitou(i).map(async (path) => {
          return await this.getImageUrl(path)
        })
      )
    }

    if (this.resultProblems?.length) {

      let resultProblem: any = {};


      if (this.isTeacher) {
        resultProblem = await this.getResultProblem(
          parseInt(this.$route.query.resultDrillId as string),
          1
        )
      }
      this.isFirstTargetMemoMath = this.getIsFirstTimeTarget(this.resultProblems)


      const result = await Promise.all(this.resultProblems.map(async (item) => {
        const myAnswers = await this.handleGetImageMyAnswer(
          item.problems as any,
          this.getResultDrillId.toString(),
          { isTeacher: this.isTeacher, encodeGdls: resultProblem?.userDrillCode }
        )

        return Object.keys(myAnswers).map((questionCode) => ({
          memoUrl: '',
          page: item.pageNum,
          questionCode,
          questionUrls: [],
          answerUrl: (myAnswers as any)[questionCode]?.url || '',
          classCategoryId: 0,
          classModeId: 0,
          memoUrlTeacher: ''
        }))
      }))

      this.questionsMemoMath = result.reduce((newResult, item) => ([...newResult, ...item]), [])

    }

    this.loadComplete = true
    Vue.prototype.$loading.complete()
  }

  private updateCorrectInput(event: boolean, pageIndex: number, questionIndex: number) {
    Vue.prototype.$logger.log(`updateCorrectInput ${event} ${pageIndex} ${questionIndex}`)

    this.$store.commit('drillsV3/setCorrectDetail', {
      value: event,
      pageIndex: pageIndex,
      questionIndex: questionIndex,
    })
  }

  /**
   * ページ番号
   *
   * @param pageIndex ページインデックス
   * @return ページ番号
   */
  private pageNumber(pageIndex: number): number {
    return this.queryPage + pageIndex
  }

  /**
   * 解説動画画面に遷移する
   */
  public transferExplanatoryVideo(pageIndex: number, questionIndex: number): void {
    const problemIndex = this.problemIndexFromQuestionIndex(pageIndex, questionIndex)
    const sProblemId = this.sProblemIds(pageIndex)[problemIndex]
    const version = this.$route.params.type || 'v3'
    let path = '/student/v3/explanatory-video'
    if (this.isReport) {
      const content: string = (this.$route.query.content as string) || ''
      const type = this.$route.query.type || ''
      path = `/${Vue.prototype.$cookies.get('authGdls').webRole
        }/report/${version}/explanatory-video?content=${encodeURIComponent(content)}&type=${type}`
    }
    this.$router.push({
      path: path,
      query: {
        sProblemId: String(sProblemId),
        resultDrillId: this.$route.query.resultDrillId,
        historyBackUrl: encodeURIComponent(this.$route.fullPath),
      },
    })
  }

  /**
   * 動画解説ボタンの文言を取得
   */
  private movieButtonMessage(pageIndex: number, questionIndex: number): string {
    // ページ番号を取得
    const pageNum = this.pageNumber(pageIndex)
    let title = ''
    let problemIndex = 0
    if (this.isLProblem(pageIndex)) {
      // 大問の場合は「大問1（ページ番号）」と表示
      title = `大問${pageNum}`
    } else {
      // 小問の場合、設問数で判定を行う
      problemIndex = this.problemIndexFromQuestionIndex(pageIndex, questionIndex)
      const questionNumber = this.questionNumber(pageIndex)[problemIndex]
      if (questionNumber === 1) {
        // 小問と設問が1対1の場合は「1-1（ページ番号-小問番号）」と表示
        title = `${pageNum}-${questionIndex + 1}`
      } else {
        // 小問と設問が1対多の場合は「1-1〜1-3（ページ番号-小問番号〜ページ番号-小問番号）」と表示
        const startNumber = this.questionNumber(pageIndex)
          .slice(0, problemIndex)
          .reduce((sum, element) => sum + element, 0)
        title = `${pageNum}-${startNumber + 1}〜${pageNum}-${startNumber + questionNumber}`
      }
    }

    const message = this.isMoviePlayed(pageIndex, problemIndex) ? '視聴済み' : '視聴する'
    // const message = '視聴する'
    return `${title} ${message}`
  }

  private async handleSaveMemo(imageFle: File) {
    Vue.prototype.$loading.start()
    await this.saveMemo({
      page_num: this.currentMemoInfo.pageNumber.toString(),
      result_drill_id: this.$route.query.resultDrillId as string,
      question_code: this.currentMemoInfo.question_code,
      memo_img: imageFle,
    })

    Vue.prototype.$loading.complete()

    // this.modalMemo.cancel()
  }

  private clickQuestion() {
    this.modalHint.hide()
    this.modalChat.showModal()
  }

  //memo Math only
  private async openMemoMath(questionCode: string, type: 'teacher' | 'student') {
    if (type === 'student') {
      this.hiddenUiMemoMath = {
        pen: true,
        button: false,
        buttonRetry: true
      }
    } else {
      this.hiddenUiMemoMath = {
        pen: false,
        button: false,
        buttonHint: !this.isTeacher,
        buttonRetry: !this.isTeacher
      }
    }
    const data = await this.getMemoDataWithQuestion(questionCode)
    this.modalMemoMath.setCurrentQuestion(data)
    this.modalMemoMath.showModal()
  }

  private async clickTabMemoMath(questionCode: string, other?: { isRefetch?: boolean }) {
    const data = await this.getMemoDataWithQuestion(questionCode, other)
    this.modalMemoMath.setCurrentQuestion(data)
  }

  private getIsFirstTargetFollowQuestion(questionCode: string) {
    return this.isFirstTargetMemoMath?.[questionCode]
  }

  private handleSaveMemoSuccess(questionCode: string) {
    this.isFirstTargetMemoMath[questionCode] = false
  }

  protected get isShowOcrLlmService(): string {
    return Vue.prototype.$cookies.get('dataGdls')?.showOcrLlmService
  }
}
