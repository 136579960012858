







































import { Component, Vue, Mixins, Watch, Ref } from 'vue-property-decorator'
import FillRound from '@/components/atoms/FillRound.vue'
import ImageRound from '@/components/atoms/ImageRound.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import NowDateTime from '@/components/atoms/NowDateTime.vue'
import LessonStudent from '@/mixins/action-cable/LessonStudent/index'
import LoginMethods from '@/components/organisms/LoginMethods.vue'
import ModalNoteReport from '@/components/modules/drillsv3/organisms/ModalNoteReport.vue'
import PropertiesDesign from '@/mixins/v3/PropertiesDesign'
import NursingBranch from '@/mixins/v3/NursingBranch'

@Component({
  components: {
    FillRound,
    ImageRound,
    ColoredBox,
    ButtonBase,
    ModalNoteReport,
  },
})
export default class StudentHeader extends Mixins(
  NowDateTime,
  LessonStudent,
  LoginMethods,
  PropertiesDesign,
  NursingBranch
) {
  private colorType = 'blue'

  private active = 0
  private show = false

  @Ref() modalNoteReport!: ModalNoteReport

  // 生徒情報
  private get student(): { name: string; icon: string } {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    return {
      name: cookie?.userNickname || '',
      icon: cookie?.userIconUrl || '',
    }
  }

  private get siteBrand(): boolean {
    return Vue.prototype.$gdlsCookiesV3.getSiteBrand()
  }

  /**
   * 授業種別コード
   *
   * @return 授業種別コード
   */
  private get classCategoryCode(): string {
    return Array.isArray(this.$route.query.classCategoryCode)
      ? this.$route.query.classCategoryCode[0] || ''
      : this.$route.query.classCategoryCode || ''
  }

  private get showTimeLimit(): boolean {
    // 演習or宿題
    return (
      ['EN', 'SY'].includes(this.classCategoryCode) && 'restriction' in this.endCondition && 'left' in this.endCondition
    )
  }

  // 終了条件
  private get endCondition(): { string: any } {
    return this.$store.getters['drills/endCondition']
  }

  // 赤吹き出しの数字の前のテキスト
  private get beforeText(): string {
    if (!('restriction' in this.endCondition)) return ''
    let text = ''
    switch (this.endCondition['restriction']) {
      case 'count':
      case 'time':
        text = 'あと'
        break
      case 'score':
        text = 'あと最大'
        break
    }
    return text
  }

  // 赤吹き出しの数字の後のテキスト
  private get afterText(): string {
    if (!('restriction' in this.endCondition)) return ''
    let text = ''
    switch (this.endCondition['restriction']) {
      case 'count':
      case 'score':
        text = '問'
        break
      case 'time':
        text = '分'
        break
    }
    return text
  }

  private callSupporterClicked() {
    this.callSupporter(true)
  }

  goToSetting() {
    this.active = 1
    this.$router.push('/student/setting')
  }

  private async getExistSupporterCallHistory() {
    if (this.lessonId) {
      try {
        const res = await Vue.prototype.$http.httpWithToken.get(`/lessons/${this.lessonId}/supporterCallHistory`)
        this.colorType = res.data.existSupporterCallHistory ? 'blue' : 'pointer-events-none'
      } catch (e) {
        console.error(`サポーターを呼ぶボタンの履歴取得でエラーが発生しました。 ${e}`)
        throw e
      }
    }
  }

  private callNoteReportClicked() {
    this.modalNoteReport.showModal()
  }

  @Watch('lessonId')
  onLessonIdChanged() {
    this.getExistSupporterCallHistory()
  }

  private async mounted() {
    await this.getExistSupporterCallHistory()
    this.mode = this.MODE.STUDENT
    window.addEventListener('click', () => {
      this.show = false
    })
  }
}
