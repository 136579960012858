






















import { Component, Mixins, Vue } from 'vue-property-decorator'
import FillRound from '@/components/atoms/FillRound.vue'
import ImageRound from '@/components/atoms/ImageRound.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import DropdownMenu from '@/components/molecules/DropdownMenu.vue'
import NowDateTime from '@/components/atoms/NowDateTime.vue'
import LoginMethods from '@/components/organisms/LoginMethods.vue'
import LogoutMenu from '@/components/organisms/LogoutMenu.vue'
import { TEACHER_SETTING_TEXT } from '@/constants'
import NursingBranch from '@/mixins/v3/NursingBranch'

@Component({
  components: {
    FillRound,
    ImageRound,
    ColoredBox,
    ButtonBase,
    DropdownMenu,
    LogoutMenu,
  },
})
export default class TeacherHeader extends Mixins(NowDateTime, LoginMethods, NursingBranch) {
  private teacher = { name: '', icon: '' }
  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
  private isToolPage = Vue.prototype.$gdlsCookiesV3.isToolPage()

  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  private academyId = Vue.prototype.$cookies.get('dataGdls').academyId

  private dropdownMenuDatasV1: { url?: string; name: string; isHidden?: boolean; children?: object[] }[] = [
    { url: '/teacher/dashboard', name: '学習管理' },
    {
      name: '履歴管理',
      children: [
        { url: '/teacher/history', name: '履歴管理トップ' },
        { url: '/teacher/timeline', name: 'タイムライン', clickEvent: this.removeTimelineCookie },
        { url: '/teacher/branch-learning-situation', name: '教室学習状況確認' },
      ],
    },
  ]

  private dropdownMenuDatasV3HideCalendar: { url?: string; name: string; isHidden?: boolean; children?: object[] }[] = [
    { url: '/teacher/dashboard', name: '学習管理' },
    {
      name: '履歴管理',
      children: [
        { url: '/teacher/history', name: '履歴管理トップ' },
        {
          url: '/teacher/v3/timeline',
          name: 'タイムライン・メモ',
          clickEvent: this.removeTimelineCookie,
        },
      ],
    },
  ]

  private dropdownMenuDatas = this.isV3 ? this.dropdownMenuDatasV3HideCalendar : this.dropdownMenuDatasV1

  private async mounted() {
    // ログアウト用のモードを設定
    this.mode = this.MODE.TEACHER
    // 設定可能ユーザがログインした際、メニューに設定を追加
    const role = Vue.prototype.$cookies.get('authGdls').role as string
    const settableRoles = ['admin', 'academy', 'branch']
    let isShowOcrLlmService = false
    if (this.isV3) {
      this.dropdownMenuDatas[0].isHidden = this.isNursingBranch
      Vue.prototype.$http.httpWithToken.get(`/v3/enabledShowCalender?branch_id=${this.branchId}`).then((res: any) => {
        if (res.data.showCalendarAndGradeBook) {
          const dropdownMenuDatasClone = [...this.dropdownMenuDatas]
          dropdownMenuDatasClone.push({
            name: '予定共有・確認',
            isHidden: this.isNursingBranch,
            children: [
              { url: '/teacher/schedule/share', name: '予定共有' },
              { url: '/teacher/schedule/student', name: '予定表確認' },
            ],
          })
          this.dropdownMenuDatas = dropdownMenuDatasClone
        }
        if (this.dropdownMenuDatas[1] && this.dropdownMenuDatas[1].children) {
          this.dropdownMenuDatas[1].children.push({
            url: '/teacher/branch-learning-situation',
            name: '教室学習状況確認',
            isHidden: this.isNursingBranch,
          })
        }
        if (settableRoles.includes(role)) {
          this.dropdownMenuDatas.push({
            url: '/teacher/setting/top',
            name: TEACHER_SETTING_TEXT,
            isHidden: this.isNursingBranch,
          })
          if (this.dropdownMenuDatas[1] && this.dropdownMenuDatas[1].children) {
            this.dropdownMenuDatas[1].children.push({
              url: '/teacher/csv-download',
              name: '帳票出力',
            })
          }
        }
      })

      await Vue.prototype.$http.httpWithToken.get(`/v3/enabled_show_ocr_llm?branch_id=${this.branchId}`).then((res: any) => {
        isShowOcrLlmService = res.data.showOcrLlmService
        console.log(res.data.showOcrLlmService, res.data);

      })
    } else {
      if (settableRoles.includes(role)) {
        this.dropdownMenuDatas.push({ url: '/teacher/setting/top', name: '設定' })
      }
    }

    // ヘッダー情報反映
    const user = Vue.prototype.$cookies.get('dataGdls')
    this.teacher.icon = user.userIconUrl || '/img/teacher_sample.png'

    if (role == 'academy' && user.academyId) {
      // 塾管理者の場合は塾名がヘッダーに入る
      this.setAcademyName(user.academyId as number)
    } else {
      this.teacher.name = `${user.userNickname}先生`
    }

    this.setCookieLoginUserWithOverWrite({ showOcrLlmService: isShowOcrLlmService })

    this.$store.dispatch('common/setUserInfo', { showOcrLlmService: isShowOcrLlmService })
  }

  /**
   *  塾名取得
   */
  private async setAcademyName(academyId: number) {
    await Vue.prototype.$http.httpWithToken.get(`/academies/${academyId}`).then((res: any) => {
      this.teacher.name = res.data.name
    })
  }

  /**
   * タイムライン画面へ遷移する際、検索条件クッキーを削除する
   */
  private async removeTimelineCookie() {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie['timelineConditions'] = null
    cookie['reportConditions'] = null
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }
}
