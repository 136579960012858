import { render, staticRenderFns } from "./AcademyListMode.vue?vue&type=template&id=07e6cd33&scoped=true&lang=pug&"
import script from "./AcademyListMode.vue?vue&type=script&lang=ts&"
export * from "./AcademyListMode.vue?vue&type=script&lang=ts&"
import style0 from "./AcademyListMode.vue?vue&type=style&index=0&id=07e6cd33&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07e6cd33",
  null
  
)

export default component.exports