import { render, staticRenderFns } from "./Victory.vue?vue&type=template&id=5522f70c&scoped=true&lang=pug&"
import script from "./Victory.vue?vue&type=script&lang=ts&"
export * from "./Victory.vue?vue&type=script&lang=ts&"
import style0 from "./Victory.vue?vue&type=style&index=0&id=5522f70c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5522f70c",
  null
  
)

export default component.exports