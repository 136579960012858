




























































import ButtonBase from '@/components/atoms/v3/ButtonBase.vue'
import CorrectGuid from '@/components/modules/drills/atoms/CorrectGuide.vue'
import TitleMath from '@/components/modules/drillsv3/atoms/Title.vue'
import Answer from '@/components/modules/drillsv3/molecules/Answer.vue'
import TabsButtonMath from '@/components/modules/drillsv3/molecules/TabsButtonMath.vue'
import ModalChatMath from '@/components/modules/drillsv3/organisms/ModalChatMath.vue'
import ModalHintMath from '@/components/modules/drillsv3/organisms/ModalHintMath.vue'
import ModalMemoMath from '@/components/modules/drillsv3/organisms/ModalMemoMath.vue'
import QuestionImageHorizontalV3 from '@/components/modules/drillsv3/organisms/QuestionImageHorizontalV3.vue'
import Transition from '@/components/modules/drillsv3/organisms/TransitionMath.vue'
import DrillMethodMath from '@/mixins/drillsv3/DrillMethodMath'
import DrillTransition from '@/mixins/drillsv3/DrillTransition'
import { ResponseProblem, ResponseResultProblem, ResultQuestion, SProblem } from '@/mixins/drillsv3/WebApi'
import { DRILL_TYPE } from '@/store/modules/DrillsV3'
import { Component, Mixins, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import MemoMathMethod from '@/mixins/drillsv3/MemoMathMethod'

@Component({
  components: {
    TitleMath,
    CorrectGuid,
    Transition,
    QuestionImageHorizontalV3,
    TabsButtonMath,
    ButtonBase,
    ModalMemoMath,
    ModalHintMath,
    ModalChatMath,
    Answer,
  },
})
export default class ScoringDrillMath extends Mixins(DrillTransition, DrillMethodMath, MemoMathMethod) {
  @Ref() modalHint!: ModalHintMath
  @Ref() modalChat!: ModalChatMath
  @Ref() modalMemoMath!: ModalMemoMath
  @Ref() tabsButtonRef!: TabsButtonMath

  @Prop()
  isReport!: boolean

  @Prop()
  reportRole!: 'student' | 'teacher'

  @Prop()
  problems!: ResponseProblem[]

  @Prop()
  resultProblem!: ResponseResultProblem

  @Prop()
  buttonCancelTransition!: { onClick: () => void; text?: string }
  // 読み込み完了フラグ
  private imgCheckAnswer: { ok: boolean; notGood: boolean } = {
    ok: false,
    notGood: false,
  }
  private curriculumSUnitId: number | string = ''
  private myImageAnswerUrl = ''
  private currenTabButtonIndex = 0
  private tabsButton: { id: string; text: string; problemId: string }[] = []
  private mysAnswers: any
  private subjectCode = ''
  private clickQuestion() {
    this.modalHint.hide()
    this.modalChat.showModal()
  }

  private get page(): number {
    return Number(this.$route.query.page)
  }

  private get checkDisplayHintBtn() {
    const drillType = this.$route.query?.drillType as string
    const studyType = this.$route.query?.studyType as string
    if (studyType === 'similar') {
      return true
    }
    if (drillType === DRILL_TYPE.DRILL_BOOKMARK) {
      return false
    }
    return true
  }

  private get similarPropsHint() {
    const drillType = this.$route.query?.drillType as string
    return {
      onClick: async () => {
        try {
          Vue.prototype.$loading.start()
          const startRetry = await Vue.prototype.$http.httpWithToken.post('v3/similar_questions/drills/start', {
            question_code: this.currentQuestion.questionCode,
            curriculum_s_unit_id: this.curriculumSUnitId,
            study_type: 'similar',
            class_category_id: this.$store.getters['drillsV3/classCategoryId'],
            class_mode_id: this.$store.getters['drillsV3/classModeId'],
            study_material: 'custom',
          })
          Vue.prototype.$loading.complete()
          const resultDrillId = startRetry?.data?.resultDrillId
          if (resultDrillId) {
            window.open(
              `/student/v3/drill-similar-math/${this.currentQuestion.questionCode}?resultDrillId=${resultDrillId}&page=${startRetry?.data.currentPage}&curriculumSUnitId=${this.curriculumSUnitId}&drillType=${DRILL_TYPE.DRILL_CUSTOM}`,
              '_blank'
            )
          }
        } catch (error) {
          const status = (error as any).response?.data?.status
          if (status === 422 || status === 400) {
            alert('類題の生成に失敗しました。しばらくしてから再試行してください。')
            return
          }
        }
      },
      isHidden: false,
    }
  }

  private get checkIsHiddenBookmark() {
    const drillType = this.$route.query?.drillType as string
    return !!drillType?.length
  }

  private get getButtonCancelTransition() {
    const drillType = this.$route.query?.drillType as string
    return drillType === DRILL_TYPE.DRILL_BOOKMARK
      ? {
        onClick: async (completeFunc: () => Promise<string>) => {
          await completeFunc?.()
          this.$router.push(`/student/v3/bookmark/detail/${this.curriculumSUnitId}/${this.$route.query.questionCode}`)
        },
      }
      : {}
  }

  private handleClickTab(item: { id: string | number; text: string }) {
    if (this.isReport) {
      this.currenTabButtonIndex = this.tabsButton.findIndex((obj: any) => obj.id === item.id)
      this.currentQuestion = this.problemsInfo[item.id] as any
      this.tabsButtonRef.setCurrentTab(item.id)
      return;
    }
    const currentAnswerQuestion = ((this.mysAnswers as any)[
      this.currentQuestion.questionCode
    ] as unknown) as ResultQuestion || {}

    if (currentAnswerQuestion.ocrCorrect === true || currentAnswerQuestion.ocrCorrect === false) {
      this.currenTabButtonIndex = this.tabsButton.findIndex((obj: any) => obj.id === item.id)
      this.currentQuestion = this.problemsInfo[item.id] as any
      this.tabsButtonRef.setCurrentTab(item.id)
      this.handleCheckAnswer()
      return
    }
    alert('全ての問題で○×をつけましょう！')
  }

  private handleProblemToTabsButton(problems: SProblem[], currentPage: number) {
    let countQues = 0
    this.tabsButton = problems.reduce((newTabsButton: any[], item) => {
      const listQues = item.questions.map((question) => {
        countQues++
        return {
          id: question.questionCode,
          text: currentPage + '-' + countQues,
          problemId: item.sProblemId,
        }
      })

      return [...newTabsButton, ...listQues]
    }, [])
    this.tabsButtonRef.setCurrentTab(this.tabsButton[0]?.id)
  }

  // Checks the answer's correctness and updates the image check status accordingly.
  private handleCheckAnswer() {
    const currentAnswerQuestion = (this.mysAnswers?.[this.currentQuestion.questionCode] as unknown) as ResultQuestion || {}

    if (currentAnswerQuestion.ocrCorrect === true) {
      this.imgCheckAnswer = {
        ok: true,
        notGood: false,
      }
    } else if (currentAnswerQuestion.ocrCorrect === false) {
      this.imgCheckAnswer = {
        ok: false,
        notGood: true,
      }
    } else {
      this.imgCheckAnswer = {
        ok: false,
        notGood: false,
      }
    }
  }

  // Handles scoring based on whether the answer is correct or not, updating the status and image accordingly.
  private handleScore(isCorrect?: boolean) {
    const currentAnswerQuestion = (this.mysAnswers?.[this.currentQuestion.questionCode] as unknown) as ResultQuestion || {}

    if (
      (isCorrect && currentAnswerQuestion.ocrCorrect === true) ||
      (!isCorrect && currentAnswerQuestion.ocrCorrect === false)
    ) {
      return
    }

    if (isCorrect) {
      this.imgCheckAnswer = {
        ...this.imgCheckAnswer,
        ok: true,
        notGood: false,
      }
      currentAnswerQuestion.ocrCorrect = true
      return
    }
    currentAnswerQuestion.ocrCorrect = false
    this.imgCheckAnswer = {
      ...this.imgCheckAnswer,
      ok: false,
      notGood: true,
    }
  }

  private async handleSubmit() {
    const currentAnswerQuestion = ((this.mysAnswers as any)[
      this.currentQuestion.questionCode
    ] as unknown) as ResultQuestion

    if (currentAnswerQuestion.ocrCorrect === true || currentAnswerQuestion.ocrCorrect === false) {
      const answers = Object.values(this.mysAnswers as any).map((item: any) => item.ocrCorrect)
      Vue.prototype.$loading.start()
      const drillType = this.$route.query?.drillType as string
      const questionCode = this.$route.query.questionCode as string
      await this.finishRegisterUnits(
        parseInt(this.$route.query.resultDrillId as string),
        parseInt(this.$route.query.page as string),
        answers,
        drillType?.length ? { question_code: questionCode } : undefined
      )

      this.$router.push({
        path:
          this.urlResultUnit +
          (drillType?.length
            ? `&questionCode=${questionCode}&drillType=${drillType}&curriculumSUnitId=${this.curriculumSUnitId}&mode=ocr`
            : '&mode=ocr'),
      })
      Vue.prototype.$loading.complete()
      return
    }

    alert('全ての問題で○×をつけましょう！')
  }

  private async handleOpenMemo() {
    const data = await this.getMemoDataWithQuestion(this.currentQuestion.questionCode)
    this.modalMemoMath.setCurrentQuestion(data)
    this.modalMemoMath.showModal()
  }

  private async clickTabMemoMath(questionCode: string) {
    const data = await this.getMemoDataWithQuestion(questionCode)
    this.currentQuestionsMemoMath = data
    this.modalMemoMath.setCurrentQuestion(data)
  }

  private async handleOpenHint() {
    const data = await this.getMemoDataWithQuestion(this.currentQuestion.questionCode)
    this.currentQuestionsMemoMath = data
    !this.checkIsHiddenBookmark &&
      this.modalHint.handleGetStatusBookmark(this.currentQuestion.questionCode, this.curriculumSUnitId.toString() || '')
    this.modalHint.show()
  }

  private get getButtonSubmitProps() {
    let textButton = '完了'
    const isInLastTab = this.currenTabButtonIndex + 1 === Object.keys(this.problemsInfo).length

    if (isInLastTab) {
      textButton = '結果を登録する'
    }

    return {
      onClick: isInLastTab
        ? () => this.handleSubmit()
        : () => this.handleClickTab(this.tabsButton[this.currenTabButtonIndex + 1]),
      text: textButton,
    }
  }

  private get getCurrentTabName() {
    if (this.tabsButton.length) {
      return this.tabsButton[this.currenTabButtonIndex].text
    }
    return ''
  }

  private get getResultDrillId() {
    return this.$route.query.resultDrillId
  }

  private get questionUrl() {
    if (!this.currentQuestion.url.length) return ''
    return this.currentQuestion.url[0]
  }

  private get memoUrl() {
    return this.currentQuestionsMemoMath.memoUrl
  }

  private getQuestionsMemoMath() {
    const questionsCode = Object.keys(this.problemsInfo)
    return questionsCode.map((questionCode) => {
      return { questionCode, questionUrls: [], answerUrl: '', memoUrl: '', page: this.resultProblem.currentPage, memoUrlTeacher: '' }
    })
  }

  @Watch('mysAnswers')
  onVariablesChangeMysAnswers(newValues: any[]) {
    if (newValues && this.currentQuestion) {
      this.myImageAnswerUrl = (newValues[(this.currentQuestion as any).questionCode] as any)?.url || ''
    }
  }

  @Watch('currentQuestion')
  onVariablesChangeCurrentProblem(newValues: any) {
    if (newValues && this.mysAnswers) {
      this.myImageAnswerUrl = (this.mysAnswers?.[newValues.questionCode] as any)?.url || ''
    }
  }

  @Watch('problems')
  async onVariablesChangeProblems(newProblems: ResponseProblem[]) {
    if (newProblems.length && !!this.resultProblem) {
      this.curriculumSUnitId = this.resultProblem.curriculumSUnitId
      this.handleProblemToTabsButton(newProblems[0]?.problems, this.resultProblem.currentPage)

      const [dataImage, dataImageAnswer, sijiUrl, myAnswers, dataImageD] = await Promise.all([
        this.handleGetImage(newProblems[0]?.problems),
        this.handleGetImageAnswer(newProblems[0]?.problems),
        this.handleGetImageSiji(newProblems[0]?.problems),
        this.handleGetImageMyAnswer(this.resultProblem.problems as any, this.resultDrillId.toString(), this.isReport && this.reportRole === 'teacher' ? { isTeacher: true, encodeGdls: this.resultProblem.userDrillCode } : undefined),
        this.handleGetImageD(newProblems[0]?.problems),
      ])
      this.sijiUrl = sijiUrl
      this.mysAnswers = myAnswers as any

      const result = this.handleFormatDataProblem(newProblems[0]?.problems, dataImage, this.resultProblem.currentPage, {
        dataImageAnswer,
        dataImageD,
      })

      this.problemsInfo = result

      this.questionsMemoMath = this.getQuestionsMemoMath()

      this.currentQuestion = Object.values(result)?.[0] as any

      this.subjectCode = this.$store.getters['drillsV3/subjectCode']
      this.handleCheckAnswer()
    }
  }
}
