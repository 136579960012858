
import { Component, Vue } from 'vue-property-decorator'

// ログイン完了時のレスポンス
export type AuthResponse = {
  headers: {
    'access-token': string
    client: string
    uid: string
  }
  data: {
    data: {
      id: number
      role_name: string
    }
  }
}

// ログインユーザ情報取得API実行時のレスポンスデータ
export type LoginUserResData = {
  nickname: string
  iconUrl: string
  academyId?: number
  branchId?: number
  branchCode: string
  grade?: {
    id: number
    code: string
    name: string
  }
  isHomeLearningEnabled?: boolean
  isStudentOfSouzouAcademy?: boolean
  isStudentOfOnairAcademy?: boolean
  showEncouragementText?: boolean
  academyCode?: string
  gdlsCode?: string
  digestGdlsCode?: string
  showOcrLlmService?: boolean
}

/**
 * ログイン画面の共通処理をまとめたMixin用のコンポーネント
 *
 * Mixin先のログイン画面に応じてcreated時にmodeを上書きし、login関数にてログインを実行できる
 * modeに入る値は、MODEを参照
 */
@Component
export default class LoginMethods extends Vue {
  // Mixin先で更新必須
  mode = ''

  // ログイン画面の種類を定義
  readonly MODE = {
    ADMIN: 'admin',
    ACADEMY: 'academy',
    TEACHER: 'teacher',
    STUDENT: 'student',
    QUIZ: 'quiz',
    ADMIN_QUIZ: 'admin-quiz',
  }

  role = ''

  private errorMessages: any[] = []

  private loginApiCalled = false

  protected async loginWithLine(token: string, success: any) {
    const cookies = Vue.prototype.$cookies
    if (this.loginApiCalled) return
    this.loginApiCalled = true
    await Vue.prototype.$http.http
      .post('/v3/history_quiz/auth/sign_in', { line_access_token: token })
      .then(async (res: AuthResponse) => {
        this.setCookieAuth(res)
        success()
        this.getLoginUser()
      })
  }
  /**
   * ログイン処理 (Mixin先で呼び出す)
   *
   * このリクエストで、以下の一連の処理を実行
   * 認証リクエスト-Cookieへの保存 => ログインユーザ取得-Cookieへの保存 => Topページへの遷移
   */
  protected async login(gdlsCode: string, password?: string) {
    if (!this.mode) return

    // 二重呼び出し防止
    if (this.loginApiCalled) return
    this.loginApiCalled = true

    const body: any = {
      gdlsCode: gdlsCode,
      role: this.mode === this.MODE.QUIZ ? this.MODE.STUDENT : this.mode,
      isIPad: this.isIPad(),
    }

    if (password) {
      body.password = password
    }

    Vue.prototype.$logger.log(gdlsCode)
    await Vue.prototype.$http.http
      .post('/auth/sign_in', body)
      .then((res: AuthResponse) => {
        this.setCookieAuth(res)
        this.getLoginUser()
      })
      .catch((error: any) => {
        if (error.response.data.errors) {
          this.errorMessages = error.response.data.errors
        } else if (error.response.status === 423) {
          this.errorMessages = ['現在一時メンテナンス中です。\n完了までしばらくお待ちください。']
        } else if (error.response.status === 503) {
          this.errorMessages = [
            '現在アクセスが集中しているためログインできません。\nしばらくお待ちください。（503エラー）',
          ]
        } else {
          this.errorMessages = ['ログイン用の認証情報が正しくありません。\n再度お試しください。']
        }
        // 失敗時のみ再呼び出し可能に
        this.loginApiCalled = false
      })
  }

  /**
   * SAML認証後ログイン処理
   *
   * 基本的には通常loginと同処理
   */
  protected async samlLogin(loginHash: string) {
    if (!this.mode) return

    const body: any = {
      loginHash: loginHash,
      role: this.mode,
      isIPad: this.isIPad(),
    }

    await Vue.prototype.$http.http
      .post('/auth/sign_in', body)
      .then((res: AuthResponse) => {
        this.setCookieAuth(res)
        this.getLoginUser()
      })
      .catch((error: any) => {
        if (error.response.data.errors) {
          this.errorMessages = error.response.data.errors
        } else if (error.response.status === 503) {
          this.errorMessages = ['現在一時メンテナンス中です。\n完了までしばらくお待ちください。']
        } else {
          this.errorMessages = ['ログイン用の認証情報が正しくありません。\n再度お試しください。']
        }
      })
  }

  /**
   * ログアウト処理
   *
   * クッキーを削除しログイン画面へ遷移する
   */
  protected async logout() {
    Vue.prototype.$cookies.remove('authGdls')
    Vue.prototype.$cookies.remove('dataGdls')

    this.$store.commit('listModeV3/setListMode', [])
    this.$router.push({ name: this.loginPageName() })
  }

  /**
   * 認証情報をCookieに保存
   * 内部メソッドの為、外部からの呼び出しは想定していない
   */
  private async setCookieAuth(res: AuthResponse) {
    if (!this.mode) return

    await Vue.prototype.$cookies.set(
      'authGdls',
      {
        accessToken: res.headers['access-token'],
        client: res.headers.client,
        uid: res.headers.uid,
        currentApiUserId: res.data.data.id,
        role: res.data.data.role_name,
        webRole: this.mode,
      },
      Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)()
    )
  }

  /**
   * ユーザ情報取得
   * 完了後にtopページへ遷移
   * 内部メソッドの為、外部からの呼び出しは想定していない
   */
  private async getLoginUser() {
    if (!this.mode) return
    const isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
    await Vue.prototype.$http.httpWithToken

      .get(`${isV3 ? '/v3' : ''}/users/loginUser`)

      .then((res: any) => {
        this.setCookieLoginUser(res.data as LoginUserResData)
        if (!this.$route.path.includes('/quiz')) {
          this.$router.push(this.topPagePath())
        }
      })
      .catch((error: any) => {
        if (error.response.data.errors) {
          this.errorMessages = error.response.data.errors
        }
      })
  }

  /**
   * ユーザ情報をCookieに保存
   * 内部メソッドの為、外部からの呼び出しは想定していない
   */
  private async setCookieLoginUser(data: LoginUserResData) {
    if (!this.mode) return

    const oldCookies = Vue.prototype.$cookies.get('dataGdls');

    const setParams = {
      userNickname: data.nickname,
      userIconUrl: data.iconUrl,
      academyId: data.academyId,
      academyCode: data.academyCode,
      branchId: data.branchId,
      branchCode: data.branchCode,
      digestGdlsCode: data?.digestGdlsCode,
      showOcrLlmService: data?.showOcrLlmService
    }

    if (this.mode == this.MODE.STUDENT && data.grade) {
      setParams['grade'] = data.grade
      setParams['isStudentOfSouzouAcademy'] = data.isStudentOfSouzouAcademy
      setParams['isStudentOfOnairAcademy'] = data.isStudentOfOnairAcademy
    }
    if (this.mode == this.MODE.STUDENT && data.isHomeLearningEnabled) {
      setParams['isHomeLearningEnabled'] = data.isHomeLearningEnabled
    }
    if (this.mode == this.MODE.STUDENT) {
      setParams['showEncouragementText'] = data.showEncouragementText
      setParams['academyCode'] = data?.academyCode || ''
    }

    if (this.role === this.MODE.ADMIN_QUIZ) {
      setParams['service'] = 'quiz'
    }

    await Vue.prototype.$cookies.set('dataGdls', setParams, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }


  protected async setCookieLoginUserWithOverWrite(data: Partial<any>) {
    if (!this.mode) return

    const oldCookies = Vue.prototype.$cookies.get('dataGdls');

    await Vue.prototype.$cookies.set('dataGdls', { ...oldCookies, ...data }, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  /**
   * ログイン後の遷移先をmodeにより分岐
   * 内部メソッドの為、外部からの呼び出しは想定していない
   */
  private topPagePath(): string {
    // 別権限でのログイン画面がある場合は、MODEを拡張
    const isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
    const isToolPage = Vue.prototype.$gdlsCookiesV3.isToolPage()
    switch (this.mode) {
      case this.MODE.ADMIN:
        return this.role === this.MODE.ADMIN_QUIZ ? '/admin/notification' : '/admin/branch'
      case this.MODE.ACADEMY:
        return '/academy/branch'
      case this.MODE.TEACHER:
        if (isToolPage) {
          return '/teacher/setting/top'
        }
        if (isV3) {
          return '/teacher/history'
        } else {
          return '/teacher/dashboard'
        }
      case this.MODE.STUDENT:
        if (isV3) {
          return '/student/v3/dashboard'
        }
        if (Vue.prototype.$gdlsCookies.isV2OrDemo()) {
          return '/student/v2/dashboard'
        } else {
          return '/student/v3/dashboard'
        }
      default:
        return '/error/error404'
    }
  }

  private loginPageName(): string {
    const siteBrand = Vue.prototype.$gdlsCookiesV3.getSiteBrand()
    switch (this.mode) {
      case this.MODE.ADMIN:
        return 'AdminLogin'
      case this.MODE.ACADEMY:
        return 'AcademyLoginV3'
      case this.MODE.TEACHER:
        if (Vue.prototype.$gdlsCookiesV3.isToolPage()) {
          return 'TeacherSignInTool'
        }
        return 'TeacherSignInV3'
      case this.MODE.STUDENT:
        if (Vue.prototype.$cookies.get('gdlsSozo')?.isSozo) {
          return 'StudentSozoLogin'
        }
        if (siteBrand) {
          const siteBrandCap = siteBrand[0].toUpperCase() + siteBrand.slice(1)
          return `Students${siteBrandCap}Login`
        }
        if (Vue.prototype.$gdlsCookiesV3.isV3()) {
          return 'StudentsV3Login'
        }
        if (Vue.prototype.$gdlsCookies.isV2()) {
          return 'StudentsSflLogin'
        } else if (Vue.prototype.$gdlsCookies.isDemo()) {
          return 'StudentsMethodLogin'
        } else {
          return 'StudentsV3Login'
        }
      case this.MODE.QUIZ:
        return 'QuizLogin'
      default:
        return ''
    }
  }

  private isIPad(): boolean {
    const ua = navigator.userAgent.toLowerCase()
    return /ipad|macintosh/.test(ua) && 'ontouchend' in document
  }
}
