





import { Component, Vue } from 'vue-property-decorator'
import { RouterHelper } from '@/router/routerHelper'

@Component({
  head: {
    link: () => {
      const siteBrand = Vue.prototype.$gdlsCookiesV3.getSiteBrand()
      let faviconUrl = '/img/icons/favicon.ico'
      if (siteBrand) faviconUrl = `/img/icons/${siteBrand}_favicon.ico`

      return [{ rel: 'icon', href: faviconUrl }]
    },
  },
})
export default class App extends Vue {
  public get layout(): string {
    let layout = 'none'
    const path = new RouterHelper(this.$route).persePathes()
    const dir = path.dir
    const subdir = path.subdir
    const brandTitles = {
      sfl: 'Smart Flip Learning',
      eliveplus: 'e-Live+',
      homusuku: 'ホムスク',
      effisage: 'eFFISAGE',
    }
    const siteBrand = Vue.prototype.$gdlsCookiesV3.getSiteBrand()

    // ディレクトリに合わせて分岐
    switch (dir) {
      case 'teacher':
        layout = 'teacher'
        // teacher/setting以下は設定系画面
        if (subdir === 'setting') {
          layout = 'teacher-setting'
        }
        document.title = 'GDLS先生'
        break
      case 'student':
        document.title = brandTitles[siteBrand] || 'GDLS'
        layout = 'student'
        // student/study以下はiframe用コンテンツ
        if (subdir === 'study') {
          layout = 'student-innerframe'
        }
        break
      case 'admin':
        document.title = 'GDLS本部'
        layout = 'admin'
        break
      case 'academy':
        document.title = 'GDLS塾管理者'
        layout = 'academy'
        break
      case 'quiz':
        layout = 'quiz'
        break
    }
    // meta.layoutから直接指定
    if (this.$route?.meta?.layout) {
      layout = this.$route.meta.layout
    }

    Vue.prototype.$logger.log('layout:' + layout)
    return layout + '-layout'
  }

  public created(): void {
    // ブラウザバックを制御する
    if (!window.location.pathname.includes('/quiz')) {
      history.pushState(null, '', location.href)
      window.addEventListener('popstate', (e) => {
        history.go(1)
      })
    }
  }
}
