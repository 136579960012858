import { LoginUserResData } from '@/components/organisms/LoginMethods.vue'
import { Vue } from 'vue-property-decorator'

const state = {
    userInfo: {},
}

const getters = {
    userInfo: (state: any) => state.userInfo,
}

const mutations = {
    setUserInfo(state: any, payload: any) {
        Vue.prototype.$logger.info(payload)

        state.userInfo = payload
    },
}

const actions = {
    setUserInfo({ state, commit }: any, payload: LoginUserResData) {
        commit('setUserInfo', payload)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
