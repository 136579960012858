// Includes variables used for question answering and functions to format data when calling api to get information and format data 
import { DEFAULT_VALUE_CANVAS } from '@/constants'
import DrillWebApi, { ResultProblem, SProblem } from '@/mixins/drillsv3/WebApi'
import { CanvasDataType, StrokeType } from '@/types/canvas'
import { ProblemInfoTypeMath } from '@/types/drill'
import { Component, Mixins, Vue } from 'vue-property-decorator'

@Component
export default class DrillMethod extends Mixins(DrillWebApi) {
  protected sijiUrl = ''

  protected problemsInfo: ProblemInfoTypeMath = {}

  protected currentQuestion: {
    title: string
    url: string[]
    questionCode: string
    urlAnswer?: string[]
    urlD: string[]
    isExpandMode: boolean
  } = {
      title: '',
      url: [],
      questionCode: '',
      urlAnswer: [],
      urlD: [],
      isExpandMode: false,
    }

  protected currentCanvasData: CanvasDataType = {}

  protected async getImageUrl(
    path: string,
    other?: {
      result_question?: boolean
    }
  ): Promise<string> {
    const result = await Vue.prototype.$http.httpWithToken.get('/v3/imageUrl', {
      params: { path: path, result_question: other?.result_question },
    })
    return result.data.url
  }

  protected async handleGetImageSiji(problems: SProblem[]) {
    const oldSijiUrl = problems?.[0]?.images?.j as string

    if (oldSijiUrl?.length) return this.getImageUrl(oldSijiUrl as string)

    return oldSijiUrl
  }

  private async getUrlOfImg(data: any) {
    const urlArr = Object.values(data)
    const dataUrl = await Promise.all(
      urlArr.map((url) => {
        return Promise.all((url as string[]).map((_url: string) => this.getImageUrl(_url as string)))
      })
    )
    const problemId = Object.keys(data)
    return dataUrl.reduce((newData, url, index) => ({ ...newData, [problemId[index]]: url }), {})
  }

  protected async handleGetImage(problems: SProblem[]) {
    const data = problems.reduce((newData, item) => ({ ...newData, [item.sProblemId]: item.images.m }), {})
    const result = await this.getUrlOfImg(data)
    return result
  }

  protected async handleGetImageD(problems: SProblem[]) {
    const data = problems.reduce((newData, item) => ({ ...newData, [item.sProblemId]: item.images.d }), {})
    const result = await this.getUrlOfImg(data)
    return result
  }

  protected async handleGetImageAnswer(problems: SProblem[]) {
    const data = problems.reduce((newData, item) => ({ ...newData, [item.sProblemId]: item.images.t }), {})
    const result = await this.getUrlOfImg(data)
    return result
  }

  protected async handleGetImageMyAnswer(
    problems: ResultProblem[],
    resultDrillId: string,
    other?: {
      isTeacher?: boolean
      encodeGdls?: string
    }
  ) {
    const questionAnswerObj = problems.reduce((newData, item) => {
      const questionResult = item.questions.reduce(
        (_newData, _item) => ({ ..._newData, [_item.questionCode]: _item }),
        {}
      )
      return { ...newData, ...questionResult }
    }, {})

    const questionCodes = Object.keys(questionAnswerObj)
    const questionCodesHaveAnswer = questionCodes.filter(
      (questionCode) => questionAnswerObj[questionCode].userAnswerPath
    )

    const encodeGdls = other?.isTeacher
      ? other?.encodeGdls
      : Vue.prototype.$cookies.get('dataGdls').digestGdlsCode || ''
    const dataUrl = await Promise.all(
      questionCodesHaveAnswer.map((questionCode) =>
        this.getImageUrl(
          encodeGdls + '/' + resultDrillId + '/' + (questionAnswerObj?.[questionCode]?.userAnswerPath as string),
          {
            result_question: true,
          }
        )
      )
    )

    questionCodesHaveAnswer.forEach((questionCode, index) => {
      questionAnswerObj[questionCode].url = dataUrl[index] || ''
    })

    return questionAnswerObj
  }

  protected handleFormatDataProblem(
    problems: SProblem[],
    dataImage: Partial<any>,
    currentPage: number,
    other?: { dataImageAnswer?: Partial<any>; dataImageD: Partial<any>; sijiUrl?: string }
  ) {
    let countQues = 0
    return problems.reduce((newData, item) => {
      countQues++
      if (item.number === 1) {
        this.currentCanvasData[item.questions[0].questionCode] = {
          answer: DEFAULT_VALUE_CANVAS,
          memo: DEFAULT_VALUE_CANVAS,
        }
        return {
          ...newData,
          [item.questions[0].questionCode]: {
            title: currentPage + '-' + countQues,
            url: dataImage?.[item.sProblemId] || [],
            questionCode: item.questions[0].questionCode,
            number: item.number,
            urlAnswer: other?.dataImageAnswer?.[item.sProblemId] || [],
            question_answer: item.images.t?.[0] || '',
            urlD: other?.dataImageD?.[item.sProblemId] || [],
            sijiUrl: other?.sijiUrl,
            isExpandMode: false,
          },
        }
      }

      const quesData = item.questions.reduce((_newData, _item) => {
        this.currentCanvasData[_item.questionCode] = {
          answer: DEFAULT_VALUE_CANVAS,
          memo: DEFAULT_VALUE_CANVAS,
        }
        return {
          ..._newData,
          [_item.questionCode]: {
            title: currentPage + '-' + countQues + '~' + currentPage + '-' + (countQues + item.number - 1),
            url: dataImage?.[item.sProblemId],
            questionCode: _item.questionCode,
            number: item.number,
            urlAnswer: other?.dataImageAnswer?.[item.sProblemId] || [],
            question_answer: item.images.t?.[0] || '',
            urlD: other?.dataImageD?.[item.sProblemId] || [],
            sijiUrl: other?.sijiUrl,
            isExpandMode: false,
          },
        }
      }, {})
      countQues = countQues + item.number - 1

      return { ...newData, ...quesData }
    }, {})
  }

  protected async handleGetOneProblemInfo(
    problem: SProblem,
    other: {
      page: number
    }
  ) {
    const [dataImage, dataImageAnswer, sijiUrl, dataImageD] = await Promise.all([
      this.handleGetImage([problem]),
      this.handleGetImageAnswer([problem]),
      this.handleGetImageSiji([problem]),
      this.handleGetImageD([problem]),
    ])

    return this.handleFormatDataProblem([problem], dataImage, other.page, {
      dataImageAnswer,
      dataImageD,
      sijiUrl,
    })
  }

  protected async drawImageAndStrokes(
    imageSrc: string,
    strokes: StrokeType[][],
    params: {
      widthCanvas: number
      nameFile: string
    }
  ) {
    const canvas = document.createElement('canvas')
    canvas.width = params.widthCanvas // Adjust the width as needed
    canvas.height = 1000 // Adjust the height as needed

    const ctx = canvas.getContext('2d')

    if (!ctx) return
    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    // Create an Image object and load the image
    const image = new Image()
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = imageSrc

    console.log({ imageSrc, strokes, params })

    // Wait for the image to load before drawing
    return new Promise((resolve) => {
      image.onload = () => {
        ctx.drawImage(image, 0, 0)

        // Draw the strokes (as before)

        ctx.lineWidth = 5 // Set the stroke width (adjust as needed)
        strokes.forEach(function (stroke: StrokeType[]) {
          ctx.beginPath()
          ctx.moveTo(stroke[0].x, stroke[0].y)
          stroke.forEach(function (point: StrokeType) {
            ctx.strokeStyle = point?.color || 'black'
            ctx.lineTo(point.x, point.y)
          })
          ctx.stroke()
        })

        // Convert the canvas to a Blob and create a File object
        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob as any)
          console.log({ url })

          const file = new File([blob as any], `${params.nameFile}.png`, { type: 'image/png' })
          resolve(file)
        })
      }
    })
  }
}
