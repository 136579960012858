export enum ServiceCodeEnum {
  CALENDAR_SERVICE_CODE = 'calendar_and_grade_book',
  GPT_SERVICE_CODE = 'encouragement_text',
  GDLS_SERVICE_CODE = 'gdls',
  GAKKEN_SERVICE_CODE = 'gakken',
  NYUSHI_SERVICE_CODE = 'nyushi',
  OCR_LLM = 'ocr_llm',
}

export type ServiceCodeType =
  | ServiceCodeEnum.CALENDAR_SERVICE_CODE
  | ServiceCodeEnum.GPT_SERVICE_CODE
  | ServiceCodeEnum.GDLS_SERVICE_CODE
  | ServiceCodeEnum.GAKKEN_SERVICE_CODE
  | ServiceCodeEnum.NYUSHI_SERVICE_CODE
  | ServiceCodeEnum.OCR_LLM;
