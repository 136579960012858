








































import { Component, Prop, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ImageRound from '@/components/atoms/ImageRound.vue'
import LabelBase from '@/components/atoms/LabelBase.vue'

@Component({
  components: {
    ColoredBox,
    ImageRound,
    LabelBase,
  },
})
export default class StudentInformationTimeline extends Vue {
  @Prop({ default: {} })
  activity!: any

  @Prop({ default: true })
  isTeacher?: boolean

  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()

  private get typeMode() {
    if (this.activity?.learningType?.length) return this.activity.learningType
    if (this.activity.type === 'AI' && this.isV3) return '通常'
    if (this.activity.type) return this.activity.type
    return this.activity.isModeDefault ? '通常' : 'カスタム'
  }

  private drillResultUrl(activity: any): string {
    if (this.isTeacher) {
      if (activity.resultDrillVersion === 2) {
        return `/teacher/v3/history/drillResult${activity.isOcrMode ? 'Math':''}?resultDrillId=${activity.resultDrillId}&prevPage=TimelineV3`
      } else {
        return `/teacher/history/drillResult/${activity.resultDrillId}?prevPage=${this.isV3 ? 'TimelineV3' : 'Timeline'
          }`
      }
    } else {
      if (activity.resultDrillVersion === 2) {
        return `/student/v3/history/drillResult${activity.isOcrMode ? 'Math':''}?resultDrillId=${activity.resultDrillId}&prevPage=StudentTimelineV3`
      } else {
        return `/student/history/drillResult/${activity.resultDrillId}?prevPage=${this.isV3 ? 'StudentTimelineV3' : 'StudentTimeline'
          }`
      }
    }
  }
}
