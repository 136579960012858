















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DropdownMenu extends Vue {
  private showChildren = false
  @Prop()
  items!: []
}
