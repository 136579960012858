






















import { Component, Prop, Mixins, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import StyleSubjectBackground from '@/components/atoms/StyleSubjectBackground.vue'
import DrillWebApi from '@/mixins/drillsv3/WebApi'
import DrillTransition from '@/mixins/drillsv3/DrillTransition'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import RatioBarText from '@/components/atoms/RatioBarText.vue'
import PositionListUnit from '@/mixins/positionListUnit/PositionListUnit'

@Component({
  components: {
    ColoredBox,
    ButtonBase,
    RatioBarText,
  },
})
export default class UnitInformation extends Mixins(
  DrillWebApi,
  DrillTransition,
  ClassModeMethods,
  StyleSubjectBackground,
  PositionListUnit
) {
  @Prop()
  unit!: {
    id: number
    name: string
    isCompleted: boolean
    totalCorrectQuestionsLastDrillRk: number | null
    totalQuestionsLastDrillRk: number | null
    totalCorrectQuestionsLastDrillEn: number | null
    totalQuestionsLastDrillEn: number | null
  }

  @Prop()
  subjectCode!: string

  @Prop()
  classMode!: string

  get nameClassObject(): { [key: string]: boolean } {
    return {
      'unit-information__name__sugaku': this.subjectCode === 'su' && !this.unit.isCompleted,
      'unit-information__name__sugaku-completed': this.subjectCode === 'su' && this.unit.isCompleted,
      'unit-information__name__kokugo': this.subjectCode === 'ko' && !this.unit.isCompleted,
      'unit-information__name__kokugo-completed': this.subjectCode === 'ko' && this.unit.isCompleted,
      'unit-information__name__eigo': this.subjectCode === 'ei' && !this.unit.isCompleted,
      'unit-information__name__eigo-completed': this.subjectCode === 'ei' && this.unit.isCompleted,
      'unit-information__name__rika': this.subjectCode === 'ri' && !this.unit.isCompleted,
      'unit-information__name__rika-completed': this.subjectCode === 'ri' && this.unit.isCompleted,
      'unit-information__name__syakai': this.subjectCode === 'sh' && !this.unit.isCompleted,
      'unit-information__name__syakai-completed': this.subjectCode === 'sh' && this.unit.isCompleted,
    }
  }

  get countQuestionRk() {
    const { totalCorrectQuestionsLastDrillRk, totalQuestionsLastDrillRk } = this.unit
    return (totalCorrectQuestionsLastDrillRk ?? '- ') + '/' + (totalQuestionsLastDrillRk ?? ' -')
  }

  get countQuestionEn() {
    const { totalCorrectQuestionsLastDrillEn, totalQuestionsLastDrillEn } = this.unit
    return (totalCorrectQuestionsLastDrillEn ?? '- ') + '/' + (totalQuestionsLastDrillEn ?? ' -')
  }

  get barStyleSet(): any {
    const _: any = this
    return function (code: string) {
      let fillColor = null
      let lineColor = null

      if (_.isTeacher) {
        fillColor = 'var(--color-blue)'
        lineColor = 'var(--color-blue-3)'
      } else {
        const subjectStyle = _.getSubjectStyle(code)
        fillColor = subjectStyle['--subject-bg-color']
        lineColor = subjectStyle['--subject-linear-gradiant']
      }
      return {
        '--bar-fill-color': fillColor,
        '--bar-line-color': lineColor,
      }
    }
  }

  /**
   * 演習開始
   */
  private async startDrill() {
    Vue.prototype.$loading.start()
    // ドリル開始APIを実行
    const drill = await this.startDrillApi('MN', 'MN', 'MN', {
      curriculumSUnitId: this.unit.id,
    })

    Vue.prototype.$loading.complete()
    // 演習画面へ遷移
    this.$router.push({
      path: this.urlDrillWithId(drill.resultDrillId),
    })
  }

  private moveToStudyUnit() {
    this.$router.push({
      path: `/student/v3/history/study-unit/${this.unit.id}?prevPage=CurriculumSUnitsV3&selectedSubject=${this.subjectCode}&modeCode=${this.classMode}`,
    })
  }

  private get isAiMode(): boolean {
    return this.classMode == this.CLASS_MODE.AI
  }

  private get classCategoriesUrl(): string {
    return `/student/v3/curriculum-s-units/${this.subjectCode}/${this.unit.id}/class-categories/${this.classMode}`
  }
}
