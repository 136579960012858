





















import { Component, Prop, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    ButtonBase,
  },
})
export default class StudentInModel extends Vue {
  @Prop()
  items!: []

  private removeItem(index: number) {
    this.$emit('removeItem', index)
  }
}
