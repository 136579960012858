














































import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import CheckboxSquare from '@/components/atoms/CheckboxSquare.vue'
import InputWithLabel from '@/components/molecules/v3/InputWithLabel.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'

@Component({
  components: {
    ModalBase,
    ButtonBase,
    CheckboxSquare,
    InputWithLabel,
    SelectBase,
  },
})
export default class ModalBranchAdd extends Mixins(ModalBaseMethod) {
  @Prop()
  items!: []

  @Prop()
  fields!: []

  @Prop()
  checkboxs?: []

  @Prop()
  showHeaderCheckbox?: boolean

  @Prop()
  images?: []

  @Prop()
  checkAll?: boolean

  @Prop({ default: 1 })
  currentPage?: number

  @Prop({ default: 0 })
  count?: number

  @Watch('checkAll')
  onItemsCheckAll() {
    if (this.checkAll == true) {
      this.headerCheckboxChecked['id'] = true
    } else {
      this.headerCheckboxChecked['id'] = false
    }
  }

  @Watch('items')
  onItemsChanged(newItems: any) {
    if (!this.checkboxs || !this.showHeaderCheckbox) return
    this.checkboxs.forEach((key: string) => {
      this.switchHeadCheckbox(key, newItems)
    })
  }

  private isMobile = window.innerWidth <= 768
  private branchParams: { branchName: string; branchCode: string } = {
    branchName: '',
    branchCode: '',
  }

  private resetStudentParams() {
    this.branchParams = {
      branchName: '',
      branchCode: '',
    }
  }

  private headerCheckboxChecked = this.getHeaderCheckboxChecked()

  private changeAllCheckbox(key: string, value: boolean) {
    this.items.forEach((item: any) => {
      item[key].checked = value
    })
  }

  private changeHeadCheckbox(key: string) {
    if (!this.showHeaderCheckbox) return
    this.switchHeadCheckbox(key, this.items)
  }

  private switchHeadCheckbox(key: string, items: any[]) {
    const itemChecked = items.map((item: any) => {
      return item[key].checked
    })
    this.headerCheckboxChecked[key] = !itemChecked.includes(false)
  }

  private getHeaderCheckboxChecked() {
    const headerCheckboxChecked: { [key: string]: boolean } = {}
    if (!this.checkboxs || !this.showHeaderCheckbox) return headerCheckboxChecked
    this.checkboxs.forEach((key: string) => {
      headerCheckboxChecked[key] = false
    })
    return headerCheckboxChecked
  }

  private getCell(value: string): string {
    return `cell(${value})`
  }

  private getHeader(value: string): string {
    return `head(${value})`
  }

  /**
   * ページネーション
   */
  private paginate(page: number): void {
    this.$emit('paginate', page)
  }

  private searchBranch(): void {
    this.$emit('search-branch', this.branchParams)
  }

  private save() {
    this.$emit('save-branch', this.items)
    this.resetStudentParams()
  }

  private cancel() {
    this.$emit('hide-modal')
  }
}
