import Vue from 'vue'
import Vuex from 'vuex'
import LessonActionCable from './modules/LessonActionCable'
import Drills from './modules/Drills'
import DrillsV2 from './modules/DrillsV2'
//75 Add Start 20230115
import DrillsV3 from './modules/DrillsV3'
import ActiveDrill from './modules/ActiveDrill'
import ListModeV3 from './modules/ListModeV3'
import ActiveSubjects from './modules/ActiveSubjects'
//75 Add End 20230115
import Queue from './modules/Queue'
import Stack from './modules/Stack'
import Media from './modules/Media'

import Question from './modules/Question'
import Modal from './modules/Modal'
import ListBadge from './modules/ListBadge'
import Common from './modules/common'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    lessonActionCable: LessonActionCable,
    drills: Drills,
    drillsV2: DrillsV2,
    drillsV3: DrillsV3,
    activeDrill: ActiveDrill,
    listModeV3: ListModeV3,
    queue: Queue,
    stack: Stack,
    media: Media,
    activeSubjects: ActiveSubjects,
    question: Question,
    modal: Modal,
    badges: ListBadge,
    common: Common
  },
})
