import { ActionSort, KeyTable, StudentHistory } from '@/types/teacher'
import moment from 'moment'

export const fields = [
  { key: KeyTable.ID, label: '', thStyle: 'text-align: center' },
  { key: KeyTable.AI, label: 'AI', thStyle: 'text-align: center' },
  {
    key: KeyTable.GRADE,
    sortKey: KeyTable.GRADE,
    label: '学年',
    thStyle: 'text-align: center; width: 80px',
  },
  {
    key: KeyTable.NAME_STUDENT,
    sortKey: KeyTable.NAME_STUDENT,
    label: '生徒氏名',
    thStyle: 'text-align: center; width: 120px',
  },
  {
    key: KeyTable.SCHOOL,
    sortKey: KeyTable.SCHOOL,
    label: '学校',
    thStyle: 'text-align: center; width: 80px',
  },
  {
    key: KeyTable.LAST_LOGIN,
    label: '最終ログイン日時',
    thStyle: 'text-align: center; width: 180px',
  },
  {
    key: KeyTable.LASTEST,
    label: '最新演習',
    thStyle: 'text-align: center; width: 180px',
  },
  {
    key: KeyTable.EI_PROCESS,
    sortKey: KeyTable.EI_PROCESS,
    label: '進捗',
    thStyle: 'text-align: center; width: 90px',
  },
  {
    key: KeyTable.EI_QUESTION,
    sortKey: KeyTable.EI_QUESTION,
    label: '問題数',
    thStyle: 'text-align: center; width: 100px',
  },
  {
    key: KeyTable.SU_PROCESS,
    sortKey: KeyTable.SU_PROCESS,
    label: '進捗',
    thStyle: 'text-align: center; width: 90px',
  },
  {
    key: KeyTable.SU_QUESTION,
    sortKey: KeyTable.SU_QUESTION,
    label: '問題数',
    thStyle: 'text-align: center; width: 100px',
  },
  {
    key: KeyTable.KO_PROCESS,
    sortKey: KeyTable.KO_PROCESS,
    label: '進捗',
    thStyle: 'text-align: center; width: 90px',
  },
  {
    key: KeyTable.KO_QUESTION,
    sortKey: KeyTable.KO_QUESTION,
    label: '問題数',
    thStyle: 'text-align: center; width: 100px',
  },
  {
    key: KeyTable.RI_PROCESS,
    sortKey: KeyTable.RI_PROCESS,
    label: '進捗',
    thStyle: 'text-align: center; width: 90px',
  },
  {
    key: KeyTable.RI_QUESTION,
    sortKey: KeyTable.RI_QUESTION,
    label: '問題数',
    thStyle: 'text-align: center; width: 100px',
  },
  {
    key: KeyTable.SH_PROCESS,
    sortKey: KeyTable.SH_PROCESS,
    label: '進捗',
    thStyle: 'text-align: center; width: 90px',
  },
  {
    key: KeyTable.SH_QUESTION,
    sortKey: KeyTable.SH_QUESTION,
    label: '問題数',
    thStyle: 'text-align: center; width: 100px',
  },
]

export const checkboxs = [KeyTable.ID, KeyTable.AI]
export const headerCheckboxs = [KeyTable.ID]
export const multipleLinks = [KeyTable.LAST_LOGIN, KeyTable.LASTEST]
export const sorts = [
  KeyTable.GRADE,
  KeyTable.NAME_STUDENT,
  KeyTable.SCHOOL,
  KeyTable.SU_PROCESS,
  KeyTable.SU_QUESTION,
  KeyTable.EI_PROCESS,
  KeyTable.EI_QUESTION,
  KeyTable.KO_PROCESS,
  KeyTable.KO_QUESTION,
  KeyTable.RI_PROCESS,
  KeyTable.RI_QUESTION,
  KeyTable.SH_PROCESS,
  KeyTable.SH_QUESTION,
]
export const links = [
  KeyTable.NAME_STUDENT,
  KeyTable.SU_PROCESS,
  KeyTable.EI_PROCESS,
  KeyTable.KO_PROCESS,
  KeyTable.RI_PROCESS,
  KeyTable.SH_PROCESS,
]
export const texts = [
  KeyTable.SCHOOL,
  KeyTable.SU_QUESTION,
  KeyTable.EI_QUESTION,
  KeyTable.KO_QUESTION,
  KeyTable.RI_QUESTION,
  KeyTable.SH_QUESTION,
]

const createdDate = (time: string) => {
  return moment(time).format('YYYY/MM/DD')
}
const createdTime = (time: string) => {
  return moment(time).format('HH:mm')
}

const generateDataSubjects = (item: StudentHistory, func: any) => {
  const mapSubjectCode = {
    '算数/数学': 'su',
    英語: 'ei',
    国語: 'ko',
    理科: 'ri',
    社会: 'sh',
  }
  const result = item.subjectsResults.reduce((obj, current) => {
    const subjectName = current.subjectName
    const keyProcess = mapSubjectCode[subjectName] + '_process'
    const keyQuestion = mapSubjectCode[subjectName] + '_question'
    obj[keyProcess] = {
      name: current.currentSUnitIds?.length && current.process.total && current.process.current ? current.process.current + '/' + current.process.total : '',
      onclick: func,
      variable: { curriculumSUnitIds: current.currentSUnitIds, student: item },
      classEmty: 'cell-emty',
    }
    obj[keyQuestion] = {
      name: current.totalQuestion ? current.totalQuestion : '',
      classEmty: 'cell-emty',
    }
    return obj
  }, {})

  return result
}

const getNameSubject = (key: string) => {
  const mapSubjectName = {
    su: '算数/数学',
    ei: '英語',
    ko: '国語',
    ri: '理科',
    sh: '社会',
  }
  const subjectCode = key.slice(0, 2)
  return mapSubjectName[subjectCode]
}

export const convertQueryParams = (query: any) => {
  const querySearch = ['student_name', 'school_id', 'start', 'end', 'grade_id']
  const params = Object.keys(query).reduce((obj: any, key) => {
    if (querySearch.includes(key)) {
      obj[key] = query[key]
      return obj
    }
    if (key.includes('_process') && query[key] !== ActionSort.NonSort) {
      !obj.process_s_unit_sort && (obj.process_s_unit_sort = [])
      obj.process_s_unit_sort.push({ key: getNameSubject(key), sort_key: query[key] })
      return obj
    }
    if (key.includes('_question') && query[key] !== ActionSort.NonSort) {
      !obj.total_question_sort && (obj.total_question_sort = [])
      obj.total_question_sort.push({ key: getNameSubject(key), sort_key: query[key] })
      return obj
    }
    if (query[key] !== ActionSort.NonSort) {
      !obj.sort_student_params && (obj.sort_student_params = [])
      obj.sort_student_params.push({ key, sort_key: query[key] })
    }
    return obj
  }, {})
  return params
}

export const getParamsExport = (items: any[]) => {
  const ai_messsage_selected: any[] = []
  const row_selected: any[] = []
  items.forEach((item) => {
    if (item[KeyTable.ID].checked) {
      row_selected.push(item[KeyTable.ID].value)
    }
    if (item[KeyTable.AI].checked) {
      ai_messsage_selected.push(item[KeyTable.AI].value)
    }
  })
  return { ai_messsage_selected, row_selected }
}

export const convertDataHistory = (data: StudentHistory[], funcs: any) => {
  return data.map((item) => ({
    [KeyTable.ID]: { value: item.rowIndex, checked: false },
    [KeyTable.AI]: {
      value: item.rowIndex,
      checked: false,
      tooltip: true,
      contentTooltip: '学習アドバイスを出力しますか。',
    },
    [KeyTable.GRADE]: item.gradeName,
    [KeyTable.NAME_STUDENT]: {
      name: item.nickname,
      onclick: funcs.clickName,
      variable: item,
      tooltip: true,
      width: 120,
    },
    [KeyTable.SCHOOL]: {
      tooltip: true,
      name: item.schoolName,
      width: 70,
    },
    [KeyTable.LAST_LOGIN]: item.lastLoginAt
      ? [
          {
            name: createdDate(item.lastLoginAt),
            disabled: true,
          },
          {
            name: createdTime(item.lastLoginAt),
            disabled: true,
          },
        ]
      : [{ name: '', classEmty: 'cell-emty' }],
    [KeyTable.LASTEST]: item.latestDrill.completedAt
      ? [
          {
            name: createdDate(item.latestDrill.completedAt),
            disabled: true,
          },
          {
            name: item.latestDrill.title,
            onclick: funcs.openHistoryDrill,
            variable: item.latestDrill.resultId,
          },
        ]
      : [{ name: '', classEmty: 'cell-emty' }],
    ...generateDataSubjects(item, funcs.clickProcess),
  }))
}
