// Includes variables used for the memo screen and functions to format data when calling the api to get information and format data.
import DrillWebApi, { ResultProblem } from '@/mixins/drillsv3/WebApi'
import { ProblemInfoTypeMath } from '@/types/drill'
import { QuestionsMemoMathType } from '@/types/memo'
import { Component, Mixins, Vue } from 'vue-property-decorator'

@Component
export default class MemoMathMethod extends Mixins(DrillWebApi) {
  protected questionsMemoMath: QuestionsMemoMathType[] = []
  protected isFirstTargetMemoMath: { [key: string]: boolean } = {}
  protected currentQuestionsMemoMath: QuestionsMemoMathType = {
    memoUrl: '',
    memoUrlTeacher: '',
    page: 1,
    questionCode: '',
    questionUrls: [],
    answerUrl: '',
    questionUrlsD: []
  }
  protected async getMemoDataWithQuestion(questionCode: string, other?: {
    isRefetch?: boolean
  }) {
    const currentQuestionIndex = this.questionsMemoMath.findIndex((item) => item.questionCode === questionCode)

    if (!this.questionsMemoMath[currentQuestionIndex].questionUrls.length || other?.isRefetch) {
      Vue.prototype.$loading.start()
      const result = await this.getMemoInfo({
        page_num: this.questionsMemoMath[currentQuestionIndex].page.toString(),
        result_drill_id: this.$route.query.resultDrillId as string,
        question_code: questionCode,
      })
      Vue.prototype.$loading.complete()

      this.questionsMemoMath[currentQuestionIndex].memoUrl = result.url
      this.questionsMemoMath[currentQuestionIndex].questionUrls = result.memoPath.m || []
      this.questionsMemoMath[currentQuestionIndex].classCategoryId = result.classCategoryId || 0
      this.questionsMemoMath[currentQuestionIndex].classModeId = result.classModeId || 0
      this.questionsMemoMath[currentQuestionIndex].memoUrlTeacher = result.urlTeacher
      this.questionsMemoMath[currentQuestionIndex].questionUrlsD = result.memoPath.d || []
    }

    return this.questionsMemoMath[currentQuestionIndex]
  }

  protected formatQuestionsMemoMath(problemsInfo: ProblemInfoTypeMath, pageNumber: number) {
    const questionsCode = Object.keys(problemsInfo)
    return questionsCode.map((questionCode) => {
      return { questionCode, questionUrls: [], answerUrl: '', memoUrl: '', page: pageNumber }
    })
  }

  protected getIsFirstTimeTarget(data: {
    pageNum: number
    problems: ResultProblem[]
  }[]) {
    return data.reduce((result, item) => {
      const addProblemObject = item.problems.reduce((_result, addProblem) => {
        const questionObj = addProblem.questions.reduce((__result, question) => ({ ...__result, [question.questionCode]: question.isFirstTimeTarget }), {})
        return { ..._result, ...questionObj }
      }, {})
      return { ...result, ...addProblemObject }
    }, {})
  }
}
