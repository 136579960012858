


























































































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import RadioOkNg from '@/components/molecules/RadioOkNg.vue'
import ImageExpansion from '@/components/modules/drillsv3/molecules/ImageExpansion.vue'
import ScrollGuide from '@/components/modules/drills/atoms/ScrollGuide.vue'
import { PAGE_TYPE } from '@/store/modules/DrillsV3'

import ImageMixin from '@/components/modules/drillsv3/molecules/ImageMixin'
import DrillWebApi from '@/mixins/drillsv3/WebApi'

@Component({
  components: {
    ButtonBase,
    RadioOkNg,
    ImageExpansion,
    ScrollGuide,
  },
})
export default class DrillCorrect extends Mixins(ImageMixin, DrillWebApi) {
  private loadComplete = false

  // s3アクセス用フルURL
  private kaitouUrl: string[][] = []

  @Prop()
  customParam?: {
    currentPageType?: string
    classCategoryCode?: string
  }

  @Prop({ default: false })
  isTeacher!: boolean

  @Prop({ default: false })
  isReport!: boolean

  private get subjectCode() {
    return this.$store.getters['drillsV3/subjectCode']
  }
  private checkHistoryOrNot(normal: number, history: number) {
    const routeName = this.$route.name || ''
    const historyRouteNames = ['StudentsV3HistoryDrillResult', 'TeachersV3HistoryDrillResult']
    return historyRouteNames.includes(routeName) ? history : normal
  }

  private get isVertical() {
    // 国語の場合はtrue
    return this.subjectCode === 'ko'
  }

  private get isTypeResult(): boolean {
    console.log({ pageType: this.pageType })
    // ページタイプが結果系画面の場合はtrue
    if (this.pageType === PAGE_TYPE.RESULT_ALL) {
      return true
    }
    if (this.pageType === PAGE_TYPE.RESULT_UNIT) {
      return true
    }
    return false
  }

  /**
   * クエリのページ指定(指定なしの場合は1)
   */
  private get queryPage(): number {
    return Number(this.$route.query.page) || 1
  }

  private get corrects(): any[] {
    return this.$store.getters['drillsV3/corrects'] || []
  }
  private get numberCorrects(): any[] {
    return this.$store.getters['drillsV3/numberCorrects'] || []
  }

  private get moviePlayed(): any[] {
    return this.$store.getters['drillsV3/moviePlayed']
  }

  protected pageQuestionStartNumberFromCorrect(pageIndex: number, index: number): number {
    return this.numberCorrects && this.numberCorrects[pageIndex]
      ? this.numberCorrects[pageIndex].slice(0, index).reduce((sum: number, element: number) => sum + element, 0) + 1
      : 0
  }

  /**
   * 該当の問題の解説動画が視聴済みかを判定
   */
  private isMoviePlayed(pageIndex: number, problemIndex: number): boolean {
    return this.moviePlayed[pageIndex][problemIndex] || false
  }

  /**
   * ストアから現在のページタイプを取得する
   *
   * @returns ページタイプ
   */
  protected get pageType(): string {
    return this.$store.getters['drillsV3/pageType']
  }

  private showTextExplain() {
    console.log('Show')
  }

  private async mounted() {
    Vue.prototype.$loading.startWhite()

    // s3アクセス用URL取得
    for (let i = 0; i < this.problemLength; i++) {
      this.kaitouUrl[i] = await Promise.all(
        this.kaitou(i).map(async (path) => {
          return await this.getImageUrl(path)
        })
      )
    }
    this.loadComplete = true
    Vue.prototype.$loading.complete()
  }

  private updateCorrectInput(event: boolean, pageIndex: number, questionIndex: number) {
    Vue.prototype.$logger.log(`updateCorrectInput ${event} ${pageIndex} ${questionIndex}`)

    this.$store.commit('drillsV3/setCorrectDetail', {
      value: event,
      pageIndex: pageIndex,
      questionIndex: questionIndex,
    })
  }

  /**
   * ページ番号
   *
   * @param pageIndex ページインデックス
   * @return ページ番号
   */
  private pageNumber(pageIndex: number): number {
    return this.queryPage + pageIndex
  }

  /**
   * 解説動画画面に遷移する
   */
  public transferExplanatoryVideo(pageIndex: number, questionIndex: number): void {
    const problemIndex = this.problemIndexFromQuestionIndex(pageIndex, questionIndex)
    const sProblemId = this.sProblemIds(pageIndex)[problemIndex]
    const version = this.$route.params.type || 'v3'
    const mode = this.$route.query.mode
    let path = '/student/v3/explanatory-video'
    if (this.isReport) {
      const content: string = (this.$route.query.content as string) || ''
      const type = this.$route.query.type || ''
      path = `/${Vue.prototype.$cookies.get('authGdls').webRole
        }/report/${version}/explanatory-video?content=${encodeURIComponent(content)}&type=${type}`
    }
    this.$router.push({
      path: path,
      query: {
        sProblemId: String(sProblemId),
        resultDrillId: this.$route.query.resultDrillId,
        historyBackUrl: encodeURIComponent(this.$route.fullPath),
        ...(mode ? { mode } : {})
      },
    })
  }

  /**
   * 動画解説ボタンの文言を取得
   */
  private movieButtonMessage(pageIndex: number, questionIndex: number): string {
    // ページ番号を取得
    const pageNum = this.pageNumber(pageIndex)
    let title = ''
    let problemIndex = 0
    if (this.isLProblem(pageIndex)) {
      // 大問の場合は「大問1（ページ番号）」と表示
      title = `大問${pageNum}`
    } else {
      // 小問の場合、設問数で判定を行う
      problemIndex = this.problemIndexFromQuestionIndex(pageIndex, questionIndex)
      const questionNumber = this.questionNumber(pageIndex)[problemIndex]
      if (questionNumber === 1) {
        // 小問と設問が1対1の場合は「1-1（ページ番号-小問番号）」と表示
        title = `${pageNum}-${questionIndex + 1}`
      } else {
        // 小問と設問が1対多の場合は「1-1〜1-3（ページ番号-小問番号〜ページ番号-小問番号）」と表示
        const startNumber = this.questionNumber(pageIndex)
          .slice(0, problemIndex)
          .reduce((sum, element) => sum + element, 0)
        title = `${pageNum}-${startNumber + 1}〜${pageNum}-${startNumber + questionNumber}`
      }
    }

    const message = this.isMoviePlayed(pageIndex, problemIndex) ? '視聴済み' : '視聴する'
    // const message = '視聴する'
    return `${title} ${message}`
  }
}
