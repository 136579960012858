
































































import { Component, Vue, Prop } from 'vue-property-decorator'
import RadioImaged from '@/components/atoms/RadioImaged.vue'
import DrillPenIconMath from '@/components/atoms/v3/icons/DrillPenIconMath.vue'
import DrillPenActiveIconMath from '@/components/atoms/v3/icons/DrillPenActiveIconMath.vue'
import { PEN_COLOR_KEY, PEN_COLOR } from '@/constants'
@Component({
  components: {
    RadioImaged,
    DrillPenIconMath,
    DrillPenActiveIconMath,
  },
})
export default class DrillPenMath extends Vue {
  @Prop()
  onUndo!: Function

  @Prop()
  onRedo!: Function

  @Prop()
  arrHiddenColor?: any

  @Prop()
  onChangePen?: Function

  @Prop()
  onTrash?: Function

  @Prop()
  onBookmark?: Function

  @Prop()
  onErase?: Function

  @Prop({
    default: {
      trashMemo: false,
      trashAnswer: false,
      bookmark: true,
      changeLine: false,
      hand: false
    }
  })
  hiddenUi?: {
    trashMemo: boolean,
    trashAnswer: boolean,
    bookmark: boolean,
    changeLine: boolean,
    hand: boolean
  }

  @Prop()
  onChangeLineWidth?: Function

  @Prop()
  onChangeHand?: Function

  private activeErase = false
  private statusHand = false
  private statusBookmark = false
  private showChangeLine = false
  private statusLine = 'medium'
  private colors = {
    [PEN_COLOR_KEY.BLACK]: {
      inactive: '/img/icon_pen/pen_black.png',
      active: '/img/icon_pen/pen_black_active.png',
    },
    [PEN_COLOR_KEY.ORANGE]: {
      inactive: '/img/icon_pen/pen_orange.png',
      active: '/img/icon_pen/pen_orange_active.png',
    },
    [PEN_COLOR_KEY.GREEN]: {
      inactive: '/img/icon_pen/pen_green.png',
      active: '/img/icon_pen/pen_green_active.png',
    },
    [PEN_COLOR_KEY.RED]: {
      inactive: '/img/icon_pen/pen_red.png',
      active: '/img/icon_pen/pen_red_active.png',
    },
    [PEN_COLOR_KEY.BLUE]: {
      inactive: '/img/icon_pen/pen_blue.png',
      active: '/img/icon_pen/pen_blue_active.png',
    },
  }
  private activeColor = ''

  private isHidePen(key: string) {
    return (this.arrHiddenColor || []).includes(key)
  }

  private onChangeStatusBookmark(value: boolean) {
    this.statusBookmark = value
  }

  private handleChangeStatusPen(value: string) {
    if (this.activeColor === value) {
      this.activeColor = ''
      this.statusHand = true;
      this.onChangePen?.('')
      return
    }
    this.activeErase = false
    this.statusHand = false;
    this.activeColor = value
    this.onChangePen?.((PEN_COLOR as any)[value])
    this.onChangeLineWidth?.(this.statusLine)
  }

  private handleSetActiveColor(colorCode: string) {
    this.activeColor = colorCode
  }

  private checkDisplayPen(colorCode: string) {
    if ((this.arrHiddenColor || []).includes(colorCode)) return false
    if (this.activeColor !== colorCode) return true
    return false
  }

  public onSetActiveColor(keyColor: string) {
    this.activeColor = keyColor
  }

  private handleDelete(type: 'memo' | 'answer') {
    this.onTrash?.(type)
  }

  private get getStarUrl() {
    return this.statusBookmark ? '/img/icons/star-mark-active-v2.png' : '/img/icons/star-mark-v2.png'
  }

  private get getHandUrl() {
    return this.statusHand ? '/img/icons/hand-active.svg' : '/img/icons/hand.svg'
  }

  private handleChangeLine(type: 'thin' | 'medium' | 'advand') {
    this.showChangeLine = !this.showChangeLine
    this.statusLine = type
    this.onChangeLineWidth?.(type)
  }

  private handleClickOutside() {
    this.showChangeLine = false
  }

  private handleChangeHandStatus() {

    if (this.statusHand) {

      this.activeColor = PEN_COLOR_KEY.BLACK
      this.onChangeLineWidth?.(this.statusLine)
    } else {
      this.activeColor = ''
      this.onChangePen?.('')
      this.activeErase = false
    }
    this.onChangeHand?.(this.statusHand)
    this.statusHand = !this.statusHand;
  }

  private get getUrlErase() {
    return this.activeErase ? '/img/icon_pen/eraser_on.png' : '/img/icon_pen/eraser_off.png'
  }

  private handleOnOffErase() {
    if (!this.activeErase) {
      this.activeColor = ''
      this.onChangePen?.('')
      this.statusHand = false
    } else {
      this.onChangeLineWidth?.(this.statusLine)
    }
    this.onErase?.(this.activeErase)
    this.activeErase = !this.activeErase;
  }
}
