
































































































































import { Component, Vue, Ref } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import SettingItem from '@/components/molecules/v3/SettingItem.vue'
import LearningEndCondition from '@/components/organisms/v3/mode/LearningEndCondition.vue'
import DatePickerIcon from '@/components/atoms/DatePickerIcon.vue'
import RadioToggle from '@/components/atoms/RadioToggle.vue'
import LessonProgressSetting from '@/components/organisms/v3/mode/LessonProcessSetting.vue'
import SchoolSetting from '@/components/organisms/v3/mode/SchoolSetting.vue'
import PopupBase from '@/components/molecules/v3/PopupBase.vue'
import Suspense from '@/components/molecules/v3/Suspense.vue'
import LevelDifficult, { DifficultTableItem } from '@/components/organisms/v3/mode/LevelDifficult.vue'
import { ICON_LIST, MODE_DEFAULT, TEACHER_SETTING_TEXT } from '@/constants'
import { ClassPolycies, Icon, Level, ModeType, ConditionExit, Subject, TypeScope } from '@/types/teacher/mode'
import { processDataPreSubmit, convertMode, validateMode, checkDate } from '@/utils/teacher/mode'
import _ from 'lodash'
import moment from 'moment'
import { data } from 'jquery'

const MAXIMUM_MODE_MESSAGE = '50モードだけ作成できます。'

@Component({
  components: {
    TitleBase,
    ButtonBase,
    TitleTextBase,
    SettingItem,
    DatePickerIcon,
    RadioToggle,
    LessonProgressSetting,
    SchoolSetting,
    PopupBase,
    LearningEndCondition,
    Suspense,
    LevelDifficult,
  },
})
export default class Top extends Vue {
  @Ref() levelDifficultRef!: LevelDifficult

  private MODE_DEFAULTS = [
    { name: '定期テスト対策', id: 1 },
    { name: '講習会', id: 2 },
  ]

  private modeDefault = {}

  private DEPTH_OF_TRACE = [
    { name: '学習項目内のみ', value: Level.OFF },
    { name: '１階層', value: Level.ONE },
    { name: '２階層', value: Level.TWO },
    { name: '3 階層', value: Level.THREE },
    { name: '制限なし', value: Level.UNLIMITED },
  ]

  private breadcrumbs = [
    { text: TEACHER_SETTING_TEXT, href: '/teacher/setting/top' },
    { text: 'カスタムモード追加', active: true },
  ]
  private listIcon: Icon[] = ICON_LIST
  private iconActive: Icon = { ...this.listIcon[0] }
  private isFirst = true

  private modeId = this.$route.params?.modeId

  private mode: ModeType = {
    name: '',
    description: '',
    s3_path_id: this.iconActive.id,
    learning_flows: [],
    learning_end_condition: {
      type: 1,
      data: [],
    },
    learning_range: {
      type: 1,
      data: {},
    },
    study_period: { start: null, end: null },
    depth_of_trace: Level.OFF,
    is_random_question: false,
    difficulty_settings: this.modeId ? undefined : [],
    is_ocr_mode: false,
  }
  private detailMode = _.cloneDeep(this.mode)
  private isChange = false
  private isModeDefault = false
  private isEdit = false
  private isLoading = true
  private awaitCallApi = false

  private iconActiveOrigin: Icon = { id: -1, value: '', image: '' }

  private period = ''
  private periodDate: { start: Date | null; end: Date | null } = { start: null, end: null }
  private showModeDefault = false
  private showPopupAddIcon = false
  private showPopupLearnEndCondition = false
  private disableCalendar = false

  private subjects: Subject[] = []

  private async getDetailMode(id: number) {
    try {
      Vue.prototype.$loading.start()
      const branchId: number = Vue.prototype.$cookies.get('dataGdls').branchId
      const res = await Vue.prototype.$http.httpWithToken.get(`/v3/class_modes/${id}?branchId=${branchId}`)
      Vue.prototype.$loading.complete()
      const mode: ModeType = convertMode(res.data)
      mode.id = id
      this.detailMode = mode
      this.mode = _.cloneDeep(mode)
      this.isModeDefault = mode?.is_mode_default || false
      this.isEdit = true
      this.iconActive = this.listIcon.find((item) => item.id == this.mode.s3_path_id) || { ...this.listIcon[0] }
      this.periodDate = this.getDate()
      const formatDate = this.formatDate(this.mode.study_period?.start || '', this.mode.study_period?.end || '')
      this.period = formatDate.includes('Invalid') ? '' : formatDate
      setTimeout(() => {
        this.isFirst = false
      }, 150)
    } catch {
      this.$router.push('/teacher/setting/list-mode')
    }
  }

  private getDate() {
    const start = this.mode.study_period?.start || ''
    const end = this.mode.study_period?.end || ''
    return { start: new Date(start), end: new Date(end) }
  }

  private selectMode(modeSelect: { id: number; name: string }) {
    this.modeDefault = _.cloneDeep(MODE_DEFAULT[modeSelect.id - 1])
    this.processFillData(MODE_DEFAULT[modeSelect.id - 1])
    this.levelDifficultRef.getDifficultItems()
    this.showModeDefault = false
  }

  private onChangeRangeDate(params: { start: moment.Moment; end: moment.Moment }) {
    const start = params.start.format('YYYY-MM-DD')
    const end = params.end.format('YYYY-MM-DD')
    const messageError = checkDate(start, end)
    const _isUpdate =
      (this.isFirst && this.detailMode?.id && this.detailMode.study_period?.start) ||
      (start === this.detailMode.study_period?.start && end === this.detailMode.study_period.end)
    if (!messageError || _isUpdate) {
      this.mode.study_period = { start, end }
      this.isFirst = false
      return
    }
    setTimeout(() => {
      this.period = ''
      this.mode.study_period = { start: null, end: null }
    }, 50)
    alert(messageError)
  }

  private async loadSubjects() {
    await Vue.prototype.$http.httpWithToken.get('/subjects').then((res: any) => {
      this.subjects = res.data.map((item: any) => {
        return { ...item, checked: false, subjectId: item.id }
      })
    })
  }

  private async mounted() {
    await this.loadSubjects()
    if (this.modeId) {
      await this.getDetailMode(+this.modeId)
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  private formatDate(_start: string, _end: string) {
    const start = moment(_start)
    const end = moment(_end)
    start.locale('ja')
    end.locale('ja')
    return (
      start.format('LL') +
      '~' +
      (start.format('YYYY') === end.format('YYYY') ? end.format('MM月DD日') : end.format('LL'))
    )
  }

  private handelDisableCalendar(idSelect: number) {
    if (idSelect == 3) {
      this.disableCalendar = true
      this.mode.study_period = { start: null, end: null }
      this.period = ''
    } else {
      this.disableCalendar = false
    }
    this.mode.learning_range.type = idSelect
  }

  private chooseIcon() {
    this.showPopupAddIcon = false
    this.mode.s3_path_id = this.iconActive.id
  }

  private cancelPopup() {
    this.iconActive = { ...this.iconActiveOrigin }
    this.showPopupAddIcon = false
  }

  private handleFlowLearning(_classPolycies: ClassPolycies[]) {
    this.mode.learning_flows = [..._classPolycies]
  }
  private handleConditionEndLearning(type: number, conditionEnd: ConditionExit[], isChange = false) {
    this.mode.learning_end_condition = { type, data: conditionEnd }
    this.isChange = isChange
  }

  private handleScopeLearning(type: number, data: any, scope: any) {
    this.mode.learning_range = { type, data, scope }
  }

  private handleSetDifficult(_: string, difficultData: { RK?: DifficultTableItem[]; EN?: DifficultTableItem[] }) {
    const difficultDataTransformed: {
      class_category_code: string
      subject_code: string
      subject_id: number
      levels: number[]
    }[] = []
    Object.keys(difficultData).forEach((difficultType) => {
      difficultData[difficultType].forEach((difficultItem: DifficultTableItem) => {
        difficultDataTransformed.push({
          class_category_code: difficultType,
          subject_code: difficultItem.subjectCode,
          subject_id: difficultItem.subjectId,
          levels: [1, 2, 3].filter((item) => difficultItem['difficult' + item].checked),
        })
      })
    })
    this.mode.difficulty_settings = difficultDataTransformed
  }

  private processFillData(data: any) {
    this.mode.is_random_question = data.is_random_question
    this.mode.depth_of_trace = data.depth_of_trace
    this.mode.learning_range.type = data.learning_range.type
    this.mode.learning_range.data = data.learning_range.data
    this.mode.study_period = data.study_period
    const formatDate = this.formatDate(this.mode.study_period?.start || '', this.mode.study_period?.end || '')
    this.period = formatDate.includes('Invalid') ? '' : formatDate
  }
  private async handleSubmit() {
    const msgError = validateMode(this.mode)
    if (msgError) {
      alert(msgError)
      return
    }
    this.awaitCallApi = true
    if (this.isEdit) {
      await this.updateMode()
    } else {
      await this.createMode()
    }
  }

  private async createMode() {
    try {
      const branchId: number = Vue.prototype.$cookies.get('dataGdls').branchId
      const dataForm = processDataPreSubmit(this.mode, branchId)
      await Vue.prototype.$http.httpWithToken.post('/v3/class_modes', { ...dataForm })
      this.awaitCallApi = false
      alert('新規カスタムモードが追加されました！')
      this.$router.push('/teacher/setting/list-mode')
    } catch (e) {
      const errorMgs: any = e
      if (errorMgs.response?.status == 400) {
        alert(MAXIMUM_MODE_MESSAGE)
        this.$router.push('/teacher/setting/list-mode')
      } else {
        alert('作成モード失敗！もう一度お試しください。')
      }
    }
    this.awaitCallApi = false
  }
  private async updateMode() {
    try {
      const branchId: number = Vue.prototype.$cookies.get('dataGdls').branchId
      const dataForm = processDataPreSubmit(this.mode, branchId)
      await Vue.prototype.$http.httpWithToken.patch(`/v3/class_modes/${this.detailMode.id}`, { ...dataForm })
      this.awaitCallApi = false
      alert('更新モードが成功しました!')
      this.$router.push('/teacher/setting/list-mode')
    } catch (e) {
      const errorMgs: any = e
      if (errorMgs.response.status == 400) {
        alert(MAXIMUM_MODE_MESSAGE)
        this.$router.push('/teacher/setting/list-mode')
      } else {
        alert('作成モード失敗！もう一度お試しください。')
      }
    }
    this.awaitCallApi = false
  }

  private getSubjectWithMode(subjects: Subject[]) {
    if (this.isOcrLlmMode) {
      return subjects.filter(item => item.code === 'su')
    }
    return subjects
  }

  private getDataInitWithMode(subjects: Subject[], learningEndCondition: {
    type: number
    data?: ConditionExit[]
    isChange?: boolean
  }) {
    const subjectIdMath = subjects.reduce((result: (number | string)[], item: Subject) => (item.code === 'su' ? [...result, item.id] : result), [])

    if (this.isOcrLlmMode) {
      const newLeaningENdCondition = { ...learningEndCondition, data: learningEndCondition.data?.filter(item => item.subjectId && subjectIdMath.includes(item.subjectId)) }
      return newLeaningENdCondition
    }
    return learningEndCondition
  }

  private get isOcrLlmMode() {
    return this.detailMode.is_ocr_mode
  }
}
