





















import { Component, Prop, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import { Notification } from '@/components/organisms/NotificationList.vue'

@Component({
  components: {
    ColoredBox,
  },
})
export default class NotificationList extends Vue {
  private showAll = false

  private defaultShowNotifications = 1

  @Prop()
  items!: []

  @Prop()
  radius!: string

  @Prop()
  linkClass!: string

  get setStyle(): { [key: string]: string } {
    const linkColor = this.linkClass === 'student' ? '--color-blue-1' : '--color-blue'
    return {
      '--link-color': `var(${linkColor})`,
    }
  }

  private notifications: Notification[] = []

  private isShown(index: number): boolean {
    return this.showAll || index < this.defaultShowNotifications
  }

  private expand(): void {
    this.showAll = true
  }

  private collapse(): void {
    this.showAll = false
  }

  private mounted() {
    this.notifications = this.items.filter((notification: Notification) => {
      const contentWithTitle = `${notification.title}${notification.content}`
      const monoxerRegex = /([ｍm][ｏo][ｎn][ｏo][ｘx][ｅe][ｒr])/i
      const hasMonoxerKeyword = monoxerRegex.test(contentWithTitle)
      const isMonoxerBranch = ['GPAPILS', 'CAI'].includes(Vue.prototype.$cookies.get('dataGdls').academyCode)
      if (!hasMonoxerKeyword) return true
      if (hasMonoxerKeyword && isMonoxerBranch) {
        return true
      }
      return false
    })
  }
}
