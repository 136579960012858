







































import { Component, Vue, Ref, Mixins } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import NotificationList, { Notification } from '@/components/organisms/NotificationList.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import HomeworkList from '@/components/organisms/HomeworkList.vue'
import LabelBase from '@/components/atoms/LabelBase.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ModalNotification from '@/components/organisms/ModalNotification.vue'
import WeekCodeMethods from '@/components/atoms/WeekCodeMethods.vue'
import moment from 'moment'
import { TimetableNextDateType } from '@/models/api/timetables'
import QueryMethods from '@/components/atoms/QueryMethods.vue'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'
import { StudentGoal } from '@/models/api/studentGoal'
import StudentGoalApi from '@/mixins/studentGoals/StudentGoalApi'
import { ResultProgressesResponseType, TermInformationType } from '@/models/api/resultProgresses'
import { SubjectProgressType, StepProgressMethods } from '@/mixins/utils/StepProgressMethods'
import { FormatResultSummaries } from '@/models/api/resultSummaries'
import { EntranceResult } from '@/models/api/history/entrance'

import TJContents from '@/components/organisms/dashboard/TJContents.vue'
import TKContents from '@/components/organisms/dashboard/TKContents.vue'
import KSContents from '@/components/organisms/dashboard/KSContents.vue'
import NYContents from '@/components/organisms/dashboard/NYContents.vue'

@Component({
  components: {
    SidebarSwitcher,
    NotificationList,
    ColoredBox,
    HomeworkList,
    LabelBase,
    ButtonBase,
    ModalNotification,
    TJContents,
    TKContents,
    KSContents,
    NYContents,
  },
})
export default class Dashboard extends Mixins(
  WeekCodeMethods,
  QueryMethods,
  ClassModeChangeAble,
  StudentGoalApi,
  StepProgressMethods
) {
  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId

  private isHomeLearningEnabled = Vue.prototype.$cookies.get('dataGdls').isHomeLearningEnabled || false

  // cookieセット完了フラグ
  private cookieReady = false

  // クラスモード
  public classMode(): string {
    return Vue.prototype.$cookies.get('dataGdls').classMode || this.CLASS_MODE.NORMAL
  }

  // クラスモード毎に表示する子コンポーネント
  private get contentsComponent() {
    return `${this.classMode()}Contents`
  }

  private get classModeName() {
    return this.classModeText(this.classMode())
  }

  private get startUrl() {
    if (this.isTestMode) {
      return '/student/subjects/EN'
    } else if (this.isEntranceMode) {
      // 入試モードの場合、都道府県未選択時は都道府県選択へ遷移
      return this.isSelectedPrefecture ? '/student/subjects' : '/student/prefectures'
    } else {
      return '/student/subjects/KK'
    }
  }

  // 都道府県選択状態(入試モードの学習開始URL分けに使用)
  private isSelectedPrefecture = false

  /**
   * 過去に登録済みの都道府県取得
   */
  private async loadStudentPrefecture() {
    const result = await Vue.prototype.$http.httpWithToken.get('/entrance/student_prefectures')
    this.isSelectedPrefecture = result.data?.prefectureId ? true : false
  }

  private notificationDatas = []

  private homeworkDatas = []

  private nextDate: TimetableNextDateType = {
    date: '',
    week: '',
    isEnable: false,
    isInLesson: false,
    isTransferEnable: false,
  }

  private intervalId: any = 0

  private currentGoal: StudentGoal | null = null
  private seasonSettingPeriod: { startAt: string; endAt: string; text: string } | null = null
  private subjectSteps: SubjectProgressType[] = []
  private termInformation: TermInformationType | null = null
  private understandingTotal = {}
  private understandingHistories: object[] = []
  private pretestResults: EntranceResult[] = []

  @Ref()
  modalNotification!: ModalNotification

  private showNotification(id: number): void {
    this.modalNotification.loadNotification(id)
  }

  private async loadNotifications() {
    const params = ['role=student']
    await Vue.prototype.$http.httpWithToken.get(`/notifications?${params.join('&')}`).then((res: any) => {
      this.notificationDatas = res.data.notifications.map(
        (notification: Notification) => {
          return {
            date: moment(notification.startedAt).format('YYYY/MM/DD'),
            title: notification.title,
            click: this.showNotification,
            content: notification.message,
            variable: notification.id,
          }
        }
      )
    })
  }

  private get startColortype(): string {
    switch (this.classMode()) {
      // 講習会、定期テスト
      case this.CLASS_MODE.SEMINAR:
      case this.CLASS_MODE.TEST:
        return this.buttonEnableTsTk() ? 'gradation-orange' : 'pointer-events-none'
      // 通常
      default:
        return this.buttonEnable() ? 'gradation-orange' : 'pointer-events-none'
    }
  }

  private buttonEnable() {
    // 家庭学習OFFの場合、授業中以外は押下不可
    if (!this.isHomeLearningEnabled && !this.nextDate.isInLesson) return false
    return true
  }

  private buttonEnableTsTk() {
    // 期間外の場合は押下不可
    if (!this.nextDate.isEnable) return false
    // 家庭学習OFFの場合、授業中以外は押下不可
    if (!this.isHomeLearningEnabled && !this.nextDate.isInLesson) return false
    return true
  }

  private get startText(): string {
    // 家庭学習OFFの場合、ボタンのテキストは常に「授業を始める」
    if (!this.isHomeLearningEnabled) return '授業を始める'
    // 家庭学習ONの場合、ボタンのテキストは授業中なら「授業を始める」、授業外なら「学習を始める」
    return this.nextDate.isInLesson ? '授業を始める' : '学習を始める'
  }

  private get transferColortype(): string {
    return this.nextDate.isTransferEnable ? 'gradation-orange' : 'pointer-events-none'
  }

  private async loadNextDate(): Promise<any> {
    const params = [`userId=${this.userId}`, `classMode=${this.classMode()}`]
    await Vue.prototype.$http.httpWithToken
      .get(`/student_timetables/next_date?${params.join('&')}`)
      .then((res: { data: TimetableNextDateType }) => {
        this.nextDate.date = res.data.date ? res.data.date : '未定'
        this.nextDate.week = res.data.week ? `（${this.weekCodeToText(res.data.week)}）` : ''
        this.nextDate.isEnable = res.data.isEnable
        this.nextDate.isInLesson = res.data.isInLesson
        this.nextDate.isTransferEnable = res.data.isTransferEnable
      })
  }

  private async loadHomework() {
    const params = [`userId=${this.userId}`, `classMode=${this.classMode()}`]
    const { data } = await Vue.prototype.$http.httpWithToken.get(`/result_drills/homework/not_yet?${params.join('&')}`)
    this.homeworkDatas = data.map(
      (info: { subjectId: number; subjectCode: string; subjectName: string; count: number }) => {
        return {
          code: info.subjectCode,
          subject: info.subjectName,
          content: `未実施${info.count}件`,
          url: '/student/confirmation/SY',
        }
      }
    )
  }

  // 目標設定取得
  private async loadGoal() {
    // 通常、テスト対策モードでのみ呼び出す
    if (this.classMode() === this.CLASS_MODE.NORMAL || this.classMode() === this.CLASS_MODE.TEST) {
      this.currentGoal = await this.loadCurrentGoal(this.classMode())
    }
  }

  // 講習会期間取得
  private async loadSeasonSettingPeriod() {
    // 講習会モードでのみ呼び出す
    if (this.classMode() !== this.CLASS_MODE.SEMINAR) return
    await Vue.prototype.$http.httpWithToken
      .get('/seasonSettings/seasons', { params: { userId: this.userId } })
      .then((res: any) => {
        const current = res.data.find((season: { isSelected: boolean }) => {
          return season.isSelected === true
        })
        if (current) {
          const startAt = current.from
          const endAt = current.to
          const [startYear, startMonth, startDay] = startAt.split('-')
          const [endYear, endMonth, endDay] = endAt.split('-')
          let period = `${startYear}年${startMonth}月${startDay}日〜`
          if (startYear !== endYear) period += `${endYear}年`
          period += `${endMonth}月${endDay}日`
          this.seasonSettingPeriod = { startAt: startAt, endAt: endAt, text: period }
        }
      })
  }

  // テスト期間情報取得
  private async loadResultProgresses() {
    // テスト対策モードでのみ呼び出す
    if (this.classMode() !== this.CLASS_MODE.TEST) return
    await Vue.prototype.$http.httpWithToken
      .get(`/history/resultProgresses/${this.userId}`)
      .then(({ data }: { data: ResultProgressesResponseType }) => {
        this.subjectSteps = this.parseStepProgresses(data)
        this.termInformation = data.termInformation
      })
      .catch(() => {
        this.subjectSteps = []
      })
  }

  // 学習状況取得
  private async loadResultSummaries() {
    // 通常、講習会、入試モードでのみ呼び出す
    if (
      this.classMode() === this.CLASS_MODE.NORMAL ||
      this.classMode() === this.CLASS_MODE.SEMINAR ||
      this.classMode() === this.CLASS_MODE.ENTRANCE
    ) {
      const params = { classModeCode: this.classMode() }
      if (this.seasonSettingPeriod) {
        params['startAt'] = this.seasonSettingPeriod.startAt
        params['endAt'] = this.seasonSettingPeriod.endAt
      }
      await Vue.prototype.$http.httpWithToken
        .get(`/history/resultSummaries/${this.userId}`, {
          params: params,
        })
        .then((res: any) => {
          this.understandingTotal = FormatResultSummaries(res.data)
        })
    }
  }

  // 直近の履歴を取得
  private async loadLatestCurriculum() {
    // 通常モードのみ呼び出す
    if (this.classMode() !== this.CLASS_MODE.NORMAL) return
    await Vue.prototype.$http.httpWithToken
      .get('/history/resultDrills/latestCurriculum', {
        params: { userId: this.userId, classModeCode: this.classMode() },
      })
      .then((res: any) => {
        this.understandingHistories = res.data.map(
          (curriculums: {
            subjectCode: string
            subjectName: string
            sUnitId: number
            sUnitName: string
            predictedScore: number
            predictedScoreMin: number
            predictedScoreMax: number
            date: string
            count: number
          }) => {
            const rateText = `${curriculums.predictedScoreMin}%-${curriculums.predictedScoreMax}%`
            return {
              sUnitId: curriculums.sUnitId,
              subject: { name: curriculums.subjectName, code: curriculums.subjectCode },
              content: curriculums.sUnitName,
              date: curriculums.date,
              count: curriculums.count,
              rates: [{ rate: curriculums.predictedScore, rateText: rateText }],
            }
          }
        )
      })
  }

  // 入試対策取り組み状況を取得
  private async loadPretestResults() {
    // 入試モードのみ呼び出す
    if (this.classMode() !== this.CLASS_MODE.ENTRANCE) return
    Vue.prototype.$http.httpWithToken
      .get(`/history/entranceResult`, {
        params: { userId: this.userId, isLatestOnly: true },
      })
      .then((res: any) => {
        this.pretestResults = res.data
      })
  }

  private created(): void {
    this.setClassModeFromQuery()
  }

  private async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.loadNotifications()
    await this.loadNextDate()
    await this.loadHomework()
    await this.loadGoal()
    await this.loadResultProgresses()
    await this.loadSeasonSettingPeriod()
    await this.loadResultSummaries()
    await this.loadLatestCurriculum()
    await this.loadPretestResults()
    await this.loadStudentPrefecture()
    // 授業の開始情報は5分おきに取得する
    this.intervalId = setInterval(() => {
      // cookieの期限切れであれば生徒ログイン画面に遷移する
      if (!Vue.prototype.$cookies.get('dataGdls')) {
        this.$router.push({ name: 'StudentsLogin' })
        return
      }
      this.loadNextDate()
    }, 300000)
    Vue.prototype.$loading.complete()
  }

  private beforeDestroy() {
    clearInterval(this.intervalId)
  }

  /**
   * クラスモードをcookieにセット&APIリクエスト
   * Queryにてmode指定がある場合は優先する
   * ex. /student/dashboard?mode=TJ
   * Query指定が無い場合は既存のcookieの値を参照し、未設定の場合は通常モードとする
   */
  private async setClassModeFromQuery() {
    const classModeFromQuery = this.getParamsObject().mode
    if (this.isValidClassMode(classModeFromQuery)) {
      await this.changeClassMode(classModeFromQuery)
    } else {
      await this.changeClassMode(Vue.prototype.$cookies.get('dataGdls').sideMenuMode || this.CLASS_MODE.NORMAL)
    }
    this.cookieReady = true
  }
}
