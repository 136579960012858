











import { Component, Vue, Mixins } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import VictoryBranch from '@/mixins/v3/VictoryBranch'

const KANJI_LINK = 'https://gv02.gakken.jp/study/kanjicontents/index'

@Component({
  components: {
    ButtonBase,
  },
})
export default class VictoryCurriculumSUnitsPage extends Mixins(VictoryBranch) {
  private KANJI_PAGE = 'kanji'
  private async handleBackPage() {
    Vue.prototype.$loading.start()
    const { subjectId, subjectName, subjectCode } = this.$route.query
    let backUrl = `/student/v3/victory/units/${subjectCode}?subjectId=${subjectId}&subjectName=${subjectName}`
    await Vue.prototype.$http.httpWithToken.post('/v3/result_victory_unit_urls/complete', { id: this.victoryUrlId })
    if (this.typePage === this.KANJI_PAGE) {
      backUrl = '/student/v3/victory'
    }
    this.$router.push(backUrl)
    Vue.prototype.$loading.complete()
  }

  private iframeUrl = ''
  private victoryUrlId = null
  private unitName = this.$route.query.unitName

  private get typePage() {
    return this.$route.params.typePage
  }

  private async mounted() {
    if (!this.isVictoryBranch) {
      this.$router.push('/student/v3/dashboard')
    } else {
      Vue.prototype.$loading.start()
      await this.handleGetIframeLink()
      Vue.prototype.$loading.complete()
    }
  }

  private async handleGetIframeLink() {
    const { subjectId, unitId } = this.$route.query
    const result = await Vue.prototype.$http.httpWithToken.post('/v3/result_victory_unit_urls', {
      victory_unit_id: unitId || null,
      subject_id: subjectId,
      url_type: this.typePage === this.KANJI_PAGE ? null : this.typePage,
    })
    this.iframeUrl = this.typePage === this.KANJI_PAGE ? KANJI_LINK : result.data.url
    this.victoryUrlId = result.data.id
  }
}
