

































import { Component, Vue, Ref, Mixins } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import CheckboxWithLabel from '@/components/molecules/CheckboxWithLabel.vue'
import RangeDatePicker from '@/components/molecules/RangeDatePicker.vue'
import moment from 'moment'
import LocalMoment from '@/components/atoms/LocalMoment.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import LoadClassOptionsApi from '@/mixins/teacher/LoadClassOptionsApi'
import FaceCheckbox from '@/components/molecules/v3/FaceCheckbox.vue'
import NursingBranch from '@/mixins/v3/NursingBranch'

@Component({
  components: {
    CheckboxWithLabel,
    RangeDatePicker,
    ButtonBase,
    SelectBase,
    FaceCheckbox,
  },
})
export default class ReportSearch extends Mixins(LocalMoment, LoadClassOptionsApi, NursingBranch) {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private gradeDatas: object[] = [
    { label: '小1', value: 's1' },
    { label: '小2', value: 's2' },
    { label: '小3', value: 's3' },
    { label: '小4', value: 's4' },
    { label: '小5', value: 's5' },
    { label: '小6', value: 's6' },
    { label: '中1', value: 't1' },
    { label: '中2', value: 't2' },
    { label: '中3', value: 't3' },
  ]

  private studentId = ''
  private studentName = ''
  private schoolName = ''

  private classOptionDatas: { text: string; value: string | null }[] = []
  private classSelectedData: string | null = null

  private attributeOptionDatas: { text: string; value: string | null }[] = []
  private attributeSelectedData: string | null = null

  private faceCheckbox = [
    { value: 1, label: 'とてもよくできた！' },
    { value: 2, label: 'できた！' },
    { value: 3, label: '少し不安' },
    { value: 4, label: 'できなかった' },
    { value: 5, label: '不具合を見つけた' },
    { value: 6, label: '先生に質問したい' },
  ]

  @Ref() rangeDatePicker!: RangeDatePicker

  private async mounted() {
    await this.loadOptions()
    // クッキーに検索条件が存在する場合はセットする
    const conditions = Vue.prototype.$cookies.get('dataGdls').reportConditions
    if (conditions) {
      this.restoreConditions(conditions)
    } else {
      this.select()
    }
  }

  private handleChangeFace(faces: any[]) {
    this.faceCheckbox = [...faces]
  }

  private clear() {
    this.rangeDatePicker.startDatePickerBase.selectedDate = this.beforeDays(14).toDate()
    this.rangeDatePicker.endDatePickerBase.selectedDate = null
    this.studentId = ''
    this.studentName = ''
    this.schoolName = ''
    this.gradeDatas.forEach((data: any) => {
      data.checked = false
    })
    this.classSelectedData = null
    this.attributeSelectedData = null
    this.faceCheckbox = this.faceCheckbox.map((face: any) => {
      return { ...face, checked: false }
    })
  }

  private select() {
    const conditionsDatas = []

    // 期間開始
    const startDate = this.rangeDatePicker.startDatePickerBase.selectedDate
    if (startDate) {
      conditionsDatas.push({
        label: '期間開始',
        name: moment(startDate).format('YYYY/MM/DD'),
        key: 'startedAtGteq',
        value: moment(startDate).startOf('day').format('YYYY/MM/DD HH:mm:ssZ'),
      })
    }

    // 期間終了
    const endDate = this.rangeDatePicker.endDatePickerBase.selectedDate
    if (endDate) {
      conditionsDatas.push({
        label: '期間終了',
        name: moment(endDate).format('YYYY/MM/DD'),
        key: 'startedAtLteq',
        value: moment(endDate).endOf('day').format('YYYY/MM/DD HH:mm:ssZ'),
      })
    }

    // 生徒id
    if (this.studentId.length > 0) {
      conditionsDatas.push({ label: '生徒id', name: this.studentId, key: 'studentCode', value: this.studentId })
    }

    // 生徒氏名
    if (this.studentName.length > 0) {
      conditionsDatas.push({ label: '生徒氏名', name: this.studentName, key: 'studentName', value: this.studentName })
    }

    // 学校名
    if (this.schoolName.length > 0) {
      conditionsDatas.push({ label: '学校名', name: this.schoolName, key: 'schoolNameCont', value: this.schoolName })
    }

    // メモ・不具合
    this.addCheckBoxConditionData(conditionsDatas, this.faceCheckbox, 'メモ・不具合', 'note_report_result')
    // 学年
    this.addCheckBoxConditionData(conditionsDatas, this.gradeDatas, '学年', 'grades')

    // クラス・コマ
    this.addSelectConditionData(
      conditionsDatas,
      this.classSelectedData,
      this.classOptionDatas,
      'クラス・コマ',
      'lesson'
    )

    // グループ
    this.addSelectConditionData(
      conditionsDatas,
      this.attributeSelectedData,
      this.attributeOptionDatas,
      // 1 Mod Start 20221129
      // '属性',
      'グループ',
      // 1 Mod End 20221129
      'attribute'
    )

    this.$emit('setConditionsDatas', conditionsDatas)
  }

  private addCheckBoxConditionData(conditionsDatas: any, checkBoxDatas: any, label: string, key: string) {
    const selectedDatas = checkBoxDatas.filter((data: any) => data.checked)
    if (selectedDatas.length > 0) {
      conditionsDatas.push({
        label: label,
        name: selectedDatas.map((data: any) => data.label).join('、'),
        key: key,
        value: selectedDatas.map((data: any) => data.value),
      })
    }
  }

  private addSelectConditionData(
    conditionsDatas: any,
    selectedValue: any,
    selectOptions: { text: string; value: string | null }[],
    label: string,
    key: string
  ) {
    if (!selectedValue) return
    const selectedOption = selectOptions.find((option: any) => option.value === selectedValue)
    if (!selectedOption) return
    conditionsDatas.push({
      label: label,
      name: selectedOption.text,
      key: key,
      value: selectedValue,
    })
  }

  public removeConditionsData(label: string) {
    if (label === '期間開始') this.rangeDatePicker.startDatePickerBase.selectedDate = null
    if (label === '期間終了') this.rangeDatePicker.endDatePickerBase.selectedDate = null
    if (label === '生徒id') this.studentId = ''
    if (label === '生徒氏名') this.studentName = ''
    if (label === '学校名') this.schoolName = ''
    if (label === '学年') {
      this.gradeDatas = this.gradeDatas.map((data: any) => ({ ...data, checked: false }))
    }
    if (label === 'メモ・不具合') {
      this.faceCheckbox = this.faceCheckbox.map((data: any) => ({ ...data, checked: false }))
    }
    if (label === 'クラス・コマ') this.classSelectedData = null
    // 1 Mod Start 20221129
    // if (label === '属性') this.attributeSelectedData = null
    if (label === 'グループ') this.attributeSelectedData = null
    // 1 Mod End 20221129
  }

  private async loadOptions() {
    const emptyOption = [{ text: '', value: null }]
    const attributeOptions = await this.getAttributes()
    this.attributeOptionDatas = emptyOption.concat(attributeOptions)
    const classOptions = await this.loadClassOptions(this.branchId)
    this.classOptionDatas = emptyOption.concat(classOptions)
  }

  // グループ情報読み込み
  private async getAttributes(): Promise<any[]> {
    // グループプルダウンの情報設定
    let attributeOptions: any[] = []
    await Vue.prototype.$http.httpWithToken
      .get(`/v3/student_groups`, { params: { branchId: this.branchId, withLinked: true } })
      .then((res: any) => {
        attributeOptions = res.data.studentGroups.map((userAttributes: { id: number; title: string }) => {
          return {
            text: userAttributes.title,
            value: userAttributes.id.toString(),
          }
        })
      })
    return attributeOptions
  }

  private restoreConditions(conditions: any[]) {
    const start = conditions.find((condition: { label: string }) => condition.label === '期間開始')
    if (start) {
      this.rangeDatePicker.startDatePickerBase.selectedDate = new Date(start.value)
    } else {
      this.rangeDatePicker.startDatePickerBase.selectedDate = null
    }
    const end = conditions.find((condition: { label: string }) => condition.label === '期間終了')
    if (end) {
      this.rangeDatePicker.endDatePickerBase.selectedDate = new Date(end.value)
    }
    const studentId = conditions.find((condition: { label: string }) => condition.label === '生徒id')
    if (studentId) this.studentId = studentId.value
    const studentName = conditions.find((condition: { label: string }) => condition.label === '生徒氏名')
    if (studentName) this.studentName = studentName.value
    const schoolName = conditions.find((condition: { label: string }) => condition.label === '学校名')
    if (schoolName) this.schoolName = schoolName.value
    const grade = conditions.find((condition: { label: string }) => condition.label === '学年')
    if (grade) {
      this.gradeDatas.forEach((data: any) => {
        data.checked = grade.value.includes(data.value)
      })
    }
    const faceCheckbox = conditions.find((condition: { label: string }) => condition.label === 'メモ・不具合')
    if (faceCheckbox) {
      this.faceCheckbox = this.faceCheckbox.map((data: any) => {
        return {
          ...data,
          checked: faceCheckbox.value.includes(data.value),
        }
      })
    }
    const attribute = conditions.find((condition: { label: string }) => condition.label === 'グループ')
    if (attribute) this.attributeSelectedData = attribute.value
    const period = conditions.find((condition: { label: string }) => condition.label === 'クラス・コマ')
    if (period) this.classSelectedData = period.value
    this.$emit('setConditionsDatas', conditions)
  }
}
