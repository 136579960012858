






















import { Component, Vue } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import RadioGroup from '@/components/molecules/RadioGroup.vue'
import CheckboxSquare from '@/components/atoms/CheckboxSquare.vue'
import { TEACHER_SETTING_TEXT } from '@/constants'
import { AxiosError } from 'axios'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    ColoredBox,
    ButtonBase,
    RadioGroup,
    CheckboxSquare,
  },
})
export default class DownloadContent extends Vue {
  private breadcrumbs = [
    { text: TEACHER_SETTING_TEXT, href: '/teacher/setting/top' },
    { text: 'デバックモード', active: true },
  ]

  private fileUpload: File | null = null
  private environmentType = 1
  private contentType = 1

  private downloadExcel = async () => {
    const formData = new FormData()
    console.log(this.fileUpload)
    if (!this.fileUpload) {
      alert('アップロードファイルを選択してください')
      return
    }
    formData.append('csv_file', this.fileUpload)
    Vue.prototype.$loading.start()
    try {
      const headers = { 'Content-Type': 'multipart/form-data' }
      await Vue.prototype.$http.httpWithToken.post('v3/store_user_mistakes/import_csv', formData, {
        headers: headers,
        responseType: 'blob',
      })
      alert('問題CSVファイルのアップロードに成功しました')
    } catch (error) {
      const e = error as AxiosError<{ message: { message: string; row?: number }[]; status: number }>
      if (e.response?.status === 500) {
        alert(e.response.data.message)
        return
      }
      if (e.response && e.response.data.message) {
        alert(
          e.response.data.message
            .map((mess) => {
              return `${mess.row ? 'row_' + mess.row + ' ' : ''}${mess.message}\n`
            })
            .join('')
        )
        return
      }
      alert((error as Error).message)
    } finally {
      Vue.prototype.$loading.complete()
    }
  }

  private environmentRadios = [
    {
      label: 'メソッド環境',
      value: 1,
    },
  ]
  private contentRadios = [
    {
      label: 'GDLS通常学習',
      value: 1,
    },
  ]

  private changeEnvironmentType = (value: any) => {
    this.environmentType = value
  }
  private changeContentType = (value: any) => {
    this.contentType = value
  }
  private changeFileCsv = (e: any) => {
    this.fileUpload = e.target.files[0]
  }
}
