import _Vue from 'vue'
import axios, { AxiosRequestConfig } from 'axios'
import VueCookies from 'vue-cookies'
import _router from 'vue-router'

interface RouterInterface {
  push(path: string): void
}

export default {
  install(Vue: typeof _Vue, router: typeof _router): void {
    // ログインや認証不要な場合のAPI
    const http = this.initAxios()
    // 認証情報付きのAPI
    const httpWithToken = this.initAxios()

    httpWithToken.interceptors.request.use((request: AxiosRequestConfig) => {
      // 認証情報の取得
      const authGdls = VueCookies.get('authGdls')
      if (authGdls?.accessToken != request.headers['access-token']) {
        request.headers['access-token'] = authGdls?.accessToken
        request.headers.client = authGdls?.client
        request.headers.uid = authGdls?.uid
      }
      return request
    })

    // todo 認証後のWeb APIでリダイレクトや共通して処理したい場合はこちらに記述を追加
    httpWithToken.interceptors.response.use(
      (reponse: any) => reponse,
      async (error: any) => {
        // router.push等によりローディング処理が残っているケースがあるため処理を追加
        Vue.prototype.$loading.complete()

        if (!error.response) {
          return alert('接続障害は発生しております。しばらくしてから接続してください。')
        }
        const isQuiz = window.location.pathname.includes('/quiz')
        if (isQuiz) {
          Vue.prototype.$count.complete()
          if (error.response.status === 401) {
            VueCookies.remove('authGdls')
            VueCookies.remove('dataGdls')
            ;(router as unknown as RouterInterface).push(
              '/quiz/error?errorMgs=セッションの有効期限が切れました。</n>再度ログインしてください。'
            )
          }
          if (error.config?.selfHandle) {
            return Promise.reject(error)
          }
          ;(router as unknown as RouterInterface).push(
            `/quiz/error?errorMgs=${
              error.response.data?.message || 'ただいま接続できません。</n>しばらくしてから再度お試しください。'
            }`
          )
          return Promise.reject(error)
        }
        // responseTypeがblobの時にerror.response.dataをjsonに書き換える
        await this.onBlobHandler(error).catch((err: any) => {
          ;(router as unknown as RouterInterface).push('/error/error500')
        })

        const isToolPage = Vue.prototype.$gdlsCookiesV3.isToolPage()

        if (error.response.status === 401) {
          const role = VueCookies.get('authGdls')?.webRole
          let path: string
          const isSozo = Vue.prototype.$gdlsCookiesV3?.isSozo()
          const siteBrand = Vue.prototype.$gdlsCookiesV3.getSiteBrand()
          const redirectStudent = isSozo
            ? '/student/sozo/login'
            : siteBrand
            ? `/student/${siteBrand}/login`
            : '/student/g-method/login'
          const redirectTeacher = isToolPage ? '/teacher/tool/login' : '/teacher/g-method/login'
          // TODO: G-IDの設定状態により、ログイン画面が異なるものは分岐する
          switch (role) {
            case 'admin':
              path = '/admin/login'
              break
            case 'academy':
              path = '/academy/g-method/login'
              break
            case 'branch':
            case 'teacher':
              path = redirectTeacher
              break
            case 'parent':
              path = '/parent/login'
              break
            case 'student':
              path = redirectStudent
              break
            default:
              path = '/error/error500'
              break
          }
          ;(router as unknown as RouterInterface).push(path)
        } else if (isToolPage) {
          return Promise.reject(error)
        } else if (error.response.status === 404 && error.response.data.code !== 40401) {
          ;(router as unknown as RouterInterface).push('/error/error404')
        } else if (error.response.status === 500) {
          ;(router as unknown as RouterInterface).push('/error/error500')
        } else if (error.response.status === 423) {
          ;(router as unknown as RouterInterface).push('/error/error423')
        } else if (error.response.status === 503) {
          ;(router as unknown as RouterInterface).push('/error/error503')
        }
        return Promise.reject(error)
      }
    )

    Vue.prototype.$http = { http, httpWithToken }
  },

  async onBlobHandler(error: any) {
    if (error.request.responseType === 'blob' && error.response.data instanceof Blob) {
      const text = await new Promise((resolve, reject) => {
        const fileReader = new FileReader()

        fileReader.onerror = () => {
          fileReader.abort()
          reject()
        }
        fileReader.onload = () => {
          resolve(fileReader.result)
        }

        fileReader.readAsText(error.response.data)
      })
      // @ts-ignore
      error.response.data = JSON.parse(text)
    }
    return
  },

  initAxios() {
    return axios.create({
      baseURL: process.env.VUE_APP_WEB_API_URL,
      timeout: 180000,
      headers: {
        'Content-Type': 'application/json',
        'X-Language': 'ja',
      },
    })
  },
}

// ? 各画面で言語を変更する場合はAPI前に下記のように設定
// ? Vue.prototype.$http.http.defaults.headers['X-Language'] = 'en'
