























import { Component, Prop, Mixins, Vue } from 'vue-property-decorator'
import RadioOkNg from '@/components/molecules/RadioOkNg.vue'
import ImageMixin from '@/components/modules/drillsv3/molecules/ImageMixin'
import ImageExpansion from '@/components/modules/drillsv3/molecules/ImageExpansion.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    RadioOkNg,
    ImageExpansion,
    ButtonBase,
  },
})
export default class Answer extends Mixins(ImageMixin) {
  @Prop()
  private tabName?: string
  @Prop()
  private chooseScore?: (correct: boolean) => void
  @Prop()
  imgCheckAnswer!: { ok: boolean; notGood: boolean }
  @Prop()
  questionIndex?: number
  @Prop()
  isModeOcr?: boolean

  private get isReport() {
    return this.$route.path.includes('report')
  }

  private name = this.$uuid.v4()

  public transferExplanatoryVideo(pageIndex: number, questionIndex: number): void {
    const problemIndex = this.problemIndexFromQuestionIndex(pageIndex, questionIndex)
    const sProblemId = this.sProblemIds(pageIndex)[problemIndex]
    const version = this.$route.params.type || 'v3'
    const mode = this.$route.query.mode
    let path = '/student/v3/explanatory-video'
    if (this.isReport) {
      const content: string = (this.$route.query.content as string) || ''
      const type = this.$route.query.type || ''
      path = `/${Vue.prototype.$cookies.get('authGdls').webRole
        }/report/${version}/explanatory-video?content=${encodeURIComponent(content)}&type=${type}`
    }
    this.$router.push({
      path: path,
      query: {
        sProblemId: String(sProblemId),
        resultDrillId: this.$route.query.resultDrillId,
        historyBackUrl: encodeURIComponent(this.$route.fullPath),
        ...(this.isModeOcr ? { mode: 'ocr' } : {})
      },
    })
  }
}
