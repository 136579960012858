import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'

import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import BootstrapVue from 'bootstrap-vue'

import VCalendar from 'v-calendar'

import VModal from 'vue-js-modal'

import UUID from 'vue-uuid'
import store from './store'
import router from './router'

import './assets/css/reset.css'

import NoneLayout from './layouts/NoneLayout.vue'
Vue.component('none-layout', NoneLayout)
import TeacherLayout from './layouts/TeacherLayout.vue'
Vue.component('teacher-layout', TeacherLayout)
import TeacherSettingLayout from './layouts/TeacherSettingLayout.vue'
Vue.component('teacher-setting-layout', TeacherSettingLayout)
import TeacherSignInLayout from './layouts/TeacherSignInLayout.vue'
Vue.component('teacher-sign-in-layout', TeacherSignInLayout)
import StudentLayout from './layouts/StudentLayout.vue'
Vue.component('student-layout', StudentLayout)
import StudentInnerframeLayout from './layouts/StudentInnerframeLayout.vue'
Vue.component('student-innerframe-layout', StudentInnerframeLayout)
import StudentHeadmenuLayout from './layouts/StudentHeadmenuLayout.vue'
Vue.component('student-headmenu-layout', StudentHeadmenuLayout)
import ReportHeadmenuLayout from './layouts/ReportHeadmenuLayout.vue'
Vue.component('report-headmenu-layout', ReportHeadmenuLayout)
import AdminLayout from './layouts/AdminLayout.vue'
Vue.component('admin-layout', AdminLayout)
import AcademyLayout from './layouts/AcademyLayout.vue'
Vue.component('academy-layout', AcademyLayout)
import QuizLayout from './layouts/QuizLayout.vue'
Vue.component('quiz-layout', QuizLayout)
Vue.component('fa-icon', FontAwesomeIcon)
library.add(fas)
Vue.use(BootstrapVue)
Vue.use(VCalendar)
Vue.config.productionTip = false
Vue.use(VModal)
Vue.use(UUID)

// axiosのデフォルトbaseURLを設定
import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT

import './assets/css/reset.css'

import http from './plugins/http'
Vue.use(http, router)

import Loading from './plugins/Loading'
Vue.use(Loading)

import Console from './plugins/Console'
Vue.use(Console)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import VueHead from 'vue-head'
Vue.use(VueHead)

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

import handleError from './plugins/handleError'
handleError.setErrorEventListeners()

import Cookies from './plugins/v2/Cookies'
Vue.use(Cookies)
import Cookies_v3 from './plugins/v3/Cookies'
Vue.use(Cookies_v3)
import Design from './plugins/v3/Design'
Vue.use(Design)

import Storage from './plugins/quiz/Storage'
Vue.use(Storage)
import Check from './plugins/quiz/CheckAnswer'
Vue.use(Check)
import LoadingCountDown from './plugins/quiz/LoadingCountDown'
Vue.use(LoadingCountDown)



import i18n from '@/i18n'



import FallbackImage from '@/mixins/v3/FallbackImage'
Vue.mixin(FallbackImage)

Vue.directive('clickoutside', {
  bind(el: HTMLElement, binding: any, vnode: any) {
    const handler = (event: any) => {
      if (!el.contains(event.target)) {
        binding.value(event);
      }
    };

    document.addEventListener('click', handler);

    // Clean up the event listener on unmount
    vnode.context.$on('hook:beforeDestroy', () => {
      document.removeEventListener('click', handler);
    });
  }
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
