

























































import { Component, Ref, Mixins, Vue } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ModalBranchAdd from '@/components/organisms/v3/ModalBranchAdd.vue'
import BranchInMode from '@/components/organisms/v3/BranchInMode.vue'
import LoadStudentsApi from '@/mixins/v3/LoadStudentsApi'
import GlinkBranchControlable from '@/mixins/teacher/GlinkBranchControlable'
import QueryMethods from '@/components/atoms/QueryMethods.vue'
import _ from 'lodash'
import { BranchType } from '../Branch.vue'

export interface Mode {
  id: number
  name: string
  code: string
  description: string
}

export interface TableBase {
  id: { value: number; checked: boolean }
  image: string
  name: string
}

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    InputWithLabel,
    ButtonBase,
    ModalBranchAdd,
    BranchInMode,
  },
})
export default class AcademyAssignMode extends Mixins(GlinkBranchControlable, LoadStudentsApi, QueryMethods) {
  private breadcrumbs = [
    { text: 'カスタムモード配信', href: '/academy/mode' },
    { text: 'モード割り当て', active: true },
  ]
  @Ref() modalAddBranch!: ModalBranchAdd

  private listMode: Mode[] = []

  private tableBaseCheckboxs = ['id']

  private teacherTableBaseLinks = ['name']

  private tableMultipleLines = ['school']

  private teacherTableBaseImages = ['image']

  private tableBaseFields = [
    { key: 'id', label: '' },
    { key: 'image', label: '' },
    { key: 'name', label: '' },
  ]

  private checkAll = false
  private modeName = ''
  private searchCount = 0
  private tableBaseItems: TableBase[] = []

  private modeActive: Mode = {
    id: 0,
    name: '',
    code: '',
    description: '',
  }
  private listBranchInMode: TableBase[] = []
  private listBranchAlreadyInMode: number[] = []
  private academyId = Vue.prototype.$cookies.get('dataGdls').academyId

  private searchMode() {
    Vue.prototype.$http.httpWithToken
      .get(`/v3/class_modes`, {
        params: { name: this.modeName, limit: 200, owner_type: 'academy', academyId: this.academyId },
      })
      .then((res: any) => {
        this.listMode = res.data.class_modes
      })
      .catch((err: any) => alert(err.message))
  }

  private async mounted() {
    Vue.prototype.$loading.start()
    await this.loadModes()
    await this.loadBranchDatas({})
    Vue.prototype.$loading.complete()
  }

  private async loadModes() {
    Vue.prototype.$http.httpWithToken
      .get(`/v3/class_modes`, { params: { page: 1, limit: 200, owner_type: 'academy', academyId: this.academyId } })
      .then((res: any) => {
        this.listMode = res.data.class_modes
      })
      .catch((err: any) => alert(err.message))
  }

  private searchStudent(params: { branchName: string; branchCode: string }): void {
    this.loadBranchDatas(params)
  }

  private saveStudent(): void {
    const listStudentChecked = this.tableBaseItems.filter((item) => item.id.checked)
    if (listStudentChecked.length == 0) {
      alert('対象のブランチを選択してください。')
      return
    } else {
      this.listBranchInMode = listStudentChecked
    }
    this.hideStudentModal()
  }

  private hideStudentModal(): void {
    this.modalAddBranch.hide()
  }

  private async showBranchAddModal() {
    !_.isEmpty(this.modeActive) ? this.modalAddBranch.show() : true
    const listIdStudent: number[] = []
    this.tableBaseItems = []
    await this.loadBranchDatas({})
    if (this.listBranchInMode.length > 0) {
      this.listBranchInMode.map((item) => {
        listIdStudent.push(item.id.value)
      })
      let branchInModeCount = 0
      this.tableBaseItems.forEach((item, index) => {
        if (listIdStudent.includes(item.id.value)) {
          this.tableBaseItems[index]['id']['checked'] = true
          branchInModeCount++
        }
      })
      if (branchInModeCount === this.tableBaseItems.length) {
        this.checkAll = true
      } else {
        this.checkAll = false
      }
    }
  }

  private getSearchParams(search: { branchName?: string; branchCode?: string }) {
    const params = {
      offset: 0,
      limit: 300,
      academyId: this.academyId,
    }
    if (search.branchName) {
      params['branchName'] = search.branchName
    }
    if (search.branchCode) {
      params['branchCode'] = search.branchCode
    }
    return params
  }

  private async selectOptionMode(mode: Mode) {
    this.modeActive = mode
    await Vue.prototype.$http.httpWithToken
      .get(`/v3/class_mode_branches`, { params: { class_mode_code: mode.code, limit: 200 } })
      .then((res: any) => {
        this.listBranchInMode = []
        this.listBranchAlreadyInMode = []
        if (res.data.branches.length > 0) {
          const listIdStudent: number[] = []
          res.data.branches.map((branch: { id: any }) => {
            listIdStudent.push(branch.id)
            this.listBranchAlreadyInMode.push(branch.id)
          })
          this.tableBaseItems.forEach((item, index) => {
            if (listIdStudent.includes(item.id.value)) {
              this.tableBaseItems[index]['id']['checked'] = true
              this.listBranchInMode.push(item)
            } else {
              this.tableBaseItems[index]['id']['checked'] = false
            }
          })
        } else {
          this.tableBaseItems.forEach((item, index) => {
            this.tableBaseItems[index]['id']['checked'] = false
          })
        }
      })
  }

  private async removeBranchInMode(index: number) {
    const branch = this.listBranchInMode[index]
    this.listBranchInMode.splice(index, 1)
    this.tableBaseItems.forEach((item, index) => {
      if (item.id.value == branch.id.value) {
        this.tableBaseItems[index]['id']['checked'] = false
      }
    })
    this.listBranchAlreadyInMode = this.listBranchAlreadyInMode.filter((item) => item !== branch.id.value)
    this.$bvToast.toast(`ブランチを削除しました。`, {
      title: '通知する',
      autoHideDelay: 3000,
      variant: 'success',
      solid: true,
    })
    await Vue.prototype.$http.httpWithToken.delete(
      `/v3/class_mode_branches?class_mode_code=${this.modeActive.code}&branch_ids[]=${branch.id.value}`
    )
  }

  private async addBranch() {
    const branchIdList: number[] = []
    if (this.listBranchInMode.length > 0) {
      this.listBranchInMode.map((item) => {
        if (!this.listBranchAlreadyInMode.includes(item.id.value)) {
          branchIdList.push(item.id.value)
        }
      })
      if (branchIdList.length > 0) {
        await Vue.prototype.$http.httpWithToken
          .post(`/v3/class_mode_branches`, { class_mode_code: this.modeActive.code, branch_ids: branchIdList })
          .then(() => {
            this.listBranchAlreadyInMode.push(...branchIdList)
            this.$bvToast.toast(`モードにブランチが追加されました。`, {
              title: '通知する',
              autoHideDelay: 3000,
              variant: 'success',
              solid: true,
            })
          })
          .catch((error: any) => {
            if (error.response.data.status === 400) {
              alert(error.response.data.message)
            } else if (error.response.data.status === 409) {
              alert('教室コードもしくは教室名が重複したものが申請中か、既に登録されています。')
            }
          })
      }
    }
  }

  private async loadBranchDatas(paramsSearch: { branchName?: string; branchCode?: string }) {
    const params = this.getSearchParams(paramsSearch)

    const res = await Vue.prototype.$http.httpWithToken.get(`/branches/requests`, { params: params })
    this.searchCount = res.count
    this.tableBaseItems = res.data.branches.map((branch: BranchType) => {
      return {
        id: {
          value: branch.branchId,
          checked: this.listBranchAlreadyInMode.includes(branch.branchId),
        },
        name: branch.branchName + '・' + branch.branchCode,
        image: '',
      }
    })
  }
}
