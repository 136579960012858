













import { Component, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'

@Component({
  components: {
    ColoredBox,
    TitleBase,
    TitleTextBase,
  },
})
export default class AcademyMode extends Vue {
  private modeSettings = [
    { url: '/academy/list-mode', name: 'モード確認・追加・変更' },
    { url: '/academy/assign-mode', name: 'モード割り当て' },
  ]

  private breadcrumbs = [{ text: 'カスタムモード配信', active: true }]
}
