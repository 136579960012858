import { Component, Vue } from 'vue-property-decorator'

const PREVIOUS_UNIT_LIST_POSITION_KEY = 'PREVIOUS_UNIT_LIST_POSITION'

@Component
export default class PositionListUnit extends Vue {
  protected setScrollPosition() {
    localStorage.setItem(
      PREVIOUS_UNIT_LIST_POSITION_KEY,
      JSON.stringify({ [this.$route.path]: Math.floor(window.scrollY) })
    )
  }

  protected scrollToPrePosition() {
    const previousPath = JSON.parse(localStorage.getItem(PREVIOUS_UNIT_LIST_POSITION_KEY) || '{}')
    const previousPosition = previousPath[this.$route.path]
    if (previousPosition) {
      window.scrollTo({
        top: previousPosition,
        behavior: 'smooth',
      })
    }
  }
}
