





import { Component, Mixins, Vue } from 'vue-property-decorator'
import Title from '@/components/modules/drillsv3/atoms/Title.vue'
import ResultGuide from '@/components/modules/drillsv3/atoms/ResultGuide.vue'
import Correct from '@/components/modules/drillsv3/organisms/Correct.vue'
import Transition from '@/components/modules/drillsv3/organisms/Transition.vue'

import { PAGE_TYPE } from '@/store/modules/DrillsV3'

import DrillsConfig from '@/mixins/drillsv3/Config'
import DrillTransition from '@/mixins/drillsv3/DrillTransition'
import DrillWebApi from '@/mixins/drillsv3/WebApi'
import DrillHistoryApi from '@/mixins/drillsv3/HistoryApi'

@Component({
  components: {
    Title,
    ResultGuide,
    Transition,
    Correct,
  },
})
export default class DrillResult extends Mixins(DrillWebApi, DrillHistoryApi, DrillTransition) {
  // 読み込み完了フラグ
  private loadComplete = false

  private drillModules = DrillsConfig.drillModules

  private get page(): number {
    return Number(this.$route.query.page)
  }

  protected async mounted(): Promise<any> {
    Vue.prototype.$loading.start()

    Vue.prototype.$logger.info('-- DrillResult V3 mounted')

    // 読み込み完了フラグを未完了に変更
    this.$store.commit('drillsV3/setProcessed', false)

    // ページタイプ設定
    this.$store.commit('drillsV3/setPageType', PAGE_TYPE.RESULT_UNIT)
    const drillType = this.$route.query?.drillType as string
    const questionCode = this.$route.query?.questionCode as string

    // 正誤入力情報を取得
    const resultProblem = await this.getResultProblem(this.resultDrillId, this.page, questionCode?.length ? { question_code: questionCode } : undefined)
    this.$store.commit('drills/setEndCondition', resultProblem.endConditions)
    this.$store.commit('drillsV3/setCurrentPage', Number(resultProblem.pageNum))
    if (resultProblem.endConditions.left == 0 || resultProblem.isFinished || drillType?.length) {
      await this.completeDrillApi(this.resultDrillId)
      this.$router.push({ path: `/student/v3/drill/result/?resultDrillId=${this.resultDrillId}` + (drillType?.length ? `&drillType=${drillType}&questionCode=${questionCode}&curriculumSUnitId=${this.$route.query?.curriculumSUnitId}` : '') + (this.$route.query?.mode?.length ? `&mode=${this.$route.query.mode}` : '') })
      return
    }
    Vue.prototype.$loading.complete()

    // 授業モードと授業種別を保持
    this.$store.commit('drillsV3/setClassModeCode', resultProblem.classModeCode)
    this.$store.commit('drillsV3/setClassCategoryCode', resultProblem.classCategoryCode)

    // 該当ページが未採点の場合は問題画面へ
    if (!resultProblem.isCorrect) {
      this.$router.push({
        path: this.urlDrill,
      })
      return
    }

    // 表示用の情報を取得し、ストアに保持
    const sProblemIds = resultProblem.problems.map((problem) => {
      return problem.sProblemId
    })
    const problems = await this.getProblems([sProblemIds], 'v3', questionCode?.length ? { question_code: questionCode } : undefined)
    await this.$store.dispatch('drillsV3/setProblems', problems)

    // 正誤情報をストアに保持
    await this.$store.dispatch('drillsV3/setProblemCorrects', [resultProblem.problems])
    await this.$store.dispatch('drillsV3/setResultProblem', resultProblem)

    // 動画視聴状況を登録
    await this.$store.dispatch('drillsV3/setMoviePlayed', [resultProblem.problems])

    // 読み込み完了フラグを設定
    this.$store.commit('drillsV3/setProcessed', true)
    this.loadComplete = true

    Vue.prototype.$loading.complete()
  }
}
