import {
  ClassPolycies,
  ConditionDTO,
  ConditionExit,
  FlowLearningDTO,
  LearningRangeDataType,
  LearningRangeType,
  ModeType,
  ModeTypeDTO,
  PageType,
  Scope,
  ScopeUnit,
  TypeScope,
  UnitType,
  DifficultUpdateData,
  DifficultSetting,
} from '@/types/teacher/mode'
import _ from 'lodash'
import moment from 'moment'

export const filterGradeId = (units: UnitType[]) => {
  const result: { [gradeId: number]: number[] } = {}
  units.forEach((item) => {
    if (!(item.gradeId in result)) {
      result[item.gradeId] = []
    }
    result[item.gradeId].push(+item.value)
  })
  return result
}
export const mapPublisherIdWithGradeId = (units: UnitType[]) => {
  const result: { [gradeId: number]: number | undefined } = {}
  units.forEach((item) => {
    if (!(item.gradeId in result)) {
      result[item.gradeId] = item.publisherId
    }
  })
  return result
}

const convertDataMix = (data: LearningRangeDataType, isScopeGDLS = false) => {
  const result = Object.keys(data).map((subjectId) => {
    const isScopePage = 'startPage' in data[subjectId]?.[0]
    if (isScopePage) {
      return (data[subjectId] as PageType[]).map((item) => {
        return {
          subjectId: +subjectId,
          gradeId: +item.gradeId,
          startPage: +item.startPage,
          endPage: +item.endPage,
          ...(isScopeGDLS ? {} : { isItem: false, termId: 1 }),
          ...(item.publisherId ? { publisherId: +item.publisherId } : {}),
          ...(item.schoolId ? { schoolId: +item.schoolId } : {}),
          ...(item.assignGradeId ? { assignGradeId: +item.assignGradeId } : {}),
        }
      })
    }
    const unitsOfGradeIds = filterGradeId(data[subjectId] as UnitType[])
    const publisherIdsOfGradeIds = mapPublisherIdWithGradeId(data[subjectId] as UnitType[])
    return Object.keys(unitsOfGradeIds).map(
      (gradeId) =>
      ({
        subjectId: +subjectId,
        gradeId: +gradeId,
        units: unitsOfGradeIds[gradeId],
        ...(isScopeGDLS ? {} : { isItem: true, termId: 1 }),
        ...(publisherIdsOfGradeIds[gradeId] ? { publisherId: Number(publisherIdsOfGradeIds[gradeId]) } : {}),
        ...(data[subjectId][0].schoolId ? { schoolId: data[subjectId][0].schoolId } : {}),
        ...(data[subjectId][0].assignGradeId ? { assignGradeId: data[subjectId][0].assignGradeId } : {}),
      } as unknown as ScopeUnit)
    )
  })

  return _.flattenDeep(result as any)
}

const convertDataLearningRange = (dataLearningRange: LearningRangeType) => {
  switch (dataLearningRange.type) {
    case Scope.GDLS:
      return convertDataMix(dataLearningRange.data as LearningRangeDataType, true)
    case Scope.SCHOOL:
    case Scope.BOOKTEXT:
      return convertDataMix(dataLearningRange.data as LearningRangeDataType)
    case Scope.NON:
      return []
    default:
      return []
  }
}

const convertDifficultData = (difficultData?: DifficultSetting[]): DifficultUpdateData[] => {
  if (!difficultData) return []
  const DIFFICULT_TYPE_TEST = 'RK'
  const difficultDataTransformed: DifficultUpdateData[] = difficultData.reduce(
    (result: DifficultUpdateData[], difficultItem) => {
      const difficultTypeIndex = difficultItem.class_category_code === DIFFICULT_TYPE_TEST ? 0 : 1
      if (result[difficultTypeIndex]) {
        result[difficultTypeIndex].subject_levels.push({
          subject_id: difficultItem.subject_id,
          levels: difficultItem.levels,
        })
      } else {
        result[difficultTypeIndex] = {
          class_category_code: difficultItem.class_category_code,
          subject_levels: [{ subject_id: difficultItem.subject_id, levels: difficultItem.levels }],
        }
      }
      return result
    },
    []
  )
  return difficultDataTransformed
}

const convertDataLearningRangeResponse = (data: any[], scope: any) => {
  const result: { [subjectId: string]: UnitType[] } = data.reduce((preResult, currentItem) => {
    if (!(currentItem.subjectId in preResult)) {
      preResult[currentItem.subjectId] = []
    }
    const isScopePage = 'startPage' in currentItem
    if (isScopePage) {
      scope[currentItem.subjectId] = TypeScope.Page
      preResult[currentItem.subjectId].push({
        endPage: currentItem.endPage.toString(),
        startPage: currentItem.startPage.toString(),
        gradeId: currentItem.gradeId,
        units: currentItem.units.map((unit: any) => ({
          value: unit.id,
          text: unit.grade_name + ' ' + unit.s_unit_name,
          pageText: ` (${unit.name} : p${unit.start_page}-p${unit.end_page})`,
          gradeId: currentItem.gradeId,
        })),
        ...(currentItem.publisherId ? { publisherId: currentItem.publisherId } : {}),
        ...(currentItem.schoolId ? { schoolId: currentItem.schoolId } : {}),
        ...(currentItem.schoolId ? { assignGradeId: currentItem.assignGradeId || currentItem.gradeId } : {}),
      })
    } else {
      scope[currentItem.subjectId] = TypeScope.Unit
      currentItem.units.forEach((unit: any) => {
        preResult[currentItem.subjectId].push({
          value: unit.id.toString(),
          text: unit.grade_name + ' ' + unit.s_unit_name,
          pageText: ` (${unit.name} : p${unit.start_page}-p${unit.end_page})`,
          gradeId: currentItem.gradeId,
          ...(currentItem.publisherId ? { publisherId: currentItem.publisherId } : {}),
          ...(currentItem.schoolId ? { schoolId: currentItem.schoolId } : {}),
          ...(currentItem.schoolId ? { assignGradeId: currentItem.assignGradeId || currentItem.gradeId } : {}),
        } as UnitType)
      })
    }

    return preResult
  }, {})

  Object.keys(result).forEach((key) => {
    if (result[key]?.length == 0) {
      delete result[key]
    }
  })
  return result
}

export const processDataPreSubmit = (mode: ModeType, branchId?: number, academyId?: number) => {
  const formData: ModeTypeDTO = {}
  if (branchId) {
    formData.branch_id = branchId
  } else {
    formData.academy_id = academyId
    formData.owner_type = 'academy'
  }
  formData.s3_path_id = mode.s3_path_id
  formData.name = mode.name
  formData.description = mode.description
  formData.depth_of_trace = mode.depth_of_trace
  formData.learning_flows =
    mode.learning_flows?.map((item) => {
      return {
        sortNum: item.sortNum,
        classCategoryId: item.classCategoryId + '',
        isEnabled: item.isEnabled,
      } as FlowLearningDTO
    }) || []
  const condition = mode.learning_end_condition?.data?.map((item) => ({
    restrictionId: item.restrictionId,
    subjectId: item.subjectId,
    limitThreshold: item.limitThreshold,
  })) as ConditionDTO[]
  formData.learning_end_condition = { type: +(mode.learning_end_condition?.type || 0), data: condition }
  formData.is_random_question = mode.is_random_question
  formData.learning_range = {
    type: mode.learning_range.type,
    data: convertDataLearningRange(mode.learning_range),
    is_default: false,
  }
  if (formData.learning_range.data.length === 0 && formData.learning_range.type !== Scope.NON) {
    formData.learning_range.data = []
    formData.learning_range.is_default = true
  }
  if (mode.study_period?.start) {
    formData.study_period = {
      startAt: mode.study_period.start,
      endAt: mode.study_period.end,
    }
  }

  formData.difficulty_settings = convertDifficultData(mode.difficulty_settings)
  return formData
}

export const convertMode = (modeDTO: ModeTypeDTO): ModeType => {
  const mode: ModeType = {
    s3_path_id: modeDTO.s3_path_id || 1,
    code: modeDTO.code,
    id: modeDTO.id,
    name: modeDTO.name + '',
    description: modeDTO.description,
    depth_of_trace: modeDTO.depth_of_trace || 0,
    is_random_question: modeDTO.is_random_question || false,
    study_period: {
      start: null,
      end: null,
    },
    learning_range: { type: 0, data: [] },
    learning_end_condition: { type: 0, data: [] },
    learning_flows: [],
    is_mode_default: modeDTO.is_mode_default,
    is_ocr_mode: modeDTO.is_ocr_mode
  }
  if (modeDTO?.study_period?.startAt) {
    mode.study_period = {
      start: moment(modeDTO?.study_period?.startAt).format('YYYY-MM-DD'),
      end: moment(modeDTO?.study_period?.endAt).format('YYYY-MM-DD'),
    }
  }
  mode.learning_flows = modeDTO.learning_flows?.map((item) => ({ ...item } as ClassPolycies)) || []
  mode.learning_end_condition = {
    type: modeDTO.learning_end_condition?.type || 1,
    data: modeDTO.learning_end_condition?.data?.map((item) => ({ ...item } as ConditionExit)),
  }
  const scope = {}
  mode.learning_range = {
    type: modeDTO.learning_range?.type || 1,
    data: modeDTO.learning_range && convertDataLearningRangeResponse(modeDTO.learning_range.data as any[], scope),
    scope: scope,
  }
  mode.difficulty_settings = modeDTO.difficulty_settings as unknown as DifficultSetting[]
  return mode
}

export const checkDate = (start?: string | null, end?: string | null) => {
  if (!end || !start) {
    return '勉強時間をもう一度確認してください。'
  }
  const today = moment(new Date()).format('YYYY-MM-DD')
  const timeToday = new Date(today).getTime()
  const timeStart = new Date(start || today).getTime()
  const timeEnd = new Date(end || today).getTime()
  if (timeToday > timeStart) {
    return '過去の日付を開始日に選べません。'
  }
  if (timeStart > timeEnd) {
    return '開始日は終了日より前にする必要があります。'
  }
  if (timeStart === timeEnd) {
    return '開始日と終了日は同じにできません。'
  }
  return ''
}

export const validateMode = (mode: ModeType) => {
  if (!mode.s3_path_id) {
    return 'モードにアイコンを選択してください。'
  }
  if (!mode.name) {
    return 'モードに名前を入力してください。'
  }
  if (Object.keys(mode.learning_range.data).length === 0 && !mode.is_mode_default) {
    return ' 学習単元を設定してください'
  }
  return ''
}
