










import { Component, Prop, Mixins } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

export type ModalParams = {
  id?: number
  title?: string
  buttonName?: string
  value?: string
  maxLength?: number
  loading?: boolean
  onClick?: Function
}

@Component({
  components: {
    ModalBase,
    ButtonBase,
  },
})
export default class ModalTeacherInput extends Mixins(ModalBaseMethod) {
  @Prop()
  info!: ModalParams

  get localInfo(): ModalParams {
    return this.info
  }
  set localInfo(value) {
    this.$emit('update:info', value)
  }

  /**
   * ボタンの色（ボタン操作制御）
   */
  private get colortype(): string {
    const value = this.localInfo.value || ''
    const permitted = value.length > 0 && this.errors.length == 0 && !this.localInfo.loading

    return permitted ? 'blue' : 'pointer-events-none'
  }

  private get errors(): any {
    const messages = []
    const maxLength = this.localInfo.maxLength || 20
    const value = this.localInfo.value || ''
    if (value.length > maxLength) messages.push(`${maxLength}文字までです`)
    return messages
  }
}
