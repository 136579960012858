



























import { Component, Prop, Mixins, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import DrillWebApi from '@/mixins/drillsv3/WebApi'
import moment from 'moment'
import { TITLE_HOMEWORK } from '@/constants'

@Component({
  components: {
    ColoredBox,
    ButtonBase,
  },
})
export default class DrillInformation extends Mixins(DrillWebApi) {
  public classMode(): string {
    return Vue.prototype.$cookies.get('dataGdls').classMode
  }

  private subjectCode = Vue.prototype.$cookies.get('dataGdls').subjectCode

  @Prop()
  drillInformation!: any

  @Prop()
  classCategoryCode!: string

  get classObject(): { [key: string]: boolean } {
    const status = this.drillInformation?.status
    return {
      'box__frame-clear': status === 'clear',
      'box__frame-active': status !== 'clear',
    }
  }

  get title() {
    const date = this.drillInformation.createdAt?.split(' ')[0]
    const [year, month, day] = date.split('-')
    const dateFormat = year + '年' + month + '月' + day + '日'
    return dateFormat + '作成'
  }

  get buttonColorType(): string {
    const status = this.drillInformation?.status
    let color = 'white'
    switch (status) {
      case 'clear':
        color = 'gray'
        break
      case 'start':
        color = 'skyblue'
        break
      case 'homework':
        color = 'pink'
        break
      case 'reconfirm':
        color = 'lightblue'
        break
    }
    return color
  }

  get statusString(): string {
    const status = this.drillInformation?.status
    let str = ''
    switch (status) {
      case 'clear':
        str = 'クリア！'
        break
      case 'start':
        str = '始める'
        break
      case 'homework':
        str = '宿題を出そう'
        break
      case 'reconfirm':
        str = '結果確認'
        break
    }
    return str
  }

  get isNoMistake(): boolean {
    return this.drillInformation?.status === 'noMistake'
  }

  public async startDrill(classCategoryCode: string): Promise<any> {
    Vue.prototype.$loading.start()
    Vue.prototype.$logger.info('-- DrillInformation startDrill')
    Vue.prototype.$cookies.set(TITLE_HOMEWORK, this.title)
    const status = this.drillInformation?.status

    switch (status) {
      case 'clear':
        // 何もしない
        break
      case 'start':
      case 'reconfirm': {
        // ドリル（確認テストまたは宿題）を開始する。「始める」と「間違い直し」共通
        const startDrill = await this.startDrillApi(
          'AI',
          this.drillInformation.classModeCode,
          classCategoryCode,
          {
            curriculumSUnitId: this.drillInformation.curriculumSUnitId,
          },
          this.drillInformation.homework_setting_item_id
        )

        if (status === 'start') {
          // ドリル（確認テスト）開始APIの結果を受けて問題ページへ遷移する
          // 途中で抜けていた場合はそのpageへ遷移
          this.$router.push({
            name: this.drillInformation.classModeCode === 'ocr_llm' ? 'drillV3Math' : 'drillV3',
            query: {
              resultDrillId: String(startDrill.resultDrillId),
              page: String(startDrill.currentPage),
            },
          })
        } else {
          // ドリル（確認テスト）開始APIの結果を受けて結果ページ遷移する
          // 一度解いている場合に遷移
          this.$router.push({
            name: 'drillResultV3',
            query: {
              resultDrillId: String(startDrill.resultDrillId),
            },
          })
        }
        break
      }
      case 'homework':
        // 宿題のページに遷移
        window.location.href = '/student/v3/confirmation/SY'
        // TODO 宿題を出そうで宿題に遷移したときに同じボタンのリンクが更新されないため保留
        // this.$router.push('/student/confirmation/SY')
        break
    }
    Vue.prototype.$loading.complete()
  }
}
