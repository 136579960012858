





import ScoringDrillMath from '@/components/organisms/v3/drill/ScoringDrillMath.vue'
import DrillMethod from '@/mixins/drillsv3/DrillMethod'
import DrillTransition from '@/mixins/drillsv3/DrillTransition'
import { ResponseProblem, ResponseResultProblem } from '@/mixins/drillsv3/WebApi'
import { PAGE_TYPE } from '@/store/modules/DrillsV3'
import { Component, Mixins, Vue } from 'vue-property-decorator'

@Component({
  components: {
    ScoringDrillMath
  },
})
export default class DrillScoring extends Mixins(DrillTransition, DrillMethod) {
  private resultProblem: ResponseResultProblem | undefined
  private problems: ResponseProblem[] = []
  private get page(): number {
    return Number(this.$route.query.page)
  }

  private get isReport() {
    return this.$route.path.includes('report')
  }

  private get reportRole() {
    return this.$route.params.role;
  }

  protected async mounted() {
    Vue.prototype.$loading.start()
    Vue.prototype.$logger.info('-- DrillScoring V3 mounted')
    // ページ未指定の場合は問題画面へ戻す
    if (!this.page) {
      Vue.prototype.$loading.complete()
      this.$router.push({
        path: this.urlDrill,
      })
      return
    }
    // 読み込み完了フラグを未完了に変更
    this.$store.commit('drillsV3/setProcessed', false)

    // ページタイプ設定
    this.$store.commit('drillsV3/setPageType', PAGE_TYPE.SCORING_UNIT_MATH)

    const questionCode = this.$route.query?.questionCode as string
    // 正誤入力情報を取得
    const resultProblem = await this.getResultProblemMath(this.resultDrillId, this.page, questionCode?.length ? { question_code: questionCode } : undefined)

    if (!resultProblem) {
      if (this.isReport) {
        this.resultProblem = {
          currentPage: this.page,
          curriculumSUnitId: 0,
          problems: [],
        } as any
        const sProblemIds = (JSON.parse((this.$route.query.sProblemIds || '[]') as string) as number[] || [])

        const problems = await this.getProblems([sProblemIds], 'v3', questionCode?.length ? { question_code: questionCode, result_drill_id: this.resultDrillId } : undefined)
        this.problems = problems;
        await this.$store.dispatch('drillsV3/setProblems', problems)
        // 読み込み完了フラグを設定
        this.$store.commit('drillsV3/setProcessed', true)
        return
      }
      return
    };
    this.resultProblem = resultProblem

    this.$store.commit('drills/setEndCondition', resultProblem.endConditions)
    this.$store.commit('drillsV3/setCurrentPage', Number(resultProblem.pageNum))
    if (resultProblem.endConditions.left == 0 && !this.isReport) {
      await this.completeDrillApi(this.resultDrillId)
      Vue.prototype.$loading.complete()
      this.$router.push({
        path: this.urlResultAll,
      })
      return
    }

    // 授業モードと授業種別を保持
    this.$store.commit('drillsV3/setClassModeCode', resultProblem.classModeCode)
    this.$store.commit('drillsV3/setClassCategoryCode', resultProblem.classCategoryCode)
    this.$store.commit('drillsV3/setClassModeId', resultProblem.classModeId)
    this.$store.commit('drillsV3/setClassCategoryId', resultProblem.classCategoryId)

    // 該当ページが採点済みの場合は問題画面へ(問題ごとの結果画面がないため)
    //    問題ごとの結果画面が存在するモードが追加された場合、DrillTransitionに処理を追加し、ClassModeなどを元に適切な遷移先を選ぶようにする
    if (resultProblem.isCorrect && !this.isReport) {
      this.$router.push({
        path: this.urlDrill,
      })
      return
    }
    await this.$store.dispatch('drillsV3/setProblemCorrects', [resultProblem.problems])

    // 表示用の情報を取得し、ストアに保持
    const sProblemIds = resultProblem.problems.map((problem) => {
      return problem.sProblemId
    })
    const problems = await this.getProblems([sProblemIds], 'v3', questionCode?.length ? { question_code: questionCode, result_drill_id: this.resultDrillId } : undefined)
    this.problems = problems;
    await this.$store.dispatch('drillsV3/setProblems', problems)
    // 読み込み完了フラグを設定
    this.$store.commit('drillsV3/setProcessed', true)

    Vue.prototype.$loading.complete()
  }

  private get getResultDrillId() {
    return this.$route.query.resultDrillId
  }
}
