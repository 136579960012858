























import { Component, Vue, Ref, Mixins } from 'vue-property-decorator'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import TableBase from '@/components/atoms/TableBase1110.vue'
import ModalAdminCustomerService from '@/components/organisms/ModalAdminCustomerService.vue'
import ModalAdminGdlsCode from '@/components/organisms/ModalAdminGdlsCode.vue'
import NursingBranch from '@/mixins/v3/NursingBranch'

@Component({
  components: {
    InputWithLabel,
    ButtonBase,
    TableBase,
    ModalAdminCustomerService,
    ModalAdminGdlsCode,
  },
})
export default class Branch extends Mixins(NursingBranch) {
  private academyCode = ''

  private academyName = ''

  private branchCode = ''

  private branchName = ''

  private currentPage = 1

  private count = 0

  private tableBasePerPage = 30

  private tableBaseItemsCustomerList: object[] = []

  private tableBaseFieldsCustomerHeader: object[] = [
    { key: 'academyCode', label: '塾コード', thStyle: 'width: 200px' },
    { key: 'academyName', label: '塾名', thStyle: 'width: 250px' },
    { key: 'branchCode', label: '教室コード', thStyle: 'width: 200px' },
    { key: 'branchName', label: '教室名', thStyle: 'width: 250px' },
    { key: 'gdlsCode', label: 'GDLSコード' },
    { key: 'login', label: 'ログイン' },
    { key: 'service', label: '利用サービス' },
  ]

  private tableBaseButtons: string[] = ['gdlsCode', 'login', 'service']

  @Ref() modalAdminCustomerService!: ModalAdminCustomerService
  @Ref() modalAdminGdlsCode!: ModalAdminGdlsCode

  private showService(branch: { academyId: number; branchId: number }): void {
    this.modalAdminCustomerService.showWithLoadService(branch.academyId, branch.branchId)
  }

  private showGdlsCode(id: number): void {
    this.modalAdminGdlsCode.loadManager(id)
  }

  /**
   * 教室一覧を取得する
   */
  private async loadBranches() {
    const params = [`limit=${this.tableBasePerPage}`, `offset=${this.tableBasePerPage * (this.currentPage - 1)}`]
    if (this.academyCode) params.push(`academyCode=${this.academyCode}`)
    if (this.academyName) params.push(`academyName=${this.academyName}`)
    if (this.branchCode) params.push(`branchCode=${this.branchCode}`)
    if (this.branchName) params.push(`branchName=${this.branchName}`)

    await Vue.prototype.$http.httpWithToken.get(`/branches?${params.join('&')}`).then((res: any) => {
      this.tableBaseItemsCustomerList = res.data.branches.map(
        (branch: {
          academyId: number
          academyName: string
          academyCode: string
          branchId: number
          branchName: string
          branchCode: string
        }) => {
          return {
            academyCode: branch.academyCode,
            academyName: branch.academyName,
            branchCode: branch.branchCode,
            branchName: branch.branchName,
            gdlsCode: [{ onclick: this.showGdlsCode, variable: branch.branchId, name: '確認' }],
            login: [
              {
                onclick: this.proxyLogin,
                variable: { branchId: branch.branchId, academyCode: branch.academyCode },
                name: 'ログイン',
              },
            ],
            service: [
              {
                onclick: this.showService,
                variable: { academyId: branch.academyId, branchId: branch.branchId },
                name: '確認',
              },
            ],
          }
        }
      )
      this.count = res.data.count
    })
  }

  /**
   * 教室管理者代理ログイン
   */
  private async proxyLogin({ branchId, academyCode }: { branchId: string; academyCode: string }) {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie['branchId'] = branchId
    if (academyCode?.toLowerCase() === 'kango') {
      cookie['academyCode'] = ''
    } else {
      cookie['academyCode'] = academyCode
    }
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())

    this.$router.push('/teacher/history')
  }

  /**
   * ページネーション
   */
  private paginate(page: number): void {
    this.currentPage = page
    this.loadBranches()
  }

  private async mounted() {
    this.loadBranches()
  }
}
