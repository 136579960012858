import { LINE_WIDTH } from '@/constants'
import { StrokeType, DataCanvasDrillMethod } from '@/types/canvas'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class CanvasMethod extends Vue {
  protected canvasName = ''

  public getImageFileCanvas(imageName: string, params?: {
    canvasName: string
  }) {
    return Vue.prototype.$penCanvases[params?.canvasName || this.canvasName]?.exportCanvasToFileImage(imageName)
  }

  public getCanvasImageData(canvasName?: string) {
    return Vue.prototype.$penCanvases[canvasName || this.canvasName]?.getCanvasAsImageDataType()
  }

  public drawCanvasFromImageData(imageData: string, params?: {
    canvasName: string
  }) {
    Vue.prototype.$penCanvases[this.canvasName]?.drawCanvasFromImageData(imageData)
  }

  public clearCanvas(canvasName?: string) {
    Vue.prototype.$penCanvases[canvasName || this.canvasName]?.clearCanvas()
  }

  public checkCanvasIsEmpty(canvasName?: string) {
    return Vue.prototype.$penCanvases[canvasName || this.canvasName]?.checkCanvasIsEmpty()
  }

  public canvasToBase64(canvasName?: string) {
    return Vue.prototype.$penCanvases[canvasName || this.canvasName]?.canvasToBase64()
  }

  public drawImageFromBase64(
    base64String: string,
    options: {
      width: number
      height: number
      canvasName?: string
    }
  ) {
    return Vue.prototype.$penCanvases[options?.canvasName || this.canvasName]?.drawImageFromBase64(base64String, options)
  }

  public getCurrentCanvas(canvasName?: string) {
    return Vue.prototype.$penCanvases[canvasName || this.canvasName]
  }

  public getStroke(canvasName?: string) {
    return Vue.prototype.$penCanvases[canvasName || this.canvasName].getStroke()
  }
  public redraw(strokes?: StrokeType[][], params?: {
    canvasName: string
  }) {
    return Vue.prototype.$penCanvases[params?.canvasName || this.canvasName].redraw(strokes)
  }

  public clearStrokes(canvasName?: string) {
    Vue.prototype.$penCanvases[canvasName || this.canvasName].clearStrokes()
  }

  public undo(canvasName?: string) {
    Vue.prototype.$penCanvases[canvasName || this.canvasName].undo()
  }

  public redo(canvasName?: string) {
    Vue.prototype.$penCanvases[canvasName || this.canvasName].redo()
  }

  public activateCanvas(canvasName?: string) {
    Vue.prototype.$penCanvases[canvasName || this.canvasName].activateCanvas()
  }

  public changePenType(params: { colorCode: string; alpha?: number, canvasName?: string }) {
    Vue.prototype.$penCanvases[params?.canvasName || this.canvasName].changePenType(params)
  }

  public setCanvasSize(canvas: any, size: { width: number; height: number }): void {
    if (!canvas) return

    canvas.changeSize({
      width: size.width,
      height: size.height,
    })
    canvas.setCanvasContext()
  }

  public changePenColor(color: string, params?: {
    canvasName: string
  }) {
    Vue.prototype.$penCanvases[params?.canvasName || this.canvasName].changePenColor(color)
  }

  public canvasToFile(nameImage?: string, params?: {
    canvasName: string
  }) {
    return Vue.prototype.$penCanvases[params?.canvasName || this.canvasName].canvasToFile(nameImage)
  }

  public getDataStroke(canvasName?: string) {
    return Vue.prototype.$penCanvases[canvasName || this.canvasName].getDataStroke()
  }

  public setDataStroke(params: DataCanvasDrillMethod, other?: {
    canvasName: string
  }) {
    Vue.prototype.$penCanvases[other?.canvasName || this.canvasName].setDataStroke(params)
  }

  public deleteCanvas(canvasName?: string) {
    Vue.prototype.$penCanvases[canvasName || this.canvasName].deleteCanvas()
  }

  public getWidthHeight(canvasName?: string) {
    return Vue.prototype.$penCanvases[canvasName || this.canvasName].getWidthHeight()
  }

  public changeSize(params: { width: number; height: number }) {
    Vue.prototype.$penCanvases[this.canvasName].changeSize(params)
  }

  public deactivateCanvas(canvasName?: string) {
    Vue.prototype.$penCanvases[canvasName || this.canvasName].deactivateCanvas()
  }

  protected async drawImageAndStrokes(
    imageSrc: string,
    strokes: StrokeType[][],
    params: {
      widthCanvas: number
      nameFile: string
    }
  ) {
    const canvas = document.createElement('canvas')
    canvas.width = params.widthCanvas // Adjust the width as needed
    canvas.height = 1000 // Adjust the height as needed

    const ctx = canvas.getContext('2d')

    if (!ctx) return
    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    if (imageSrc) {
      // Create an Image object and load the image
      const image = new Image()

      image.src = imageSrc

      // Wait for the image to load before drawing
      return new Promise((resolve) => {
        image.onload = () => {
          const ratio = image.width / image.height
          ctx.globalCompositeOperation = 'source-over'
          ctx.drawImage(image, 0, 0, params.widthCanvas, params.widthCanvas / ratio)

          // Draw the strokes (as before)

          strokes.forEach(function (stroke: StrokeType[]) {
            ctx.beginPath()
            ctx.moveTo(stroke[0].x, stroke[0].y)
            stroke.forEach(function (point: StrokeType) {
              if (point.lineWidth === LINE_WIDTH.ERASE) {
                ctx.globalCompositeOperation = 'destination-out'
              }

              ctx.lineWidth = point?.lineWidth || LINE_WIDTH.MEDIUM
              ctx.strokeStyle = point?.color || 'black'
              ctx.lineTo(point.x, point.y)

              if (point.lineWidth === LINE_WIDTH.ERASE) {
                ctx.globalCompositeOperation = 'source-over'
              }
            })
            ctx.stroke()
          })

          // Convert the canvas to a Blob and create a File object
          canvas.toBlob((blob) => {
            // const url = URL.createObjectURL(blob as any)


            const file = new File([blob as any], `${params.nameFile}.png`, { type: 'image/png' })
            canvas.remove()
            resolve(file)
          })
        }
      })
    }

    return new Promise((resolve) => {
      strokes.forEach(function (stroke: StrokeType[]) {
        ctx.beginPath()
        ctx.moveTo(stroke[0].x, stroke[0].y)
        stroke.forEach(function (point: StrokeType) {
          if (point.lineWidth === LINE_WIDTH.ERASE) {
            console.log('123123');
            ctx.globalCompositeOperation = 'destination-out'
          }
          ctx.lineWidth = point?.lineWidth || LINE_WIDTH.MEDIUM
          ctx.strokeStyle = point?.color || 'black'
          ctx.lineTo(point.x, point.y)

          if (point.lineWidth === LINE_WIDTH.ERASE) {
            ctx.globalCompositeOperation = 'source-over'
          }
        })
        ctx.stroke()
      })

      // Convert the canvas to a Blob and create a File object
      canvas.toBlob((blob) => {
        // const url = URL.createObjectURL(blob as any)


        const file = new File([blob as any], `${params.nameFile}.png`, { type: 'image/png' })
        canvas.remove()
        resolve(file)
      })
    })


  }

  public changeLineWidth(lineWidth: number, params?: {
    canvasName: string
  }) {
    Vue.prototype.$penCanvases[params?.canvasName || this.canvasName].changeLineWidth(lineWidth)
  }

  public setEraser(canvasName?: string) {
    Vue.prototype.$penCanvases[canvasName || this.canvasName].setEraser()
  }

  public getCompositeOperation(canvasName?: string) {
    return Vue.prototype.$penCanvases[canvasName || this.canvasName].getCompositeOperation()
  }

  public drawFromStroke(canvasName?: string) {
    Vue.prototype.$penCanvases[canvasName || this.canvasName].drawFromStroke()
  }

  public getRealStrokes(canvasName?: string) {
    return Vue.prototype.$penCanvases[canvasName || this.canvasName].getRealStrokes()
  }
}
