












































import { Component, Vue, Ref, Mixins } from 'vue-property-decorator'
import NotificationList from '@/components/organisms/NotificationList.vue'
import TableBase from '@/components/atoms/TableBase1110.vue'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ModalAdminCustomerService from '@/components/organisms/ModalAdminCustomerService.vue'
import ModalAdminCustomerClassroomAdd from '@/components/organisms/ModalAdminCustomerClassroomAdd.vue'
import ModalAdminCustomerClassroomEdit from '@/components/organisms/ModalAdminCustomerClassroomEdit.vue'
import LocalMoment from '@/components/atoms/LocalMoment.vue'
import ModalNotification from '@/components/organisms/ModalNotification.vue'
import moment from 'moment'
import FileDropArea from '@/components/atoms/FileDropArea.vue'
import ModalCsvLoadErrors, { CsvLoadError } from '@/components/organisms/ModalCsvLoadErrors.vue'
import NursingBranch from '@/mixins/v3/NursingBranch'

export type BranchType = {
  branchId: number
  branchName: string
  branchCode: string
  isRenameRequesting: boolean
  isDeleteRequesting: boolean
}

export type NotificationType = {
  id: number
  roles: string[]
  title: string
  message: string
  isDisplaying: boolean
  startedAt: string
  endedAt?: string
  createdAt: string
  updatedAt: string
}

export type TableItemType = {
  branchId: number
  branchCode: string
  branchName: any
  classroomManager: any[]
  download: any[]
  login: any[]
  service: any[]
  delete: any[]
}

@Component({
  components: {
    NotificationList,
    TableBase,
    InputWithLabel,
    ButtonBase,
    ModalAdminCustomerService,
    ModalAdminCustomerClassroomAdd,
    ModalAdminCustomerClassroomEdit,
    ModalNotification,
    FileDropArea,
    ModalCsvLoadErrors,
  },
})
export default class Branch extends Mixins(LocalMoment, NursingBranch) {
  @Ref() modalAdminCustomerService!: ModalAdminCustomerService
  @Ref() modalAdminCustomerClassroomAdd!: ModalAdminCustomerClassroomAdd
  @Ref() modalAdminCustomerClassroomEdit!: ModalAdminCustomerClassroomEdit
  @Ref() modalNotification!: ModalNotification
  @Ref() modalCsvLoadErrors!: ModalCsvLoadErrors

  private academyId = Vue.prototype.$cookies.get('dataGdls').academyId

  private academy: { id: number; name: string; code: string } = { id: 0, code: '', name: '' }

  private currentPage = 1

  private branchesCount = 0

  private branchesTablePerPage = 30

  private notificationDatas: object[] = []

  private branchesTableItems: TableItemType[] = []

  private branchCode = ''

  private branchName = ''

  private branchesTableFields = [
    { key: 'branchCode', label: '教室コード' },
    { key: 'branchName', label: '教室名' },
    { key: 'classroomManager', label: '教室長一覧' },
    { key: 'download', label: 'ダウンロード' },
    { key: 'login', label: 'ログイン' },
    { key: 'service', label: '利用サービス' },
    { key: 'delete', label: '' },
  ]

  private branchesTableButtons = ['classroomManager', 'download', 'login', 'service', 'delete']

  private branchesTableLinks = ['branchName']

  private readonly deleteRequestType = 5
  private readonly addRequestType = 4

  // CSVアップロードエリア表示ステータス
  private isShowCsvDropArea = false

  private showService(branchId: number): void {
    this.modalAdminCustomerService.showWithLoadService(this.academyId, branchId)
  }

  private showClassroomAdd(): void {
    this.modalAdminCustomerClassroomAdd.show()
  }

  private showClassroomEdit(branch: BranchType): void {
    const param = { id: branch.branchId, code: branch.branchCode, name: branch.branchName }
    this.modalAdminCustomerClassroomEdit.showWithBranchData(param)
  }

  private showNotification(id: number): void {
    this.modalNotification.loadNotification(id)
  }

  private showCsvLoadErrors(errors: CsvLoadError[]): void {
    this.modalCsvLoadErrors.showErrors(errors)
  }

  private async mounted() {
    this.setAcademy()
    this.loadBranches()
    this.loadNotifications()
  }

  /**
   *  塾情報を取得する
   */
  private async setAcademy() {
    await Vue.prototype.$http.httpWithToken.get(`/academies/${this.academyId}`).then((res: any) => {
      this.academy = res.data
    })
  }

  /**
   *  お知らせ一覧を取得する
   */
  private async loadNotifications() {
    await Vue.prototype.$http.httpWithToken.get(`/notifications?role=academy`).then((res: any) => {
      const notifications: NotificationType[] = res.data.notifications
      this.notificationDatas = notifications.map((notification) => {
        return this.notificationParams(notification)
      })
    })
  }

  private notificationParams(notification: NotificationType) {
    return {
      date: moment(notification.startedAt).format('YYYY/MM/DD'),
      title: notification.title,
      click: this.showNotification,
      content: notification.message,
      variable: notification.id,
    }
  }

  /**
   * 教室一覧を取得する
   */
  private async loadBranches() {
    const params = [
      `limit=${this.branchesTablePerPage}`,
      `offset=${this.branchesTablePerPage * (this.currentPage - 1)}`,
    ]
    params.push(`academyId=${this.academyId}`)
    if (this.branchCode) params.push(`branchCode=${this.branchCode}`)
    if (this.branchName) params.push(`branchName=${this.branchName}`)

    await Vue.prototype.$http.httpWithToken.get(`/branches/requests?${params.join('&')}`).then((res: any) => {
      const branches: BranchType[] = res.data.branches
      this.branchesTableItems = branches.map((branch) => {
        return this.tableParams(branch)
      })
      this.branchesCount = res.data.count
    })
  }

  /**
   * 教室毎のテーブルパラメータを返却する
   */
  private tableParams(branch: BranchType): TableItemType {
    return {
      branchId: branch.branchId,
      branchCode: branch.branchCode,
      branchName: this.branchNameColumnParams(branch),
      classroomManager: [
        {
          url: `/academy/${Vue.prototype.$gdlsCookiesV3.isV3() ? 'v3/' : ''}${branch.branchId}/branch-user`,
          name: '一覧',
        },
      ],
      download: [{ onclick: this.downloadPdf, variable: branch, name: 'ダウンロード' }],
      login: [{ onclick: this.proxyLogin, variable: branch.branchId, name: 'ログイン' }],
      service: [{ onclick: this.showService, variable: branch.branchId, name: '確認' }],
      delete: [this.deleteColumnParams(branch)],
    }
  }

  /**
   * 教室名列のパラメータ
   * 教室名変更申請中の場合表示内容を変更する
   */
  private branchNameColumnParams(branch: BranchType) {
    if (branch.isRenameRequesting) {
      return { name: `${branch.branchName}(申請中)`, disabled: true }
    }

    return {
      name: branch.branchName,
      onclick: this.showClassroomEdit,
      variable: branch,
    }
  }

  /**
   * 教室削除列のパラメータ
   * 教室削除申請中の場合表示内容を変更する
   */
  private deleteColumnParams(branch: BranchType) {
    if (branch.isDeleteRequesting) {
      return { name: '削除申請中', requesting: true }
    }

    return {
      name: '削除',
      onclick: () => {
        if (confirm(`${branch.branchName}を削除申請します。よろしいですか？`)) {
          this.deleteBranchRequest(branch.branchId)
        }
      },
    }
  }

  /**
   * 教室削除申請
   */
  private async deleteBranchRequest(branchId: number) {
    const body = this.parseDeleteRequestBody(branchId)
    await Vue.prototype.$http.httpWithToken
      .post('/requests', body)
      .then(() => {
        alert('教室削除申請が完了しました。')
        this.updateTableItem(branchId, 'delete')
      })
      .catch((error: any) => {
        if (error.response.data.status === 409) {
          alert('教室の削除に失敗しました')
        }
      })
  }

  private parseDeleteRequestBody(branchId: number) {
    return {
      requestType: this.deleteRequestType,
      academyId: this.academy.id,
      requests: [{ branchId: branchId }],
    }
  }

  /**
   * 教室名変更/削除申請リクエスト時、テーブルパラメータを変更する
   */
  private updateTableItem(branchId: number, requestType: string) {
    const tableItem = this.branchesTableItems.filter((item) => item.branchId == branchId)[0]

    if (requestType == 'rename') {
      tableItem.branchName = {
        name: `${tableItem.branchName.name}(申請中)`,
        disabled: true,
      }
    } else if (requestType == 'delete') {
      tableItem.delete = [{ name: '削除申請中', requesting: true }]
    }
  }

  /**
   * PDFをダウンロードしてGDLSコードを表示する
   */
  private async downloadPdf(branch: BranchType) {
    await Vue.prototype.$http.httpWithToken
      .get(`/branches/${branch.branchId}/users`, {
        responseType: 'blob',
        dataType: 'binary',
        headers: {
          Accept: 'application/pdf',
        },
      })
      .then((res: any) => {
        const blob = new Blob([res.data], { type: 'application/pdf' })
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(blob)
        a.download = `${branch.branchCode}_${this.today().format('YYYYMMDDHHmmss')}.pdf`
        a.click()
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        } else if (error.response.data.status === 422) {
          alert('教室長が見つかりませんでした。')
        }
      })
  }

  /**
   * 教室管理者代理ログイン
   */
  private async proxyLogin(id: number) {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    Vue.prototype.$gdlsCookiesV3.setToolPage(false)
    cookie['branchId'] = id
    if (this.isNursingBranch) {
      cookie['academyCode'] = ''
    }
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())

    this.$router.push('/teacher/history')
  }

  /**
   * ページネーション
   */
  private paginate(page: number): void {
    this.currentPage = page
    this.loadBranches()
  }

  /**
   * ファイルドロップイベント
   */
  private async onDropFile(file: File) {
    Vue.prototype.$loading.start()
    await this.uploadRequestsCsv(file)
    Vue.prototype.$loading.complete()
  }

  /**
   * 教室追加申請CSVを元に
   */
  private async uploadRequestsCsv(file: File) {
    const headers = { 'Content-Type': 'multipart/form-data' }
    const params = new FormData()
    params.append('csvFile', file)
    params.append('academyId', this.academyId)
    params.append('requestType', this.addRequestType.toString())
    params.append('isImport', 'true')

    await Vue.prototype.$http.httpWithToken
      .post('/requests', params, { headers: headers })
      .then(() => {
        alert('教室追加申請が完了しました。')
      })
      .catch((error: any) => {
        if (error?.response?.data?.status === 400) {
          const errors: CsvLoadError[] = error?.response?.data?.errors || []
          this.showCsvLoadErrors(errors)
        }
      })
      .finally(() => {
        this.isShowCsvDropArea = false
      })
  }
}
