















































import { Component, Vue } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import RadioGroup from '@/components/molecules/RadioGroup.vue'
import CheckboxSquare from '@/components/atoms/CheckboxSquare.vue'
import { TEACHER_SETTING_TEXT } from '@/constants'

export type Environment = 'method' | 'staging' | 'production'
export type Mode = 'TJ'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    ColoredBox,
    ButtonBase,
    RadioGroup,
    CheckboxSquare,
  },
})
export default class DownloadContent extends Vue {
  private breadcrumbs = [
    { text: TEACHER_SETTING_TEXT, href: '/teacher/setting/top' },
    { text: 'コンテンツダウンロード', active: true },
  ]

  private environmentType: Environment = 'method'
  private modeType: Mode = 'TJ'
  private subjectType = 0

  private async downloadExcel() {
    const gradeCheckedValues = this.gradeCheckOptions.filter((grade) => grade.checked).map((grade) => grade.value)
    if (!gradeCheckedValues.length) {
      alert('学年を選択してください')
    }
    const params = {
      environment: this.environmentType,
      subject_id: this.subjectType,
      curriculum_mode_code: this.modeType,
      grade_ids: gradeCheckedValues,
    }
    Vue.prototype.$loading.start()
    try {
      const res = await Vue.prototype.$http.httpWithToken.get('/v3/question_data/export', {
        responseType: 'blob',
        params,
      })
      const blob = new Blob([res.data], { type: res.data.type })
      const a = document.createElement('a')
      a.href = window.URL.createObjectURL(blob)
      a.download = this.getFilename(res.headers['content-disposition'])
      a.click()
    } catch (error) {
      alert((error as Error).message)
    }

    Vue.prototype.$loading.complete()
  }

  private getFilename(contentDisposition: string): string {
    const filenameRegex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/
    const matches = filenameRegex.exec(contentDisposition)
    if (matches != null && matches[1]) {
      return decodeURI(matches[1].replace(/['"]/g, '').replace('utf-8', ''))
    } else {
      return 'question_data.xlsx'
    }
  }

  private get gradeCheckedNumber() {
    return this.gradeCheckOptions.filter((grade) => grade?.checked).length
  }

  private gradeCheckOptions: { label: string; value: number; checked: boolean }[] = []
  private environmentOptions: { label: string; value: Environment }[] = [
    {
      label: 'メソッド環境',
      value: 'method',
    },
    {
      label: 'ステージング環境',
      value: 'staging',
    },
    {
      label: '本番環境',
      value: 'production',
    },
  ]
  private modeOptions: { label: string; value: Mode }[] = [
    {
      label: 'GDLS通常学習',
      value: 'TJ',
    },
  ]
  private subjectOptions = []

  private async mounted() {
    Vue.prototype.$loading.start()
    const grades = await Vue.prototype.$http.httpWithToken.get('/grades')
    this.gradeCheckOptions = grades.data.map(({ name, id }: { name: string; id: number }, index: number) => ({
      label: name,
      value: id,
      checked: index === 0,
    }))
    const subjects = await Vue.prototype.$http.httpWithToken.get('/subjects')
    this.subjectType = subjects.data?.[0].id
    this.subjectOptions = subjects.data.map(({ name, code, id }: { name: string; code: string; id: number }) => ({
      label: `${name}（${code}）`,
      value: id,
    }))
    Vue.prototype.$loading.complete()
  }
}
