import Vue from 'vue'
import VueRouter, { RouteConfig, Route } from 'vue-router'
import { RouterHelper } from '@/router/routerHelper'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/student/g-method/login',
    name: 'StudentsV3Login',
    component: () => import('../views/student/v3/Login.vue'),
  },
  {
    path: '/student/sozo/login',
    name: 'StudentSozoLogin',
    component: () => import('../views/student/v3/Login.vue'),
  },
  {
    path: '/student/sozo/select-subject',
    name: 'StudentSozoSelectSubject',
    component: () => import('../views/student/sozo/StudentSozoSubject.vue'),
  },
  {
    path: '/student/sozo/references/:subjectCode',
    name: 'StudentSozoSelectSubject',
    component: () => import('../views/student/sozo/StudentSozoReference.vue'),
  },
  {
    path: '/student/sozo/watch-reference/:type/:subjectCode/:grade/:kind',
    name: 'StudentSozoViewPdf',
    meta: { layout: 'none' },
    component: () => import('../views/student/sozo/StudentOpenPdf.vue'),
  },
  {
    path: '/student/sozo/hidden-link',
    name: 'StudentSozoViewPdf',
    meta: { layout: 'none' },
    component: () => import('../views/student/sozo/RedirectLink.vue'),
  },
  {
    path: '/student/setting',
    name: 'StudentSetting',
    component: () => import('@/views/student/v3/StudentSetting.vue'),
  },
  {
    path: '/student/v3/homework/:classCategoryCode',
    name: 'SelectSubjectHomeworkV3',
    component: () => import('../views/student/v3/Homework.vue'),
  },
  {
    path: '/teacher/setting/mode-default',
    name: 'ModeDefaultSetting',
    component: () => import('../views/teacher/v3/setting/ModeDefault.vue'),
  },
  {
    path: '/teacher/setting/create-mode',
    name: 'CreateSetting',
    component: () => import('../views/teacher/v3/setting/CreateMode.vue'),
  },
  {
    path: '/teacher/setting/homework',
    name: 'HomeworkSetting',
    component: () => import('../views/teacher/v3/setting/Homework.vue'),
  },
  {
    path: '/teacher/setting/mode/:modeId',
    name: 'CreateSetting',
    component: () => import('../views/teacher/v3/setting/CreateMode.vue'),
  },
  {
    path: '/student/v3/history/subject-situation',
    name: 'SubjectSituationV3',
    component: () => import('../views/student/v3/history/normal/SubjectSituation.vue'),
  },
  {
    path: '/student/v3/history/learning-situation',
    name: 'LearningSituationV3',
    component: () => import('../views/student/v3/history/normal/LearningSituation.vue'),
  },
  {
    path: '/student/v3/history/target-situation',
    name: 'TargetSituationV3',
    component: () => import('../views/student/v3/history/normal/TargetSituation.vue'),
  },
  {
    path: '/student/v3/history/lesson-situation',
    name: 'LessonSituationV3',
    component: () => import('../views/student/v3/history/normal/LessonSituation.vue'),
  },
  {
    path: '/student/v3/history/pretest-situation',
    name: 'PretestSituationV3',
    component: () => import('../views/student/v3/history/normal/PretestSituation.vue'),
  },
  {
    path: '/student/v3/history/viewer-situation',
    name: 'StudentTestViewerV3',
    component: () => import('../views/student/v3/history/normal/StudentTestViewer.vue'),
  },
  {
    path: '/student/v3/goal-list',
    name: 'StudentGoalListV3',
    component: () => import('../views/student/v3/GoalList.vue'),
  },
  {
    path: '/student/v3/goal-setting',
    name: 'StudentGoalSetting',
    component: () => import('../views/student/v3/GoalSetting.vue'),
  },
  {
    path: '/student/v3/goal-setting/:goalId',
    name: 'StudentGoalSetting',
    component: () => import('../views/student/v3/GoalSetting.vue'),
    props: true,
  },
  {
    path: '/student/v3/schedule',
    name: 'StudentSchedule',
    component: () => import('../views/student/v3/StudentSchedule.vue'),
    props: true,
  },
  {
    path: '/student/v3/report',
    name: 'StudentReportV3',
    component: () => import('../views/student/v3/Report.vue'),
  },
  {
    path: '/student/v3/history-chat',
    name: 'StudentHistoryChatV3',
    component: () => import('../views/student/v3/HistoryChat.vue'),
  },

  {
    path: '/student/v3/confirmation/:classCategoryCode',
    name: 'ConfirmationTestV3',
    component: () => import('../views/student/v3/DrillConfirmation.vue'),
  },

  {
    path: '/student/v3/image-report/:id',
    name: 'imageReport',
    component: () => import('../views/student/v3/ImageReport.vue'),
  },

  {
    path: '/teacher/v3/image-report/:id',
    name: 'imageReport',
    component: () => import('../views/teacher/v3/ImageReport.vue'),
  },

  {
    path: '/academy/g-method/login',
    name: 'AcademyLoginV3',
    meta: { layout: 'none' },
    component: () => import('../views/academy/Login.vue'),
  },
  {
    path: '/academy/v3/:id/branch-user',
    name: 'AcademyBranchUserV3',
    component: () => import('../views/academy/v3/BranchUser.vue'),
  },

  {
    path: '/',
    name: 'StudentsV3Login',
    meta: { layout: 'student' },
    component: () => import('../views/student/v3/Login.vue'),
  },
  {
    path: '/admin/login',
    name: 'AdminLogin',
    meta: { layout: 'none' },
    component: () => import('../views/admin/Login.vue'),
  },
  {
    path: '/admin/branch',
    name: 'AdminBranch',
    component: () => import('../views/admin/Branch.vue'),
  },
  {
    path: '/admin/customer',
    name: 'AdminCustomer',
    component: () => import('../views/admin/Customer.vue'),
  },
  {
    path: '/admin/:id/customer-list',
    name: 'AdminCustomerList',
    component: () => import('../views/admin/_id/CustomerList.vue'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/samples/test1',
    name: 'Test1',
    component: () => import('../views/samples/Test1.vue'),
  },
  {
    path: '/users/login',
    name: 'UsersLogin',
    component: () => import('../views/users/Login.vue'),
  },
  {
    path: '/teacher/login',
    name: 'TeachersLogin',
    meta: { layout: 'none' },
    component: () => import('../views/teacher/Login.vue'),
  },
  {
    path: '/teacher/g-method/login',
    name: 'TeacherSignInV3',
    meta: { layout: 'teacher-sign-in' },
    component: () => import('../views/teacher/SignIn1.vue'),
  },
  {
    path: '/teacher/tool/login',
    name: 'TeacherSignInTool',
    meta: { layout: 'teacher-sign-in' },
    component: () => import('../views/teacher/SignIn1.vue'),
  },
  {
    path: '/teacher/sign-in1',
    name: 'TeacherSignIn1',
    meta: { layout: 'teacher-sign-in' },
    component: () => import('../views/teacher/SignIn1.vue'),
  },
  {
    path: '/teacher/sign-in2',
    name: 'TeacherSignIn2',
    meta: { layout: 'teacher-sign-in' },
    component: () => import('../views/teacher/SignIn2.vue'),
  },
  {
    path: '/teacher/sign-in3',
    name: 'TeacherSignIn3',
    meta: { layout: 'teacher-sign-in' },
    component: () => import('../views/teacher/SignIn3.vue'),
  },
  {
    path: '/teacher/sign-in4',
    name: 'TeacherSignIn4',
    meta: { layout: 'teacher-sign-in' },
    component: () => import('../views/teacher/SignIn4.vue'),
  },
  //新しいメニュー バー項目番号 3 を使用
  {
    path: '/teacher/timeline',
    name: 'Timeline',
    component: () => import('../views/teacher/Timeline.vue'),
  },
  //新しいメニュー バー項目番号 3 を使用
  {
    path: '/teacher/v3/timeline',
    name: 'TimelineV3',
    component: () => import('../views/teacher/v3/Timeline.vue'),
  },
  {
    path: '/teacher/v3/report',
    name: 'ReportV3',
    component: () => import('../views/teacher/v3/Report.vue'),
  },
  {
    path: '/teacher/dashboard',
    name: 'TeacherDashboard',
    component: () => import('../views/teacher/Dashboard.vue'),
  },
  {
    path: '/teacher/history',
    name: 'History',
    component: () => import('../views/teacher/v3/History.vue'),
  },
  {
    path: '/teacher/lesson/:id',
    name: 'TeacherLesson',
    component: () => import('../views/teacher/Lesson.vue'),
  },
  {
    path: '/teacher/v3/lesson/:id',
    name: 'TeacherLesson',
    component: () => import('../views/teacher/v3/Lesson.vue'),
  },
  {
    path: '/teacher/status',
    name: 'StudentStatus',
    component: () => import('../views/teacher/StudentStatus.vue'),
  },
  {
    path: '/teacher/memo',
    name: 'SupporterMemo',
    component: () => import('../views/teacher/SupporterMemo.vue'),
  },
  {
    path: '/teacher/csv-download',
    name: 'TeacherCsvDownload',
    component: () => import('../views/teacher/CsvDownload.vue'),
  },
  {
    path: '/student/subjects', // classCategoryCodeを渡す必要がない場合もあるため静的ルーティングも用意
    name: 'SelectSubject',
    component: () => import('../views/student/SelectSubject.vue'),
  },
  {
    path: '/student/subjects/:classCategoryCode',
    name: 'SelectSubject',
    component: () => import('../views/student/SelectSubject.vue'),
  },
  // 補助教材モードでの教科選択にも student/SelectSubject.vue を使用するが、静的プロパティとしてisAssistMaterialを渡すようにする
  {
    path: '/student/assist-material/subjects/:assistMaterialCode',
    name: 'AssistMaterialSelectSubject',
    component: () => import('../views/student/SelectSubject.vue'),
    props: { isAssistMaterial: true },
  },
  {
    path: '/student/v2/subjects/:classMode',
    name: 'SelectSubject',
    component: () => import('../views/student/v2/SelectSubject.vue'),
  },
  {
    path: '/student/prefectures', // classCategoryCodeを渡す必要がない場合もあるため静的ルーティングも用意
    name: 'SelectPrefecture',
    component: () => import('../views/student/SelectPrefecture.vue'),
  },
  {
    path: '/student/confirmation/:classCategoryCode',
    name: 'ConfirmationTest',
    component: () => import('../views/student/DrillConfirmation.vue'),
  },
  {
    // TODO GDLS-600 削除予定
    path: '/student/study-challenge/:route/:id',
    name: 'StudyChallenge',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/StudyChallenge.vue'),
  },
  {
    path: '/student/drill',
    name: 'drill',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/Drill.vue'),
  },
  {
    path: '/student/drill/scoring',
    name: 'drillScoring',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/DrillScoring.vue'),
  },
  {
    path: '/student/drill/result',
    name: 'drillResult',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/DrillResult.vue'),
  },
  {
    path: '/student/v2/drill',
    name: 'drillV2',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/v2/Drill.vue'),
  },
  {
    path: '/student/v2/drill/scoring',
    name: 'drillScoringV2',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/v2/DrillScoring.vue'),
  },
  {
    path: '/student/v2/drill/result',
    name: 'drillResultV2',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/v2/DrillResult.vue'),
  },
  {
    path: '/student/v2/drill/result/unit',
    name: 'drillResultUnitV2',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/v2/DrillResultUnit.vue'),
  },
  {
    path: '/student/v2/explanatory-video',
    name: 'ExplanatoryVideoV2',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/v2/ExplanatoryVideo.vue'),
  },
  {
    path: '/student/study/challenge/challenge-2',
    name: 'Challenge2',
    component: () => import('../views/student/study/challenge/Challenge2.vue'),
  },
  {
    path: '/student/study/challenge/challenge-3',
    name: 'Challenge3',
    component: () => import('../views/student/study/challenge/Challenge3.vue'),
  },
  {
    path: '/student/study/challenge/challenge-4',
    name: 'Challenge4',
    component: () => import('../views/student/study/challenge/Challenge4.vue'),
  },
  {
    path: '/student/study/challenge/challenge-5',
    name: 'Challenge5',
    component: () => import('../views/student/study/challenge/Challenge5.vue'),
  },
  {
    path: '/student/study/challenge/challenge-6',
    name: 'Challenge6',
    component: () => import('../views/student/study/challenge/Challenge6.vue'),
  },
  {
    path: '/student/study/challenge/challenge-7',
    name: 'Challenge7',
    component: () => import('../views/student/study/challenge/Challenge7.vue'),
  },
  {
    path: '/student/study/challenge/challenge-8',
    name: 'Challenge8',
    component: () => import('../views/student/study/challenge/Challenge8.vue'),
  },
  {
    path: '/student/study/challenge/challenge-9',
    name: 'Challenge9',
    component: () => import('../views/student/study/challenge/Challenge9.vue'),
  },
  {
    path: '/student/study/challenge/challenge-10',
    name: 'Challenge10',
    component: () => import('../views/student/study/challenge/Challenge10.vue'),
  },
  {
    path: '/student/study/collect/collect-1',
    name: 'Collect1',
    component: () => import('../views/student/study/collect/Collect1.vue'),
  },
  {
    path: '/student/study/collect/collect-3',
    name: 'Collect3',
    component: () => import('../views/student/study/collect/Collect3.vue'),
  },
  {
    path: '/student/study/collect/collect-4',
    name: 'Collect4',
    component: () => import('../views/student/study/collect/Collect4.vue'),
  },
  {
    path: '/student/study/collect/collect-5',
    name: 'Collect5',
    component: () => import('../views/student/study/collect/Collect5.vue'),
  },
  {
    path: '/student/study/collect/collect-7',
    name: 'Collect7',
    component: () => import('../views/student/study/collect/Collect7.vue'),
  },
  {
    path: '/student/study/collect/collect-8',
    name: 'Collect8',
    component: () => import('../views/student/study/collect/Collect8.vue'),
  },
  {
    path: '/student/study/collect/collect-9',
    name: 'Collect9',
    component: () => import('../views/student/study/collect/Collect9.vue'),
  },
  {
    path: '/student/study-explanation/:route/:id',
    name: 'StudyExplanation',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/StudyExplanation.vue'),
  },
  {
    path: '/student/study/explanation/explanation-1',
    name: 'Explanation1',
    component: () => import('../views/student/study/explanation/Explanation1.vue'),
  },
  {
    path: '/student/study/explanation/explanation-3',
    name: 'Explanation3',
    component: () => import('../views/student/study/explanation/Explanation3.vue'),
  },
  {
    path: '/student/study/explanation/explanation-4',
    name: 'Explanation4',
    component: () => import('../views/student/study/explanation/Explanation4.vue'),
  },
  {
    path: '/student/study/explanation/explanation-5',
    name: 'Explanation5',
    component: () => import('../views/student/study/explanation/Explanation5.vue'),
  },
  {
    path: '/student/study/explanation/explanation-7',
    name: 'Explanation7',
    component: () => import('../views/student/study/explanation/Explanation7.vue'),
  },
  {
    path: '/student/study/explanation/explanation-8',
    name: 'Explanation8',
    component: () => import('../views/student/study/explanation/Explanation8.vue'),
  },
  {
    path: '/student/study/explanation/explanation-9',
    name: 'Explanation9',
    component: () => import('../views/student/study/explanation/Explanation9.vue'),
  },
  {
    path: '/student/study-result/:route/:id',
    name: 'StudyResult',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/StudyResult.vue'),
  },
  {
    path: '/student/curriculum-s-units/:curriculumSUnitId/class-categories',
    name: 'CurriculumSUnitsClassCategories',
    component: () => import('../views/student/CurriculumSUnitsClassCategories.vue'),
  },
  {
    path: '/student/v2/curriculum-s-units/:subjectCode/:curriculumSUnitId/class-categories',
    name: 'CurriculumSUnitsClassCategories',
    component: () => import('../views/student/v2/CurriculumSUnitsClassCategories.vue'),
  },
  {
    path: '/student/study-drill/:route/:id',
    name: 'StudyDrill',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/StudyDrill.vue'),
  },
  {
    path: '/student/study/drill/drill-1',
    name: 'Drill1',
    component: () => import('../views/student/study/drill/Drill1.vue'),
  },
  {
    path: '/student/s-problems/explanatory-video',
    name: 'ExplanatoryVideo',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/ExplanatoryVideo.vue'),
  },
  {
    path: '/student/curriculum-s-units/:curriculumSUnitId/introduction-video',
    name: 'IntroductionVideo',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/IntroductionVideo.vue'),
  },
  {
    path: '/:role/report/curriculum-s-units/:curriculumSUnitId/introduction-video',
    name: 'ReportIntroductionVideo',
    meta: { layout: 'report-headmenu' },
    component: () => import('../views/student/IntroductionVideo.vue'),
  },
  {
    path: '/student/v2/curriculum-s-units/:curriculumSUnitId/introduction-video',
    name: 'IntroductionVideoV2',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/v2/IntroductionVideo.vue'),
  },
  {
    path: '/student/transfer',
    name: 'Transfer',
    component: () => import('../views/student/Transfer.vue'),
  },
  {
    path: '/student/curriculum-s-units',
    name: 'CurriculumSUnits',
    component: () => import('../views/student/CurriculumSUnits.vue'),
  },
  {
    path: '/student/v2/curriculum-s-units/:classMode/:subjectCode',
    name: 'CurriculumSUnits',
    component: () => import('../views/student/v2/CurriculumSUnits.vue'),
  },
  {
    path: '/student/v3/bookmark/detail/:curriculumSUnitId/:questionCode',
    name: 'StudentTimeBookmarkDetail',
    component: () => import('../views/student/v3/BookmarkDetail.vue'),
  },
  {
    path: '/student/v3/bookmark/:classMode/:subjectCode',
    name: 'StudentTimeBookmark',
    component: () => import('../views/student/v3/Bookmark.vue'),
  },
  {
    path: '/student/seminar-unit-selection',
    name: 'SeminarUnitSelection',
    component: () => import('../views/student/SeminarUnitSelection.vue'),
  },
  {
    path: '/student/review/:id',
    name: 'Review',
    component: () => import('../views/student/Review.vue'),
  },
  {
    path: '/student/login',
    name: 'StudentsLogin',
    component: () => import('../views/student/Login.vue'),
  },
  {
    path: '/student/sfl/login',
    name: 'StudentsSflLogin',
    component: () => import('../views/student/v3/Login.vue'),
  },
  {
    path: '/student/eliveplus/login',
    name: 'StudentsEliveplusLogin',
    component: () => import('../views/student/v3/Login.vue'),
  },
  {
    path: '/student/homusuku/login',
    name: 'StudentsHomusukuLogin',
    component: () => import('../views/student/v3/Login.vue'),
  },
  {
    path: '/student/effisage/login',
    name: 'StudentsEffisageLogin',
    component: () => import('../views/student/v3/Login.vue'),
  },
  {
    path: '/student/method/login',
    name: 'StudentsMethodLogin',
    component: () => import('../views/student/v2/MethodLogin.vue'),
  },
  {
    path: '/student/dashboard',
    name: 'StudentsDashboard',
    component: () => import('../views/student/Dashboard.vue'),
  },
  {
    path: '/student/v2/dashboard',
    name: 'StudentsDashboardV2',
    component: () => import('../views/student/v2/Dashboard.vue'),
  },
  {
    path: '/student/history/normal/learning-situation',
    name: 'LearningSituation',
    component: () => import('../views/student/history/normal/LearningSituation.vue'),
  },
  {
    path: '/student/v2/history/normal/learning-situation',
    name: 'LearningSituationV2',
    component: () => import('../views/student/v2/history/normal/LearningSituation.vue'),
  },
  {
    path: '/student/history/normal/target-situation',
    name: 'TargetSituation',
    component: () => import('../views/student/history/normal/TargetSituation.vue'),
  },
  {
    path: '/student/history/normal/subject-situation',
    name: 'SubjectSituation',
    component: () => import('../views/student/history/normal/SubjectSituation.vue'),
  },
  {
    path: '/student/v2/history/normal/subject-situation',
    name: 'SubjectSituationV2',
    component: () => import('../views/student/v2/history/normal/SubjectSituation.vue'),
  },
  {
    path: '/student/history/normal/lesson-situation',
    name: 'LessonSituation',
    component: () => import('../views/student/history/normal/LessonSituation.vue'),
  },
  {
    path: '/student/history/test/step-situation',
    name: 'TestStepSituation',
    component: () => import('../views/student/history/test/StepSituation.vue'),
  },
  {
    path: '/student/self-setting/:classMode',
    name: 'StudentSelfSetting',
    component: () => import('../views/student/v3/StepSituation.vue'),
  },
  {
    path: '/student/history/test/target-situation',
    name: 'TestTargetSituation',
    component: () => import('../views/student/history/test/TargetSituation.vue'),
  },
  {
    path: '/student/history/test/learning-situation',
    name: 'TestLearningSituation',
    component: () => import('../views/student/history/test/LearningSituation.vue'),
  },
  {
    path: '/student/history/test/subject-situation',
    name: 'TestSubjectSituation',
    component: () => import('../views/student/history/test/SubjectSituation.vue'),
  },
  {
    path: '/student/history/test/lesson-situation',
    name: 'TestLessonSituation',
    component: () => import('../views/student/history/test/LessonSituation.vue'),
  },
  {
    path: '/student/history/seminar/target-situation',
    name: 'SeminarTargetSituation',
    component: () => import('../views/student/history/seminar/TargetSituation.vue'),
  },
  {
    path: '/student/history/seminar/learning-situation',
    name: 'SeminarLearningSituation',
    component: () => import('../views/student/history/seminar/LearningSituation.vue'),
  },
  {
    path: '/student/history/seminar/subject-situation',
    name: 'SeminarSubjectSituation',
    component: () => import('../views/student/history/seminar/SubjectSituation.vue'),
  },
  {
    path: '/student/history/seminar/lesson-situation',
    name: 'SeminarLessonSituation',
    component: () => import('../views/student/history/seminar/LessonSituation.vue'),
  },
  {
    path: '/student/history/entrance/pretest-situation',
    name: 'EntrancePretestSituation',
    component: () => import('../views/student/history/entrance/PretestSituation.vue'),
  },
  {
    path: '/student/history/entrance/target-situation',
    name: 'EntranceTargetSituation',
    component: () => import('../views/student/history/entrance/TargetSituation.vue'),
  },
  {
    path: '/student/history/entrance/learning-situation',
    name: 'EntranceLearningSituation',
    component: () => import('../views/student/history/entrance/LearningSituation.vue'),
  },
  {
    path: '/student/history/entrance/lesson-situation',
    name: 'EntranceLessonSituation',
    component: () => import('../views/student/history/entrance/LessonSituation.vue'),
  },
  {
    path: '/student/history/assist-material',
    name: 'AssistMaterialHistory',
    component: () => import('../views/student/history/assistmaterial/AssistMaterialHistory.vue'),
  },
  {
    path: '/admin/sign-in1',
    name: 'AdminSignIn1',
    meta: { layout: 'none' },
    component: () => import('../views/admin/SignIn1.vue'),
  },
  {
    path: '/admin/customer',
    name: 'AdminCustomer',
    component: () => import('../views/admin/Customer.vue'),
  },
  {
    path: '/admin/request',
    name: 'AdminRequest',
    component: () => import('../views/admin/Request.vue'),
  },
  {
    path: '/admin/notification',
    name: 'AdminNotification',
    component: () => import('../views/admin/Notification.vue'),
  },
  {
    path: '/admin/notification-edit',
    name: 'AdminNotificationEdit',
    component: () => import('../views/admin/NotificationEdit.vue'),
  },
  {
    path: '/admin/notification-edit/:id',
    name: 'AdminNotificationEditId',
    component: () => import('../views/admin/NotificationEdit.vue'),
  },
  {
    path: '/admin/access-log',
    name: 'AdminAccessLog',
    component: () => import('../views/admin/AccessLog.vue'),
  },
  {
    path: '/admin/branch',
    name: 'AdminBranch',
    component: () => import('../views/admin/Branch.vue'),
  },
  {
    path: '/academy/login',
    name: 'AcademyLogin',
    meta: { layout: 'none' },
    component: () => import('../views/academy/Login.vue'),
  },
  {
    path: '/academy/request',
    name: 'AcademyRequest',
    component: () => import('../views/academy/Request.vue'),
  },
  {
    path: '/academy/request-edit',
    name: 'AcademyRequestEdit',
    component: () => import('../views/academy/RequestEdit.vue'),
  },
  {
    path: '/academy/v3/request-edit',
    name: 'AcademyRequestEditV3',
    component: () => import('../views/academy/v3/RequestEdit.vue'),
  },
  {
    path: '/academy/request-default',
    name: 'AcademyRequestDefault',
    component: () => import('../views/academy/RequestDefault.vue'),
  },
  {
    path: '/academy/branch',
    name: 'AcademyBranch',
    component: () => import('../views/academy/Branch.vue'),
  },
  {
    path: '/academy/:id/branch-user',
    name: 'AcademyBranchUser',
    component: () => import('../views/academy/BranchUser.vue'),
  },
  {
    path: '/academy/csv-download',
    name: 'AcademyCsvDownload',
    component: () => import('../views/academy/CsvDownload.vue'),
  },
  {
    path: '/teacher/history/test/step-situation',
    name: 'TeacherTestStepSituation',
    component: () => import('../views/teacher/history/test/StepSituation.vue'),
  },
  {
    path: '/teacher/history/test/target-situation',
    name: 'TeacherTestTargetSituation',
    component: () => import('../views/teacher/history/test/TargetSituation.vue'),
  },
  {
    path: '/teacher/history/test/learning-situation',
    name: 'TeacherTestLearningSituation',
    component: () => import('../views/teacher/history/test/LearningSituation.vue'),
  },
  {
    path: '/teacher/history/test/subject-situation',
    name: 'TeacherTestSubjectSituation',
    component: () => import('../views/teacher/history/test/SubjectSituation.vue'),
  },
  {
    path: '/teacher/history/test/lesson-situation',
    name: 'TeacherTestLessonSituation',
    component: () => import('../views/teacher/history/test/LessonSituation.vue'),
  },
  {
    path: '/teacher/history/seminar/target-situation',
    name: 'TeacherSeminarTargetSituation',
    component: () => import('../views/teacher/history/seminar/TargetSituation.vue'),
  },
  {
    path: '/teacher/history/seminar/learning-situation',
    name: 'TeacherSeminarLearningSituation',
    component: () => import('../views/teacher/history/seminar/LearningSituation.vue'),
  },
  {
    path: '/teacher/history/seminar/subject-situation',
    name: 'TeacherSeminarSubjectSituation',
    component: () => import('../views/teacher/history/seminar/SubjectSituation.vue'),
  },
  {
    path: '/teacher/history/seminar/lesson-situation',
    name: 'TeacherSeminarLessonSituation',
    component: () => import('../views/teacher/history/seminar/LessonSituation.vue'),
  },
  {
    path: '/teacher/history/entrance/pretest-situation',
    name: 'TeacherEntrancePretestSituation',
    component: () => import('../views/teacher/history/entrance/PretestSituation.vue'),
  },
  {
    path: '/teacher/history/entrance/target-situation',
    name: 'TeacherEntranceTargetSituation',
    component: () => import('../views/teacher/history/entrance/TargetSituation.vue'),
  },
  {
    path: '/teacher/history/entrance/learning-situation',
    name: 'TeacherEntranceLearningSituation',
    component: () => import('../views/teacher/history/entrance/LearningSituation.vue'),
  },
  {
    path: '/teacher/history/entrance/lesson-situation',
    name: 'TeacherEntranceLessonSituation',
    component: () => import('../views/teacher/history/entrance/LessonSituation.vue'),
  },
  {
    path: '/teacher/history/normal/learning-situation',
    name: 'TeacherLearningSituation',
    component: () => import('../views/teacher/history/normal/LearningSituation.vue'),
  },
  {
    path: '/teacher/history/normal/target-situation',
    name: 'TeacherTargetSituation',
    component: () => import('../views/teacher/history/normal/TargetSituation.vue'),
  },
  {
    path: '/teacher/history/normal/subject-situation',
    name: 'TeacherSubjectSituation',
    component: () => import('../views/teacher/history/normal/SubjectSituation.vue'),
  },
  {
    path: '/teacher/history/normal/lesson-situation',
    name: 'TeacherLessonSituation',
    component: () => import('../views/teacher/history/normal/LessonSituation.vue'),
  },
  {
    path: '/teacher/history/normal-v1/learning-situation',
    name: 'TeacherLearningSituationV1',
    component: () => import('../views/teacher/history/normal/LearningSituation.vue'),
  },
  {
    path: '/teacher/history/normal-v1/subject-situation',
    name: 'TeacherSubjectSituationV1',
    component: () => import('../views/teacher/history/normal/SubjectSituation.vue'),
  },
  {
    path: '/teacher/history/normal-v1/lesson-situation',
    name: 'TeacherLessonSituationV1',
    component: () => import('../views/teacher/history/normal/LessonSituation.vue'),
  },
  {
    path: '/teacher/history/normal-v1/target-situation',
    name: 'TeacherTargetSituationV1',
    component: () => import('../views/teacher/history/normal/TargetSituation.vue'),
  },
  {
    path: '/teacher/history/custom/target-situation',
    name: 'TeacherTargetSituationCustom',
    component: () => import('../views/teacher/history/normal/TargetSituation.vue'),
  },
  {
    path: '/teacher/history/custom/learning-situation',
    name: 'TeacherLearningSituationCustom',
    component: () => import('../views/teacher/history/normal/LearningSituation.vue'),
  },
  {
    path: '/teacher/history/custom/subject-situation',
    name: 'TeacherSubjectSituationCustom',
    component: () => import('../views/teacher/history/normal/SubjectSituation.vue'),
  },
  {
    path: '/teacher/history/custom/lesson-situation',
    name: 'TeacherLessonSituationCustom',
    component: () => import('../views/teacher/history/normal/LessonSituation.vue'),
  },
  {
    path: '/teacher/history/assist-material/lesson-situation',
    name: 'TeacherAssistMaterialLessonSituation',
    component: () => import('../views/teacher/history/assistmaterial/LessonSituation.vue'),
  },
  {
    path: '/student/assist-material/operation',
    name: 'AssistMaterialOperation',
    component: () => import('../views/student/AssistMaterialOperation.vue'),
  },
  {
    path: '/student/assist-material',
    name: 'AssistMaterial',
    component: () => import('../views/student/AssistMaterial.vue'),
  },
  {
    path: '/student/assist-material/select/:assistMaterialCode',
    name: 'AssistMaterialSelect',
    component: () => import('../views/student/AssistMaterialSelect.vue'),
    props: true,
  },
  {
    path: '/student/assist-material/video/:curriculumSUnitId',
    name: 'AssistMaterialVidel',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/AssistMaterialVideo.vue'),
    props: true,
  },
  {
    path: '/:role/report/assist-material/video/:curriculumSUnitId',
    name: 'ReportAssistMaterialVidel',
    meta: { layout: 'report-headmenu' },
    component: () => import('../views/student/AssistMaterialVideo.vue'),
    props: true,
  },
  {
    path: '/student/assist-material/practice/:curriculumSUnitId',
    name: 'AssistMaterialPractice',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/AssistMaterialPractice.vue'),
    props: true,
  },
  {
    path: '/student/history/:learningType/study-unit/:id',
    name: 'StudentsStudyUnit',
    component: () => import('../views/student/history/StudyUnit.vue'),
    props: true,
  },
  {
    path: '/student/v2/history/study-unit/:id',
    name: 'StudentsV2StudyUnit',
    component: () => import('../views/student/v2/history/StudyUnit.vue'),
    props: true,
  },
  {
    path: '/student/history/drillResult/:resultDrillId',
    name: 'StudentsHistoryDrillResult',
    component: () => import('../views/student/history/DrillResult.vue'),
    props: true,
  },
  {
    path: '/student/v2/history/drillResult',
    name: 'StudentsV2HistoryDrillResult',
    component: () => import('../views/student/v2/history/DrillResult.vue'),
    props: true,
  },
  {
    path: '/teacher/history/:learningType/study-unit/:id',
    name: 'TeachersStudyUnit',
    component: () => import('../views/teacher/history/StudyUnit.vue'),
    props: true,
  },
  {
    path: '/teacher/history/drillResult/:resultDrillId',
    name: 'TeachersHistoryDrillResult',
    component: () => import('../views/teacher/history/DrillResult.vue'),
    props: true,
  },
  {
    path: '/teacher/v2/history/drillResult',
    name: 'TeachersV2HistoryDrillResult',
    component: () => import('../views/teacher/v2/history/DrillResult.vue'),
    props: true,
  },
  {
    path: '/student/goal-list',
    name: 'StudentGoalList',
    component: () => import('../views/student/GoalList.vue'),
  },
  {
    path: '/student/goal-setting',
    name: 'StudentGoalSetting',
    component: () => import('../views/student/GoalSetting.vue'),
  },
  {
    path: '/student/goal-setting/:goalId',
    name: 'StudentGoalSetting',
    component: () => import('../views/student/GoalSetting.vue'),
    props: true,
  },
  {
    path: '/student/goal-setting-entrance',
    name: 'StudentGoalSettingEntrance',
    component: () => import('../views/student/GoalSettingEntrance.vue'),
  },
  {
    path: '/student/goal-setting-entrance/:goalId',
    name: 'StudentGoalSettingEntrance',
    component: () => import('../views/student/GoalSettingEntrance.vue'),
    props: true,
  },

  //新しいメニュー バー項目番号 3 を使用
  {
    path: '/teacher/setting/top',
    name: 'TeachersSettingTop',
    component: () => import('../views/teacher/v3/setting/SettingTopContainer.vue'),
  },

  {
    path: '/teacher/setting/school',
    name: 'TeachersSettingSchool',
    component: () => import('../views/teacher/setting/School.vue'),
  },
  {
    path: '/teacher/setting/v3/school',
    name: 'TeachersSettingSchool',
    component: () => import('../views/teacher/v3/setting/School.vue'),
  },
  {
    path: '/student/class-modes/',
    name: 'StudentsClassModes',
    component: () => import('../views/student/ClassModes.vue'),
  },
  {
    path: '/student/v2/class-modes/',
    name: 'StudentsClassModes',
    component: () => import('../views/student/v2/ClassModes.vue'),
  },
  {
    path: '/teacher/setting/student',
    name: 'TeachersSettingStudent',
    component: () => import('../views/teacher/setting/StudentInformation.vue'),
  },
  {
    path: '/teacher/setting/v3/student',
    name: 'TeachersSettingStudent',
    component: () => import('../views/teacher/v3/setting/StudentInformation.vue'),
  },
  {
    path: '/teacher/setting/student/edit',
    name: 'TeachersSettingStudentEdit',
    component: () => import('../views/teacher/setting/StudentEdit.vue'),
  },
  {
    path: '/teacher/setting/v3/student/edit',
    name: 'TeachersSettingStudentEditV3',
    component: () => import('../views/teacher/v3/setting/StudentEdit.vue'),
  },
  {
    path: '/teacher/setting/student/curriculum',
    name: 'TeachersSettingStudentCurriculum',
    component: () => import('../views/teacher/setting/StudentCurriculum.vue'),
  },
  {
    path: '/teacher/setting/student/reserve',
    name: 'TeachersSettingStudentReserve',
    component: () => import('../views/teacher/setting/StudentReserve.vue'),
  },
  {
    path: '/teacher/setting/timetable',
    name: 'TeachersSettingTimetable',
    component: () => import('../views/teacher/setting/Timetable.vue'),
  },
  {
    path: '/teacher/setting/teacher',
    name: 'TeachersSettingTeacher',
    component: () => import('../views/teacher/setting/Teacher.vue'),
  },
  {
    path: '/teacher/setting/teacher/:teacherId/student-charge',
    name: 'StudentCharge',
    component: () => import('../views/teacher/setting/StudentCharge.vue'),
    props: true,
  },
  {
    path: '/teacher/setting/attribute',
    name: 'TeachersSettingAttribute',
    component: () => import('../views/teacher/setting/Attribute.vue'),
  },
  {
    path: '/teacher/setting/v3/attribute',
    name: 'TeachersSettingAttribute',
    component: () => import('../views/teacher/v3/setting/Attribute.vue'),
  },
  {
    path: '/teacher/setting/lesson',
    name: 'TeachersSettingLesson',
    component: () => import('../views/teacher/setting/Lesson.vue'),
  },
  {
    path: '/teacher/setting/default-exit-condition',
    name: 'TeachersSettingDefaultExitCondition',
    component: () => import('../views/teacher/setting/DefaultExitCondition.vue'),
  },
  {
    path: '/teacher/setting/student-exit-condition',
    name: 'TeachersSettingStudentExitCondition',
    component: () => import('../views/teacher/setting/StudentExitCondition.vue'),
  },
  {
    path: '/teacher/setting/student-timetable',
    name: 'TeachersSettingStudentTimetable',
    component: () => import('../views/teacher/setting/StudentTimetable.vue'),
  },
  {
    path: '/teacher/setting/class-name',
    name: 'TeachersSettingClassName',
    component: () => import('../views/teacher/setting/ClassName.vue'),
  },
  {
    path: '/teacher/setting/school-curriculum/:schoolId',
    name: 'TeachersSettingSchoolCurriculum',
    component: () => import('../views/teacher/setting/SchoolCurriculum.vue'),
    props: (route) => ({ schoolId: Number(route.params.schoolId) }),
  },
  {
    path: '/teacher/setting/school-test/:id',
    name: 'TeachersSettingSchoolTest',
    component: () => import('../views/teacher/setting/SchoolTest.vue'),
  },
  {
    path: '/teacher/setting/school-test/:id/term/:termId/unit/:subid',
    name: 'TeachersSettingSchoolTestUnit',
    component: () => import('../views/teacher/setting/SchoolTestUnit.vue'),
  },
  {
    path: '/teacher/setting/school-test/:id/term/:termId/page/:subid',
    name: 'TeachersSettingSchoolTestPage',
    component: () => import('../views/teacher/setting/SchoolTestPage.vue'),
  },
  {
    path: '/teacher/setting/seminar',
    name: 'TeachersSettingSeminar',
    component: () => import('../views/teacher/setting/Seminar.vue'),
  },
  {
    path: '/teacher/setting/seminar-edit/:id',
    name: 'TeachersSettingSeminarEdit',
    component: () => import('../views/teacher/setting/SeminarEdit.vue'),
  },
  {
    path: '/teacher/setting/student-service',
    name: 'TeachersSettingStudentService',
    component: () => import('../views/teacher/setting/StudentService.vue'),
  },
  {
    path: '/teacher/setting/v3/student-service',
    name: 'TeachersSettingStudentService',
    component: () => import('../views/teacher/v3/setting/StudentService.vue'),
  },
  {
    path: '/teacher/setting/other',
    name: 'TeachersSettingOther',
    component: () => import('../views/teacher/setting/OtherSettings.vue'),
  },
  {
    path: '/teacher/branch-learning-situation',
    name: 'BranchLearningSituation',
    component: () => import('../views/teacher/setting/BranchLearningSituation.vue'),
  },
  {
    path: '/student/timeline',
    name: 'StudentTimeline',
    component: () => import('../views/student/Timeline.vue'),
  },

  {
    path: '/student/schedule',
    name: 'StudentSchedule',
    component: () => import('../views/student/StudentSchedule.vue'),
  },
  {
    path: '/teacher/schedule/share',
    name: 'TeacherSharedSchedule',
    component: () => import('../views/teacher/SharedSchedule.vue'),
  },
  {
    path: '/teacher/schedule/student',
    name: 'TeacherStudentSchedule',
    component: () => import('../views/teacher/StudentSchedule.vue'),
  },

  /**
   * SAML認証
   */
  {
    // 学研マイページから遷移時の窓口
    path: '/gakken-saml-sso',
    name: 'GakkenSamlSso',
    component: () => import('../views/saml/GakkenSamlSso.vue'),
  },
  {
    // Idp認証後の受口（acs）
    path: '/gakken-saml-acs',
    name: 'GakkenSamlAcs',
    component: () => import('../views/saml/GakkenSamlAcs.vue'),
  },

  {
    // 学研マイページから遷移時の窓口
    path: '/g-method/gakken-saml-sso',
    name: 'GakkenSamlSso',
    component: () => import('../views/saml/v3/GakkenSamlSso.vue'),
  },

  /**
   * エラー画面
   */
  {
    path: '/error/error400',
    name: 'Error400',
    component: () => import('../views/error/Error400.vue'),
  },
  {
    path: '/error/error404',
    name: 'Error404',
    component: () => import('../views/error/Error404.vue'),
  },
  {
    path: '/error/error500',
    name: 'Error500',
    component: () => import('../views/error/Error500.vue'),
  },
  {
    path: '/error/error503',
    name: 'Error503',
    component: () => import('../views/error/Error503.vue'),
  },
  {
    path: '/student/entrance-range-selection',
    name: 'StudentEntranceRangeSelection',
    component: () => import('../views/student/EntranceRangeSelection.vue'),
  },
  {
    path: '/url_check',
    name: 'UrlCheck',
    component: () => import('../views/UrlCheck.vue'),
  },
  {
    path: '*',
    redirect: '/error/error404',
  },

  //生徒をモードに割り当てる
  {
    path: '/teacher/setting/list-mode',
    name: 'listMode',
    component: () => import('../views/teacher/v3/setting/ListMode.vue'),
  },
  {
    path: '/teacher/setting/mode-student',
    name: 'ModeStudent',
    component: () => import('../views/teacher/v3/setting/ModeStudent.vue'),
  },
  {
    path: '/student/v3/curriculum-s-units/:classMode/:subjectCode',
    name: 'CurriculumSUnitsV3',
    component: () => import('../views/student/v3/CurriculumSUnits.vue'),
  },
  {
    path: '/student/v3/curriculum-s-units/:subjectCode/:curriculumSUnitId/class-categories/:classMode',
    name: 'CurriculumSUnitsClassCategoriesV3',
    component: () => import('../views/student/v3/CurriculumSUnitsClassCategories.vue'),
  },
  //先生_生徒に割り当てられるモード一覧
  {
    path: '/student/v3/dashboard',
    name: 'StudentsDashboardV3',
    component: () => import('../views/student/v3/Dashboard.vue'),
  },
  {
    path: '/student/v3/subjects/:classMode',
    name: 'SelectSubjectCaterV3',
    component: () => import('../views/student/v3/SelectSubject.vue'),
  },
  {
    path: '/student/v3/subjects', // classCategoryCodeを渡す必要がない場合もあるため静的ルーティングも用意
    name: 'SelectSubjectV3',
    component: () => import('../views/student/v3/SelectSubject.vue'),
  },
  {
    path: '/student/v3/drill-one-math/:questionCode',
    name: 'DrillOneV3Math',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/v3/DrillAiOneQuestionMath.vue'),
  },
  {
    path: '/student/v3/drill-similar-math/:questionCode',
    name: 'DrillSimilarV3',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/v3/DrillAiOneSimilarMath.vue'),
  },
  {
    path: '/student/v3/drillMath',
    name: 'drillV3Math',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/v3/DrillAiMath.vue'),
  },
  {
    path: '/student/v3/drill',
    name: 'drillV3',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/v3/Drill.vue'),
  },
  {
    path: '/student/v3/drill/scoring',
    name: 'drillScoringV3',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/v3/DrillScoring.vue'),
  },
  {
    path: '/student/v3/drill/scoringMath',
    name: 'drillScoringV3Math',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/v3/DrillScoringAiMath.vue'),
  },
  {
    path: '/student/v3/drill/result',
    name: 'drillResultV3',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/v3/DrillResult.vue'),
  },
  {
    path: '/student/v3/drill/result/unit',
    name: 'drillResultUnitV3',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/v3/DrillResultUnit.vue'),
  },
  //note report page study
  {
    path: '/:role/report/assist-material/practice/:curriculumSUnitId',
    name: 'ReportAssistMaterialPractice',
    meta: { layout: 'report-headmenu' },
    component: () => import('../views/student/AssistMaterialPractice.vue'),
    props: true,
  },
  {
    path: '/:role/report/drill',
    name: 'ReportDrill',
    meta: { layout: 'report-headmenu' },
    component: () => import('../views/student/Drill.vue'),
  },
  {
    path: '/:role/report/drill/scoring',
    name: 'ReportDrillScoring',
    meta: { layout: 'report-headmenu' },
    component: () => import('../views/student/DrillScoring.vue'),
  },
  {
    path: '/:role/report/v3/drill-one-math/:questionCode',
    name: 'ReportDrilOnelMath',
    meta: { layout: 'report-headmenu' },
    component: () => import('../views/student/v3/DrillAiOneQuestionMath.vue'),
  },
  {
    path: '/:role/report/v3/drill-similar-math/:questionCode',
    name: 'ReportDrillSimilarlMath',
    meta: { layout: 'report-headmenu' },
    component: () => import('../views/student/v3/DrillAiOneSimilarMath.vue'),
  },
  {
    path: '/:role/report/v3/drillMath',
    name: 'ReportDrillMath',
    meta: { layout: 'report-headmenu' },
    component: () => import('../views/student/v3/DrillAiMath.vue'),
  },
  {
    path: '/:role/report/v3/drill/scoringMath',
    name: 'ReportDrillScoringMath',
    meta: { layout: 'report-headmenu' },
    component: () => import('../views/student/v3/DrillScoringAiMath.vue'),
  },
  {
    path: '/:role/report/drill/result',
    name: 'ReportDrillResult',
    meta: { layout: 'report-headmenu' },
    component: () => import('../views/student/DrillResult.vue'),
  },
  {
    path: '/:role/report/s-problems/explanatory-video',
    name: 'ReportExplanatoryVideo',
    meta: { layout: 'report-headmenu' },
    component: () => import('../views/student/ExplanatoryVideo.vue'),
  },
  {
    path: '/:role/report/:type/drill',
    name: 'ReportDrillV3',
    meta: { layout: 'report-headmenu' },
    component: () => import('../views/teacher/v3/report/Drill.vue'),
  },
  {
    path: '/:role/report/:type/drill/scoring',
    name: 'ReportDrillScoringV3',
    meta: { layout: 'report-headmenu' },
    component: () => import('../views/teacher/v3/report/DrillScoring.vue'),
  },
  {
    path: '/:role/report/:type/drill/result',
    name: 'ReportResultV3',
    meta: { layout: 'report-headmenu' },
    component: () => import('../views/teacher/v3/report/DrillResult.vue'),
  },
  {
    path: '/:role/report/:type/drill/result/unit',
    name: 'ReportDrillResultUnitV3',
    meta: { layout: 'report-headmenu' },
    component: () => import('../views/teacher/v3/report/DrillResultUnit.vue'),
  },
  {
    path: '/:role/report/:type/explanatory-video',
    name: 'ReportExplanatoryVideoV3',
    meta: { layout: 'report-headmenu' },
    component: () => import('../views/teacher/v3/report/ExplanatoryVideo.vue'),
  },
  //end note report page study
  {
    path: '/student/v3/history/study-unit/:id',
    name: 'StudentsV3StudyUnit',
    component: () => import('../views/student/v3/history/StudyUnit.vue'),
    props: true,
  },
  {
    path: '/student/v3/history/drillResult',
    name: 'StudentsV3HistoryDrillResult',
    component: () => import('../views/student/v3/history/DrillResult.vue'),
    props: true,
  },
  {
    path: '/teacher/v3/history/drillResult',
    name: 'TeachersV3HistoryDrillResult',
    component: () => import('../views/teacher/v3/history/DrillResult.vue'),
    props: true,
  },
  {
    path: '/student/v3/history/drillResultMath',
    name: 'StudentsV3HistoryDrillResultMath',
    component: () => import('../views/student/v3/history/DrillResultMath.vue'),
    props: true,
  },
  {
    path: '/teacher/v3/history/drillResultMath',
    name: 'TeachersV3HistoryDrillResultMath',
    component: () => import('../views/teacher/v3/history/DrillResultMath.vue'),
    props: true,
  },
  {
    path: '/student/v3/curriculum-s-units/:curriculumSUnitId/introduction-video/:classMode',
    name: 'IntroductionVideoV3',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/v3/IntroductionVideo.vue'),
  },
  {
    path: '/:role/report/:type/curriculum-s-units/:curriculumSUnitId/introduction-video/:classMode?',
    name: 'ReportIntroductionVideoV3',
    meta: { layout: 'report-headmenu' },
    component: () => import('../views/teacher/v3/report/IntroductionVideo.vue'),
  },
  {
    path: '/student/v3/explanatory-video',
    name: 'ExplanatoryVideoV3',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/v3/ExplanatoryVideo.vue'),
  },
  {
    path: '/student/v3/timeline',
    name: 'StudentTimelineV3',
    component: () => import('../views/student/v3/Timeline.vue'),
  },
  {
    path: '/student/v3/victory',
    name: 'StudentVictory',
    component: () => import('../views/student/v3/Victory.vue'),
  },
  {
    path: '/student/v3/challenge',
    name: 'StudentChallenge',
    component: () => import('../views/student/v3/Challenge.vue'),
  },
  {
    path: '/student/v3/victory/units/:subjectCode',
    name: 'StudentVictoryUnits',
    component: () => import('../views/student/v3/VictoryUnits.vue'),
  },
  {
    path: '/student/v3/victory/units/page/:typePage',
    name: 'StudentVictoryCurriculumSUnitsPage',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/v3/VictoryCurriculumSUnitsPage.vue'),
  },
  {
    path: '/student/v3/victory/units/print/:unitId',
    name: 'StudentVictoryPrintPage',
    meta: { layout: 'student-headmenu' },
    component: () => import('../views/student/v3/VictoryPrintPage.vue'),
  },
  {
    path: '/quiz/units/:level/:parentId',
    name: 'QuizUnits',
    component: () => import('../views/quiz/Units.vue'),
  },
  {
    path: '/quiz',
    name: 'QuizForward',
    component: () => import('../views/quiz/Forward.vue'),
    meta: { layout: 'none' },
  },
  {
    path: '/quiz/question',
    name: 'QuizQuestion',
    component: () => import('../views/quiz/Drill.vue'),
  },
  {
    path: '/quiz/result',
    name: 'QuizResult',
    component: () => import('../views/quiz/ResultDrill.vue'),
  },
  {
    path: '/quiz/overview',
    name: 'QuizOverview',
    component: () => import('../views/quiz/Overview.vue'),
  },
  {
    path: '/quiz/notices',
    name: 'QuizNotices',
    component: () => import('../views/quiz/Notices.vue'),
  },
  {
    path: '/quiz/reset-study',
    name: 'QuizResetStudy',
    component: () => import('../views/quiz/ResetStudy.vue'),
  },
  {
    path: '/quiz/remove-account',
    name: 'QuizRemoveAccount',
    component: () => import('../views/quiz/RemoveAccount.vue'),
  },
  {
    path: '/quiz/error',
    name: 'QuizError',
    component: () => import('../views/quiz/Error.vue'),
    meta: { layout: 'none' },
  },
  {
    path: '/quiz/achievements',
    name: 'QuizAchievements',
    component: () => import('../views/quiz/Achievements.vue'),
  },
  {
    path: '/academy/mode',
    name: 'AcademyMode',
    component: () => import('../views/academy/v3/AcademyMode.vue'),
  },
  {
    path: '/academy/list-mode',
    name: 'AcademyListMode',
    component: () => import('../views/academy/v3/AcademyListMode.vue'),
  },
  {
    path: '/academy/create-mode',
    name: 'AcademyCreateMode',
    component: () => import('../views/academy/v3/AcademyCreateMode.vue'),
  },
  {
    path: '/academy/edit-mode/:modeId',
    name: 'AcademyEditMode',
    component: () => import('../views/academy/v3/AcademyCreateMode.vue'),
  },
  {
    path: '/academy/assign-mode',
    name: 'AcademyAssignMode',
    component: () => import('../views/academy/v3/AcademyAssignMode.vue'),
  },
  {
    path: '/teacher/setting/download-content',
    name: 'TeacherSettingDownloadContent',
    component: () => import('../views/teacher/v3/setting/DownloadContent.vue'),
  },
  {
    path: '/teacher/setting/upload-content',
    name: 'TeacherSettingUploadContent',
    component: () => import('../views/teacher/v3/setting/UploadContent.vue'),
  },
  {
    path: '/teacher/setting/debug-mode',
    name: 'TeacherSettingDebugMode',
    component: () => import('../views/teacher/v3/setting/DebugMode.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to: Route, from: Route, next: Function) => {
  const helper = new RouterHelper(to)
  if (helper.isNavigationable()) return next()

  next({ name: helper.loginRouteName() })
})

export default router
