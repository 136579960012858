










import { Component, Vue } from 'vue-property-decorator'
import FillRound from '@/components/atoms/FillRound.vue'

@Component({
  components: {
    FillRound,
  },
})
export default class StudentBottomPanel extends Vue {
  private get topUrl() {
    return '/student/v3/dashboard'
  }
}
