export enum Level {
  INIT = -1,
  OFF = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  UNLIMITED = 4,
}

export enum TypeScope {
  Unit = 'unit',
  Page = 'page',
}

export interface Icon {
  id: number
  value: string
  image: string
}

export interface ClassPolycies {
  id?: number
  sortNum?: number
  isEnabled?: boolean
  classCategoryName?: string
  classCategoryId?: number
}

export interface ConditionExit {
  subjectId?: number
  subjectName?: string
  limitThreshold?: number
  restrictionName?: string
  restrictionId?: number
  restrictionUnit?: string
}

export interface ConditionItem {
  subjectId?: number
  subject?: string
  item?: string
  limit?: string
  action?: { onclick?: () => void; name?: string }[]
  itemId?: number
  limitThreshold: number
  unit?: string
}

export interface Subject {
  code: string
  id: string | number
  name?: string
  checked?: boolean
  subjectId: string | number
}
export interface Grade {
  id: string | number
  name: string
}

export interface Unit {
  value: string
  text: string
  gradeId: number
  pageText: string
  schoolId?: string
  publisherId?: string
}
export interface UnitResponse {
  endPage: number
  id: number
  name: string
  sUnitName: string
  startPage: number
  gradeName: string
}

export enum Scope {
  INIT,
  GDLS,
  BOOKTEXT,
  SCHOOL,
  NON,
}

export enum StatusType {
  CLOSE = 0,
  OPEN = 1,
  CANCEL = 2,
  SUBMIT = 3,
}

export interface UnitType {
  value: number
  text: string
  gradeId: number
  schoolId?: number
  publisherId?: number
  assignGradeId?: number
}

export interface PageType {
  curriculum_mode_code: string
  endPage: string
  gradeId: string
  publisherId?: number
  startPage: string
  subjectId: number
  schoolId?: number
  assignGradeId: number
}

export interface LearningRangeDataType {
  [subjectId: string]: PageType[] | UnitType[]
}

export interface ScopeUnit {
  subjectId: number
  gradeId: number
  schoolId?: number
  units: { id: number; name: string }[]
}

export interface ScopeUnitCaseBookText extends ScopeUnit {
  schoolId: number
}

export interface ScopePage {
  schoolId: number
  subjectId: number
  gradeId: number
  startPage: number
  endPage: number
  publisherId: number
}

export interface ConditionDTO {
  restrictionId?: number
  subjectId?: number | string
  limitThreshold?: number
}

export interface FlowLearningDTO {
  sortNum?: number
  isEnabled?: boolean
  classCategoryId?: string
}

export interface LearningRangeTypeDTO {
  type: number
  code?: number
  is_default?: boolean
  data: any[]
}
export interface ModeTypeDTO {
  id?: number
  code?: string
  branch_id?: number
  academy_id?: number
  owner_type?: string
  s3_path_id?: number | null
  name?: string
  description?: string
  learning_flows?: FlowLearningDTO[]
  learning_range?: LearningRangeTypeDTO
  depth_of_trace?: number
  learning_end_condition?: {
    type?: number
    data?: ConditionDTO[]
  }
  study_period?: {
    startAt: string | null
    endAt: string | null
  }
  is_random_question?: boolean | null
  is_mode_default?: boolean
  difficulty_settings?: DifficultUpdateData[]
  is_ocr_mode?: boolean
}

export type DifficultUpdateData = {
  class_category_code: string
  subject_levels: {
    subject_id?: number
    levels: number[]
  }[]
}

export interface LearningRangeType {
  type: number
  scope?: any
  data: any
}
export interface ModeType {
  id?: number
  code?: string
  s3_path_id: number | null
  name: string
  description?: string
  learning_flows: ClassPolycies[]
  learning_range: LearningRangeType
  depth_of_trace: Level
  learning_end_condition: {
    type: number
    data?: ConditionExit[]
    isChange?: boolean
  }
  study_period?: {
    start: string | null
    end: string | null
  }
  is_random_question: boolean
  is_mode_default?: boolean
  difficulty_settings?: DifficultSetting[]
  is_ocr_mode?: boolean
}

export type DifficultSetting = {
  class_category_code: string
  subject_code: string
  subject_id?: number
  levels: number[]
}
export type BookOptions = { [key: number]: { text: string; value: number }[] }

export type Page = { gradeId: string; startPage: string; endPage: string; text?: string; publisherId?: string }
