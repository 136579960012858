













import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import TabBase, { TabOption } from '@/components/atoms/TabBase.vue'
import AccordionBase from '@/components/atoms/AccordionBase.vue'
import StudentHistoryContent, { History } from '@/components/organisms/StudentHistoryContent.vue'

type GradeHistories = {
  gradeName: string
  gradeCode: string
  histories: History[]
  date?: string
  count?: number
}

@Component({
  components: {
    TabBase,
    AccordionBase,
    StudentHistoryContent,
  },
})
export default class AccordionStudentHistory extends Vue {
  @Prop()
  gradeHistories!: GradeHistories[]

  @Prop({ default: false })
  isTeacher?: boolean

  @Prop()
  gradeCode?: string

  @Prop({ default: true })
  isShowAgainBtn?: boolean

  @Prop({ default: false })
  isTerm?: boolean

  @Prop()
  termExamId?: number

  @Prop()
  selectedText?: string

  @Prop({ default: '' })
  selectedSubject?: string

  private get totalGradesHistory() {
    return this.gradeHistories.reduce((gradeHistory: History[], currentGradeHistory) => {
      gradeHistory = [...gradeHistory, ...currentGradeHistory.histories]
      return gradeHistory
    }, [])
  }
}
