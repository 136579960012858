

















































import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import UnitInformation from '@/components/organisms/v3/UnitInformation.vue'
import StudentBottomPanel from '@/components/organisms/v3/StudentBottomPanel.vue'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import PositionListUnit from '@/mixins/positionListUnit/PositionListUnit'
import InputWithSearch from '@/components/molecules/InputWithSearch.vue'

export type Unit = {
  curriculumSUnitId: number
  subjectCode: string
  name: string
  gradeCode: string
}

@Component({
  components: {
    SidebarSwitcher,
    ButtonBase,
    SelectBase,
    UnitInformation,
    StudentBottomPanel,
    InputWithSearch
  },
})
export default class CurriculumSUnits extends Mixins(ClassModeMethods, PositionListUnit) {
  private gradeOptionDatas = [
    { text: '小１', value: 's1' },
    { text: '小２', value: 's2' },
    { text: '小３', value: 's3' },
    { text: '小４', value: 's4' },
    { text: '小５', value: 's5' },
    { text: '小６', value: 's6' },
    { text: '中１', value: 't1' },
    { text: '中２', value: 't2' },
    { text: '中３', value: 't3' },
  ]

  private subjectNames = {
    su: '算数/数学',
    ei: '英語',
    ko: '国語',
    sh: '社会',
    ri: '理科',
  }

  private unitDatas: Unit[] = []

  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId

  private classMode = this.$route.params.classMode
  private subjectCode = this.$route.params.subjectCode
  private inputCurriculumSUnitName?: string
  private dataPrediction: {[gradeCode: string]: Unit[]} = {}
  private statusOptionDatas = [
    { text: 'すべて', value: '0' },
    { text: '未学習', value: '1' },
    { text: '学習済み', value: '2' },
  ]
  private statusSelectedData = '0'
  private gradeSelectedCode =
    Vue.prototype.$cookies.get('dataGdls').curriculumSUnitsGrade || Vue.prototype.$cookies.get('dataGdls').grade.code

  private async mounted(): Promise<void> {
    window.scrollTo(0, 0)
    Vue.prototype.$loading.start()
    await this.loadDatas()
    this.scrollToPrePosition()
    Vue.prototype.$loading.complete()
  }

  private async loadDatas(): Promise<void> {
    if (Object.keys(this.dataPrediction).length) {
      this.unitDatas = this.dataPrediction[this.gradeSelectedCode]
        ? this.dataPrediction[this.gradeSelectedCode]
          .filter((unit: any) => {
            if (this.statusSelectedData == '0') return true
            return this.statusSelectedData == '1' ? !unit.isCompleted : unit.isCompleted
          })
          .filter((unit: any) => unit.name.includes(this.inputCurriculumSUnitName))
        : []
      return;
    }
    const endData: Unit[] = []
    const listFetch = this.gradeOptionDatas.map((grade) =>
      Vue.prototype.$http.httpWithToken.get('/v3/curriculumSUnits', {
        params: {
          studyLogicType: 'AI',
          userId: this.userId,
          subjectCode: this.subjectCode,
          gradeCode: grade.value,
          classModeCode: this.classMode,
        },
      })
    )
    try {
      const results = await Promise.all(listFetch)
      results.forEach((result: any) => {
        const dataUnits = result.data.map((item: any) => {
          const gradeOption = this.gradeOptionDatas.find((grade) => grade.value === result.config.params.gradeCode)
          if (gradeOption) {
            if (
              item.name.trim().endsWith(`（${gradeOption.text}）`) ||
              item.name.trim().endsWith(`〈${gradeOption.text}〉`)
            ) {
              return {...item, gradeCode: gradeOption.value }
            }
            return { ...item, name: item.name + ` （${gradeOption.text}）`, gradeCode: gradeOption.value }
          }
        })
        endData.push(...dataUnits)
      })

      this.dataPrediction = endData.reduce((objectResult: {[gradeCode: string]: Unit[]}, item: Unit) => {
        const currentGradeCodeValue = objectResult?.[item.gradeCode] || [];
        currentGradeCodeValue.push(item);
        return {...objectResult, [item.gradeCode]: currentGradeCodeValue }
      },{});

      this.unitDatas = this.isOcrMode ? ( this.dataPrediction?.[this.gradeSelectedCode] || []) : endData;
      
    } catch (error) {
      this.$router.push('/error/error400')
    }
  }

  /**
   * クレジットリンク表示ステータス
   */
  private get isShowCreditLink() {
    return this.subjectCode == 'sh'
  }

  private get selectSubjectUrl() {
    return `/student/v3/subjects/${this.classMode}`
  }

  private get classModeName() {
    let modeName = ''
    if (this.$store.getters['drillsV3/modeActive']) {
      modeName = this.$store.getters['drillsV3/modeActive'].name
    }
    return modeName
  }

  private get isAiMode(): boolean {
    return this.classMode == this.CLASS_MODE.AI
  }

  private get isOcrMode(): boolean {
    return this.classMode == this.CLASS_MODE.OCRLLM
  }

  private async changeConditions(): Promise<void> {
    Vue.prototype.$loading.start()
     await this.loadDatas()
    Vue.prototype.$loading.complete()
  }

  @Watch('gradeSelectedCode')
  private async gradeSelectedCodeChange(newValue: string, oldValue: string): Promise<void> {
    this.inputCurriculumSUnitName = ""
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie['curriculumSUnitsGrade'] = newValue
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
    // 初回は除く
    if (oldValue) await this.changeConditions()
  }

  @Watch('statusSelectedData')
  private async statusSelectedDataChange(): Promise<void> {
    this.inputCurriculumSUnitName = ""
    await this.changeConditions()
  }
}
