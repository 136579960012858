

















import { Component, Prop, Mixins, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import StudyUnitInformation from '@/components/organisms/v3/StudyUnitInformation.vue'
import StudentBottomPanel from '@/components/organisms/v3/StudentBottomPanel.vue'
import { StudyHistory } from '@/models/api/v3/history/studyUnits'
import StudyUnitWebApi from '@/mixins/historyv3/StudyUnitWebApi'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'

@Component({
  components: {
    ButtonBase,
    SidebarSwitcher,
    StudyUnitInformation,
    StudentBottomPanel,
  },
})
export default class StudyUnit extends Mixins(StudyUnitWebApi, ClassModeChangeAble) {
  // curriculum_s_unit_id
  @Prop()
  id!: number

  private title = ''

  private get studentUserId() {
    return Vue.prototype.$cookies.get('authGdls').currentApiUserId
  }

  private studyUnitDatas: StudyHistory[] = []

  private async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.checkClassModeAndSideMenuMode()
    await this.loadStudyHistories()
    Vue.prototype.$loading.complete()
  }

  private async loadStudyHistories() {
    const modeCode = this.$route.query.modeCode as string
    const params = {
      userId: this.studentUserId,
      classModeCode: modeCode,
      curriculumSUnitId: this.id,
      classCategoryCode: 'RK,EN',
    }

    const res = await this.loadStudyHistoriesApi(params)

    this.title = res.title
    this.studyUnitDatas = res.studyHistories
  }

  private async movePrev() {
    const name = Array.isArray(this.$route.query.prevPage)
      ? this.$route.query.prevPage[0] || ''
      : this.$route.query.prevPage || ''
    if (name === 'CurriculumSUnitsV3') {
      this.$router.push({
        path: `/student/v3/curriculum-s-units/${this.$route.query.modeCode}/${this.$route.query.selectedSubject}`
      })
      return 
    }
    if (name === 'CurriculumSUnits') {
      this.$router.push({
        path: `/student/v2/curriculum-s-units/${this.$route.query.modeCode}/${this.$route.query.selectedSubject}`
      })
      return 
    }
    const targetQueryKeys = ['selectedSubject', 'modeCode']
    const targetQuery = {}
    for (const [key, value] of Object.entries(this.$route.query)) {
      if (targetQueryKeys.includes(key)) targetQuery[key] = value
    }
    const route = { name: name }
    if (Object.keys(targetQuery).length > 0) route['query'] = targetQuery
    this.$router.push(route)
  }
}
