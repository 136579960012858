
















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import { Debounce } from 'vue-debounce-decorator'
import { throttle } from 'lodash'

const BUTTON_WIDTH = 62 * 3
@Component({
  components: {
    ButtonBase,
  },
})
export default class TabsButton extends Vue {
  @Prop({ default: [] })
  data?: { id: string | number; text: string; color: string; colorActive: string }[]

  @Prop()
  onClick?: (item: { id: string | number; text: string }, oldItemId?: string | number) => void

  private currentTab: string | number | undefined = ''

  private isShowButtonScroll = { left: false, right: false }

  private handleResize = throttle(this.changeScreenSize, 500)

  private handleClick(item: { id: string | number; text: string }) {
    this.onClick?.(item, this.currentTab)
  }

  private getColor(color?: string, colorActive?: string) {
    if (!color)
      return {
        '--bg-color-tabButton': '#ddf0ff',
        '--bg-color-tabButtonActive': '#4b9ad9',
      }
    return {
      '--bg-color-tabButton': color,
      '--bg-color-tabButtonActive': colorActive || color,
    }
  }

  private handleScroll() {
    const container = this.$refs.tabsButtonRef as HTMLElement
    const atBeginning = container.scrollLeft === 0
    const atEnd = container.scrollLeft - container.scrollWidth + container.clientWidth

    if (atBeginning) {
      this.isShowButtonScroll = { ...this.isShowButtonScroll, right: true, left: false }
      return
    }

    if (atEnd < 1 && atEnd > -1) {
      this.isShowButtonScroll = { ...this.isShowButtonScroll, right: false, left: true }
      return
    }
    this.isShowButtonScroll = { ...this.isShowButtonScroll, right: true, left: true }
  }

  private handleClickToScroll(type: 'left' | 'right') {
    const container = this.$refs.tabsButtonRef as HTMLElement

    if (type === 'left' && container) {
      container.scrollTo({
        left: container.scrollLeft - BUTTON_WIDTH,
        behavior: 'smooth',
      })
    } else {
      container.scrollTo({
        left: container.scrollLeft + BUTTON_WIDTH,
        behavior: 'smooth',
      })
    }
  }

  public setCurrentTab(id: string | number) {
    this.currentTab = id
  }

  private handleCheckHasScroll() {
    const container = this.$refs.tabsButtonRef as HTMLElement
    const hasScrollbar = container.scrollWidth > container.clientWidth

    this.isShowButtonScroll = { ...this.isShowButtonScroll, right: hasScrollbar }
  }

  @Debounce(300)
  private changeScreenSize(): void {
    const container = this.$refs.tabsButtonRef as HTMLElement
    const hasScrollbar = container.scrollWidth > container.clientWidth

    if (hasScrollbar) {
      this.handleScroll()
      return
    }
    this.isShowButtonScroll = { ...this.isShowButtonScroll, right: false, left: false }
  }

  @Watch('data', { deep: true })
  private onDataChanged() {
    setTimeout(() => {
      this.handleCheckHasScroll()
    }, 2000)
  }

  private mounted() {
    this.handleCheckHasScroll()
    window.addEventListener('resize', this.handleResize)
  }

  private beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  }
}
