














































import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import StudentBottomPanel from '@/components/organisms/v2/StudentBottomPanel.vue'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import UnitInformationVictory from '@/components/organisms/v3/UnitInformationVictory.vue'
import InputWithSearch from '@/components/molecules/InputWithSearch.vue'
import { GRADE_OPTIONS } from '@/constants'
import VictoryBranch from '@/mixins/v3/VictoryBranch'
import PositionListUnit from '@/mixins/positionListUnit/PositionListUnit'

export interface UnitVictory {
  id: number
  name: string
  grade_name: string
  grade_code: string
  has_explanation_url: boolean
  has_summary_url: boolean
  has_print_url: boolean
  has_sentence_pattern_explanation_url: boolean
  is_studied: boolean
}

@Component({
  components: {
    SidebarSwitcher,
    ButtonBase,
    SelectBase,
    StudentBottomPanel,
    UnitInformationVictory,
    InputWithSearch,
  },
})
export default class VictoryUnits extends Mixins(ClassModeMethods, VictoryBranch, PositionListUnit) {
  private subjectNames = {
    su: '算数/数学',
    ei: '英語',
    ko: '国語',
    sh: '社会',
    ri: '理科',
    chi: '漢字カード',
  }

  private gradeSelectedCode =
    Vue.prototype.$cookies.get('dataGdls').curriculumSUnitsGrade ||
    Vue.prototype.$cookies.get('dataGdls').grade.code ||
    GRADE_OPTIONS[0].value

  private statusOptionDatas = [
    { text: 'すべて', value: 2 },
    { text: '未学習', value: 0 },
    { text: '学習済み', value: 1 },
  ]

  private get subjectName() {
    return this.$route.query.subjectName
  }

  private get subjectCode() {
    return this.$route.params.subjectCode
  }

  private subjectId = this.$route.query.subjectId

  private statusSelectedData = 2

  private inputUnitName = ''

  private unitDatas: any = []
  private unitDatasFiltered: UnitVictory[] = []

  private handleFilterUnit() {
    this.unitDatasFiltered = !this.unitDatas[this.gradeSelectedCode]
      ? []
      : this.unitDatas[this.gradeSelectedCode].filter(({ name, is_studied }: any) => {
          return (
            name.includes(this.inputUnitName) &&
            (this.statusSelectedData == 2 || this.statusSelectedData == Number(is_studied))
          )
        })
  }

  private async mounted(): Promise<void> {
    if (this.isVictoryBranch) {
      Vue.prototype.$loading.start()
      await this.loadDatas()
      this.scrollToPrePosition()
      Vue.prototype.$loading.complete()
    } else {
      this.$router.push('/student/v3/dashboard')
    }
  }

  private async loadDatas(): Promise<void> {
    await Vue.prototype.$http.httpWithToken
      .get('/v3/victory_units', {
        params: {
          subjectId: this.subjectId,
        },
      })
      .then((res: { data: { victory_units: UnitVictory[] } }) => {
        const { victory_units } = res.data
        this.unitDatas = victory_units.reduce((objectResult: any, unit: UnitVictory) => {
          if (!objectResult[unit.grade_code]) objectResult[unit.grade_code] = []
          objectResult[unit.grade_code].push(unit)
          return objectResult
        }, {})
        this.handleFilterUnit()
      })
      .catch((error: any) => {
        if (error.response.status !== 401) {
          this.$router.push('/error/error400')
          throw new Error(error)
        }
      })
  }

  @Watch('gradeSelectedCode')
  private async handleChangeGrade(newValue: string) {
    this.handleFilterUnit()
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie['curriculumSUnitsGrade'] = newValue
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  @Watch('statusSelectedData')
  private handleChangeStatus() {
    this.handleFilterUnit()
  }

  private gradeOptionDatas = GRADE_OPTIONS

  private get selectVictorySubjectUrl() {
    return '/student/v3/victory'
  }
}
