





















import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import StyleSubjectBackground from '@/components/atoms/StyleSubjectBackground.vue'
import LoadEnableSubjectsApi from '@/mixins/utils/LoadEnableSubjectsApi'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import VictoryBranch from '@/mixins/v3/VictoryBranch'

const CAI_ACADEMY = 'CAI'

type Subject = { text: string; code: string; id: number }
type SubjectResponse = { name: string; code: string; id: number; sort_num: number }

@Component({
  components: {
    SidebarSwitcher,
    ColoredBox,
    ButtonBase,
  },
})
export default class Victory extends Mixins(
  StyleSubjectBackground,
  LoadEnableSubjectsApi,
  ClassModeMethods,
  VictoryBranch
) {
  public async mounted(): Promise<void> {
    if (this.isVictoryBranch) {
      Vue.prototype.$loading.start()
      await this.resetCurriculumSUnitsGrade()
      await this.loadEnableSubjects()
      Vue.prototype.$loading.complete()
    } else {
      this.$router.push('/student/v3/dashboard')
    }
  }

  private subjectDatas: Subject[] = []

  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private classMode = this.$route.params.classMode

  @Watch('$route')
  async onChangeRoute(): Promise<void> {
    this.classMode = this.$route.params.classMode
  }

  private async selectSubject({ text, id, code }: Subject) {
    let url = `/student/v3/victory/units/${code}?subjectId=${id}&subjectName=${text}`
    if (code === 'kj') {
      url = `/student/v3/victory/units/page/kanji?subjectCode=${code}&subjectId=${id}&subjectName=${text}`
    }
    this.$router.push(url)
  }

  /**
   * サービス設定で有効な教科を取得し、教科プルダウンに反映
   */
  private async loadEnableSubjects() {
    const subjects = await Vue.prototype.$http.httpWithToken.get('/subjects', {
      params: { academy: CAI_ACADEMY },
    })
    let isHasLiteratureSubject = false
    const literatureSubjectCode = 'ko'
    this.subjectDatas = subjects.data.map((subject: SubjectResponse) => {
      if (subject.code === literatureSubjectCode) {
        isHasLiteratureSubject = true
      }
      return { text: subject.name, code: subject.code, id: subject.id }
    })

    if (isHasLiteratureSubject) {
      const literatureSubject: Subject = {
        id: 6,
        text: '漢字カード',
        code: 'kj',
      }
      this.subjectDatas.push(literatureSubject)
    }
  }

  /**
   * 項目リストの学年選択情報をリセットする
   */
  private async resetCurriculumSUnitsGrade() {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    delete cookie['curriculumSUnitsGrade']
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  private get classModeName() {
    return this.classModeText(this.classMode)
  }
}
