import { render, staticRenderFns } from "./SchoolSetting.vue?vue&type=template&id=55bffc4a&scoped=true&lang=pug&"
import script from "./SchoolSetting.vue?vue&type=script&lang=ts&"
export * from "./SchoolSetting.vue?vue&type=script&lang=ts&"
import style0 from "./SchoolSetting.vue?vue&type=style&index=0&id=55bffc4a&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55bffc4a",
  null
  
)

export default component.exports