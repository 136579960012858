
























import { Component, Vue, Ref, Mixins } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import TableBase from '@/components/atoms/TableBase1110.vue'
import ModalAdminJukuNameEdit from '@/components/organisms/ModalAdminJukuNameEdit.vue'
import ModalAdminJukuAdd from '@/components/organisms/ModalAdminJukuAdd.vue'
import LocalMoment from '@/components/atoms/LocalMoment.vue'

@Component({
  components: {
    ButtonBase,
    InputWithLabel,
    TableBase,
    ModalAdminJukuNameEdit,
    ModalAdminJukuAdd,
  },
})
export default class Customer extends Mixins(LocalMoment) {
  private isProcessing = false

  private jukuCode = ''

  private jukuName = ''

  private currentPage = 1

  private count = 0

  private customerTableBasePerPage = 30

  private customerTableBaseItems: object[] = []

  private customerTableBaseFields: object[] = [
    { key: 'jukuCode', label: '塾コード', thStyle: 'width: 200px' },
    { key: 'jukuName', label: '塾名', thStyle: 'width: 400px' },
    { key: 'adminList', label: '管理者一覧' },
    { key: 'download', label: 'ダウンロード' },
    { key: 'login', label: 'ログイン' },
    { key: 'delete', label: '' },
  ]

  private customerTableBaseButtons: string[] = ['adminList', 'download', 'login', 'delete']

  private customerTableBaseLinks: string[] = ['jukuName']

  @Ref()
  modalEdit!: ModalAdminJukuNameEdit

  @Ref()
  modalAdd!: ModalAdminJukuAdd

  private showJukuNameEdit(id: number): void {
    this.modalEdit.loadAcademy(id)
  }

  private showJukuAdd(): void {
    this.modalAdd.show()
  }

  /**
   * 塾一覧を取得する
   */
  private async loadAcademies() {
    if (this.isProcessing) return
    this.isProcessing = true

    const params = [
      `limit=${this.customerTableBasePerPage}`,
      `offset=${this.customerTableBasePerPage * (this.currentPage - 1)}`,
    ]
    if (this.jukuCode) params.push(`code=${this.jukuCode}`)
    if (this.jukuName) params.push(`name=${this.jukuName}`)
    await Vue.prototype.$http.httpWithToken
      .get(`/academies?${params.join('&')}`)
      .then((res: any) => {
        this.customerTableBaseItems = res.data.academies.map((academy: { id: number; code: string; name: string }) => {
          return {
            id: academy.id,
            jukuCode: academy.code,
            jukuName: { onclick: this.showJukuNameEdit, variable: academy.id, name: academy.name },
            adminList: [{ url: `/admin/${academy.id}/customer-list`, name: '一覧' }],
            download: [
              { onclick: this.downloadPdf, variable: { id: academy.id, code: academy.code }, name: 'ダウンロード' },
            ],
            login: [{ onclick: this.proxyLogin, variable: { id: academy.id, code: academy.code }, name: 'ログイン' }],
            delete: [{ onclick: this.destroyAcademy, variable: academy.id, name: '削除' }],
          }
        })
        this.count = res.data.count
      })
      .finally(() => {
        this.isProcessing = false
      })
  }

  /**
   * PDFをダウンロードしてGDLSコードを表示する
   */
  private async downloadPdf(academy: { id: number; code: string }) {
    if (this.isProcessing) return
    this.isProcessing = true

    await Vue.prototype.$http.httpWithToken
      .get(`/academies/${academy.id}/users`, {
        responseType: 'blob',
        dataType: 'binary',
        headers: {
          Accept: 'application/pdf',
        },
      })
      .then((res: any) => {
        const blob = new Blob([res.data], { type: 'application/pdf' })
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(blob)
        a.download = `${academy.code}_${this.today().format('YYYYMMDDHHmmss')}.pdf`
        a.click()
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        } else if (error.response.data.status === 422) {
          alert('塾管理者が見つかりませんでした。')
        }
      })
      .finally(() => {
        this.isProcessing = false
      })
  }

  /**
   * 塾管理者代理ログイン
   */
  private async proxyLogin({ id, code }: { id: number; code: string }) {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie['academyId'] = id
    cookie['academyCode'] = code
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())

    this.$router.push('/academy/branch')
  }

  /**
   * 塾を削除する
   */
  private async destroyAcademy(id: number) {
    if (this.isProcessing) return
    this.isProcessing = true

    if (!confirm('削除しますか？')) {
      this.isProcessing = false
      return
    }

    await Vue.prototype.$http.httpWithToken
      .delete(`/academies/${id}`)
      .then(() => {
        alert('塾の削除が完了しました')
        // 塾を削除したことで現在のページの塾がなくなる場合は1ページ前を表示する
        if (this.customerTableBaseItems.length === 1 && this.currentPage > 1) this.currentPage = this.currentPage - 1
        this.isProcessing = false
        this.loadAcademies()
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        } else if (error.response.data.status === 422) {
          alert('教室が存在しているため削除できません。')
        }
        this.isProcessing = false
      })
  }

  /**
   * ページネーション
   */
  private paginate(page: number): void {
    this.currentPage = page
    this.loadAcademies()
  }

  private async mounted() {
    this.loadAcademies()
  }
}
