


















import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class DrillPenIconMath extends Vue {

    @Prop()
    props!: Partial<any>

    @Prop()
    onClick!: () => void


    private handleClick() {
        this.onClick?.()
    }
}
