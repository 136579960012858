











import { Component, Mixins, Vue } from 'vue-property-decorator'
import Title from '@/components/modules/drillsv3/atoms/Title.vue'
import ResultGuide from '@/components/modules/drillsv3/atoms/ResultGuide.vue'
import Correct from '@/components/modules/drillsv3/organisms/Correct.vue'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import StudentBottomPanel from '@/components/organisms/v3/StudentBottomPanel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

import { PAGE_TYPE } from '@/store/modules/DrillsV3'

import DrillTransition from '@/mixins/drillsv3/DrillTransition'
import DrillWebApi from '@/mixins/drillsv3/WebApi'
import DrillHistoryApi from '@/mixins/drillsv3/HistoryApi'

@Component({
  components: {
    Title,
    ResultGuide,
    Correct,
    SidebarSwitcher,
    StudentBottomPanel,
    ButtonBase,
  },
})
export default class DrillResult extends Mixins(DrillWebApi, DrillHistoryApi, DrillTransition) {
  // 読み込み完了フラグ
  private loadComplete = false

  protected async mounted(): Promise<any> {
    Vue.prototype.$loading.start()

    Vue.prototype.$logger.info('-- DrillResult V3 mounted')

    // 読み込み完了フラグを未完了に変更
    this.$store.commit('drillsV3/setProcessed', false)

    // ページタイプ設定
    this.$store.commit('drillsV3/setPageType', PAGE_TYPE.RESULT_ALL)

    const resultDrill = await this.getResultDrill(this.resultDrillId)

    // 授業モードと授業種別を保持
    this.$store.commit('drillsV3/setClassModeCode', resultDrill.classModeCode)
    this.$store.commit('drillsV3/setClassCategoryCode', resultDrill.classCategoryCode)

    // 表示用の情報を取得し、ストアに保持
    const sProblemIds = resultDrill.resultDrillPages.map((resultDrillPage) => {
      return resultDrillPage.problems.map((problem) => {
        return problem.sProblemId
      })
    })
    const problems = await this.getProblems(sProblemIds)
    await this.$store.dispatch('drillsV3/setProblems', problems)

    // 正誤情報をストアに保持
    const resultProblems = resultDrill.resultDrillPages.map((resultDrillPage) => {
      return resultDrillPage.problems
    })
    await this.$store.dispatch('drillsV3/setProblemCorrects', resultProblems)
    await this.$store.dispatch('drillsV3/setNumberProblemCorrects', resultDrill.resultDrillPages)
    // 動画視聴状況を登録
    await this.$store.dispatch('drillsV3/setMoviePlayed', resultProblems)

    // その他学習結果情報をストアに保持
    await this.$store.dispatch('drillsV3/setResultDrill', resultDrill)

    // 読み込み完了フラグを設定
    this.$store.commit('drillsV3/setProcessed', true)
    this.loadComplete = true

    Vue.prototype.$loading.complete()
  }

  private async movePrev() {
    const name = Array.isArray(this.$route.query.prevPage)
      ? this.$route.query.prevPage[0] || ''
      : this.$route.query.prevPage || ''
    const id = Array.isArray(this.$route.query.prevId)
      ? this.$route.query.prevId[0] || ''
      : this.$route.query.prevId || ''
    const prevPage = Array.isArray(this.$route.query.beforePage)
      ? this.$route.query.beforePage[0] || ''
      : this.$route.query.beforePage || ''
    const targetQueryKeys = ['selectedSubject', 'modeCode']
    const targetQuery = {}
    for (const [key, value] of Object.entries(this.$route.query)) {
      if (targetQueryKeys.includes(key)) targetQuery[key] = value
    }
    if (prevPage) targetQuery['prevPage'] = prevPage
    const route = { name: name }
    if (id) {
      route['params'] = { id: id }
    }
    if (Object.keys(targetQuery).length > 0) route['query'] = targetQuery
    this.$router.push(route)
  }
}
