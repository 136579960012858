





















































import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import UnitInformation from '@/components/organisms/v2/UnitInformation.vue'
import StudentBottomPanel from '@/components/organisms/v2/StudentBottomPanel.vue'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import UnitInformationRemoveBackground from '@/components/organisms/v3/UnitInformationRemoveBackground.vue'
import InputWithSearch from '@/components/molecules/InputWithSearch.vue'
import ContinueLearn from '@/components/molecules/v3/ContinueLearn.vue'
import PositionListUnit from '@/mixins/positionListUnit/PositionListUnit'
import TabBase from '@/components/atoms/v3/TabBase.vue'

export type Unit = {
  id: number
  name: string
  isCompleted: boolean
}

@Component({
  components: {
    SidebarSwitcher,
    ButtonBase,
    SelectBase,
    UnitInformation,
    StudentBottomPanel,
    UnitInformationRemoveBackground,
    InputWithSearch,
    ContinueLearn,
    TabBase,
  },
})
export default class CurriculumSUnits extends Mixins(ClassModeMethods, PositionListUnit) {
  private get unitComponent() {
    return this.isV3 ? 'UnitInformationRemoveBackground' : 'UnitInformation'
  }

  private tabs: { link: string; name: string }[] = [
    {
      link: `/student/v2/curriculum-s-units/${this.$route.params.classMode}/${this.$route.params.subjectCode}`,
      name: '間違い直し',
    },
    ...(this.isShowOcrLlmService && this.$route.params.classMode === 'MN' ? [{
      link: `/student/v3/bookmark/${this.$route.params.classMode}/${this.$route.params.subjectCode}`,
      name: 'ブックマーク',
    }] : [])
  ]

  private gradeOptionDatas = [
    { text: '小1', value: 's1' },
    { text: '小2', value: 's2' },
    { text: '小3', value: 's3' },
    { text: '小4', value: 's4' },
    { text: '小5', value: 's5' },
    { text: '小6', value: 's6' },
    { text: '中1', value: 't1' },
    { text: '中2', value: 't2' },
    { text: '中3', value: 't3' },
  ]

  private subjectNames = {
    su: '算数/数学',
    ei: '英語',
    ko: '国語',
    sh: '社会',
    ri: '理科',
  }

  private gradeSelectedCode =
    Vue.prototype.$cookies.get('dataGdls').curriculumSUnitsGrade || Vue.prototype.$cookies.get('dataGdls').grade.code

  private statusOptionDatas = [
    { text: 'すべて', value: '0' },
    { text: '未学習', value: '1' },
    { text: '学習済み', value: '2' },
  ]

  private statusSelectedData = '0'

  private inputCurriculumSUnitName?: string

  private dataPrediction = {}

  private unitDatas: Unit[] = []

  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId

  private classMode = this.$route.params.classMode
  private subjectCode = this.$route.params.subjectCode
  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3() && this.classMode === 'AI'

  private async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    this.isV3 ? await this.loadDatasV3() : await this.loadDatas()
    this.scrollToPrePosition()
    Vue.prototype.$loading.complete()
  }

  private async loadDatas(): Promise<void> {
    await Vue.prototype.$http.httpWithToken
      .get('/v2/curriculumSUnits', {
        params: {
          studyLogicType: this.classMode,
          userId: this.userId,
          subjectCode: this.subjectCode,
          gradeCode: this.gradeSelectedCode,
          status: this.statusSelectedData,
          curriculumSUnitName: this.inputCurriculumSUnitName,
        },
      })
      .then((res: any) => {
        this.unitDatas = res.data
      })
      .catch((error: any) => {
        if (error.response.status !== 401) {
          this.$router.push('/error/error400')
          throw new Error(error)
        }
      })
  }

  private async loadDatasV3(): Promise<void> {
    if (Object.keys(this.dataPrediction).length) {
      this.unitDatas = this.dataPrediction[this.gradeSelectedCode]
        ? this.dataPrediction[this.gradeSelectedCode]
          .filter((unit: any) => {
            if (this.statusSelectedData == '0') return true
            return this.statusSelectedData == '1' ? !unit.isCompleted : unit.isCompleted
          })
          .filter((unit: any) => unit.curriculumSUnitName.includes(this.inputCurriculumSUnitName))
        : []
      return
    }
    await Vue.prototype.$http.httpWithToken
      .get('/v2/history/subjectResult/curriculums', {
        params: {
          userId: this.userId,
          subjectCode: this.subjectCode,
        },
      })
      .then((res: any) => {
        const { curriculums } = res.data
        this.dataPrediction = curriculums.reduce((objectResult: any, item: any) => {
          objectResult[item.gradeCode] = item.curriculumSUnits.map((unit: any) => {
            return {
              ...unit,
              curriculumSUnitId: unit.sUnitId,
              curriculumSUnitName: unit.sUnitName,
            }
          })
          return objectResult
        }, {})
        this.unitDatas = this.dataPrediction[this.gradeSelectedCode]
      })
      .catch((error: any) => {
        console.log(error)
        if (error.response.status !== 401) {
          this.$router.push('/error/error400')
          throw new Error(error)
        }
      })
  }

  /**
   * クレジットリンク表示ステータス
   */
  private get isShowCreditLink() {
    return this.subjectCode == 'sh'
  }

  private get selectSubjectUrl() {
    return `/student/v2/subjects/${this.classMode}`
  }

  private get classModeName() {
    return this.classModeText(this.classMode)
  }

  private get isAiMode(): boolean {
    return this.classMode == this.CLASS_MODE.AI
  }

  @Watch('gradeSelectedCode')
  private async gradeSelectedCodeChange(newValue: string, oldValue: string): Promise<void> {
    this.inputCurriculumSUnitName = ''
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie['curriculumSUnitsGrade'] = newValue
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
    // 初回は除く
    if (oldValue) await this.changeConditions()
  }

  @Watch('statusSelectedData')
  private async statusSelectedDataChange(): Promise<void> {
    this.inputCurriculumSUnitName = ''
    await this.changeConditions()
  }

  private async changeConditions(): Promise<void> {
    Vue.prototype.$loading.start()
    this.isV3 ? await this.loadDatasV3() : await this.loadDatas()
    Vue.prototype.$loading.complete()
  }

  protected get isShowOcrLlmService(): string {
    return Vue.prototype.$cookies.get('dataGdls')?.showOcrLlmService
  }
}
