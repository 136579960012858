





























































import { Component, Ref, Mixins, Vue } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ModalStudentAdd from '@/components/organisms/v3/ModalStudentAdd.vue'
import StudentInModel from '@/components/organisms/v3/StudentInModel.vue'
import { GetStudentsResponseType } from '@/models/api/students'
import LoadStudentsApi from '@/mixins/v3/LoadStudentsApi'
import GlinkBranchControlable from '@/mixins/teacher/GlinkBranchControlable'
import QueryMethods from '@/components/atoms/QueryMethods.vue'
import _ from 'lodash'
import { TEACHER_SETTING_TEXT } from '@/constants'

export interface Mode {
  id: number
  name: string
  code: string
  description: string
}

export interface TableBase {
  id: { value: number; checked: boolean }
  image: string
  name: { name: string; disabled: boolean }
  gdlsCode: { name: string }[]
  school: string
  grade: string
  studentId: number
  attribute: string[]
  status: { variable: number; name: string }[]
  condition: { variable: number; name: string }[]
}

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    InputWithLabel,
    ButtonBase,
    ModalStudentAdd,
    StudentInModel,
  },
})
export default class ModeStudent extends Mixins(GlinkBranchControlable, LoadStudentsApi, QueryMethods) {
  private breadcrumbs = [
    { text: TEACHER_SETTING_TEXT, href: '/teacher/setting/top' },
    { text: 'モード割り当て', active: true },
  ]
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  @Ref() modalAddStudent!: ModalStudentAdd

  private listMode: Mode[] = []

  private tableBaseCheckboxs = ['id']

  private teacherTableBaseLinks = ['name']

  private tableMultipleLines = ['school']

  private teacherTableBaseImages = ['image']

  private tableBaseFields = [
    { key: 'id', label: '' },
    { key: 'image', label: '' },
    { key: 'name', label: '' },
    { key: 'school', label: '' },
  ]

  private checkAll = false
  private modeName = ''
  private searchCount = 0
  private tableBaseItems: TableBase[] = []

  private modeActive: Mode = {
    id: 0,
    name: '',
    code: '',
    description: '',
  }
  private schoolOptions: { text: string; value: number }[] = []
  private gradeOptions: { text: string; value: number }[] = []
  private attributeOptions: { text: string; value: number }[] = []
  private listStudentInMode: TableBase[] = []
  private listStudentAlreadyInMode: number[] = []

  private searchMode() {
    Vue.prototype.$http.httpWithToken
      .get(`/v3/class_modes`, { params: { name: this.modeName, limit: 200, branchId: this.branchId } })
      .then((res: any) => {
        this.listMode = this.filterToRemoveOcrClassMode(res.data.class_modes)
      })
  }

  private async mounted() {
    Vue.prototype.$loading.start()
    await this.loadModes()
    await this.loadStudentDatas({})
    await this.loadSelectDatas()
    Vue.prototype.$loading.complete()
  }

  // プルダウン用情報読み込み
  private async loadSelectDatas() {
    this.loadGrades()
    this.loadSchools()
    this.loadAttributes()
  }

  // 学年情報読み込み
  private async loadModes() {
    Vue.prototype.$http.httpWithToken
      .get(`/v3/class_modes`, { params: { page: 1, limit: 200, branchId: this.branchId } })
      .then((res: any) => {
        this.listMode = this.filterToRemoveOcrClassMode(res.data.class_modes)
      })
  }

  // 学年情報読み込み
  private async loadGrades() {
    Vue.prototype.$http.httpWithToken.get(`/grades`).then((res: any) => {
      this.gradeOptions = res.data.map((grade: { id: number; name: string; code: string; sortNum: string }) => {
        return {
          text: grade.name,
          value: grade.id,
        }
      })
    })
  }

  // 学校情報読み込み
  private async loadSchools() {
    Vue.prototype.$http.httpWithToken.get(`/schools`, { params: { branchId: this.branchId } }).then((res: any) => {
      this.schoolOptions = res.data.schools.map((school: { id: number; name: string }) => {
        return {
          text: school.name,
          value: school.id,
        }
      })
    })
  }
  // 属性情報読み込み
  private async loadAttributes() {
    // 属性プルダウンの情報設定
    Vue.prototype.$http.httpWithToken
      .get(`/v3/student_groups`, { params: { branchId: this.branchId, withLinked: true } })
      .then((res: any) => {
        this.attributeOptions = res.data.studentGroups.map((userAttributes: { id: number; title: string }) => {
          return {
            text: userAttributes.title,
            value: userAttributes.id,
          }
        })
      })
  }

  private searchStudent(event: object): void {
    this.loadStudentDatas(event)
  }

  private saveStudent(): void {
    const listStudentChecked = this.tableBaseItems.filter((item) => item.id.checked)
    if (listStudentChecked.length == 0) {
      alert('対象の生徒を選択してください。')
      return
    } else {
      this.listStudentInMode = listStudentChecked
    }
    this.hideStudentModal()
  }

  private hideStudentModal(): void {
    this.modalAddStudent.hide()
  }

  private async showStudentModal() {
    !_.isEmpty(this.modeActive) ? this.modalAddStudent.show() : true
    const listIdStudent: any = []
    this.tableBaseItems = []
    await this.loadStudentDatas({})
    if (this.listStudentInMode.length > 0) {
      this.listStudentInMode.map((item) => {
        listIdStudent.push(item.id.value)
      })
      let checkAll = 0
      this.tableBaseItems.forEach((item, index) => {
        if (listIdStudent.includes(item.id.value)) {
          this.tableBaseItems[index]['id']['checked'] = true
          checkAll++
        }
      })
      if (checkAll == this.tableBaseItems.length) {
        this.checkAll = true
      } else {
        this.checkAll = false
      }
    }
  }

  private getSearchParams(search: any) {
    const params = this.getSearchParamsBase(this.branchId)
    if (search.nickname) {
      params['name'] = search.nickname
    }
    if (search.studentCode) {
      params['studentCode'] = search.studentCode
    }
    if (search.gradeSelectedData) {
      params['gradeId'] = search.gradeSelectedData
    }
    if (search.schoolSelectedData) {
      params['schoolId'] = search.schoolSelectedData
    }
    if (search.attributeSelectedData) {
      params['userAttributeId'] = search.attributeSelectedData
    }
    return params
  }

  private async selectOptionMode(option: Mode): Promise<void> {
    this.modeActive = option
    await Vue.prototype.$http.httpWithToken
      .get(`/v3/class_mode_students`, { params: { classMode: this.modeActive.code, limit: 200 } })
      .then((res: any) => {
        this.listStudentInMode = []
        this.listStudentAlreadyInMode = []
        if (res.data.students.length > 0) {
          const listIdStudent: number[] = []
          res.data.students.map((item: { studentId: any }) => {
            listIdStudent.push(item.studentId)
            this.listStudentAlreadyInMode.push(item.studentId)
          })
          this.tableBaseItems.forEach((item, index) => {
            if (listIdStudent.includes(item.studentId)) {
              this.tableBaseItems[index]['id']['checked'] = true
              this.listStudentInMode.push(item)
            } else {
              this.tableBaseItems[index]['id']['checked'] = false
            }
          })
        } else {
          this.tableBaseItems.forEach((item, index) => {
            this.tableBaseItems[index]['id']['checked'] = false
          })
        }
      })
  }

  private async removeStudentInMode(index: number): Promise<void> {
    const student = this.listStudentInMode[index]
    this.listStudentInMode.splice(index, 1)
    this.tableBaseItems.forEach((item, index) => {
      if (item.studentId == student.studentId) {
        this.tableBaseItems[index]['id']['checked'] = false
      }
    })
    this.listStudentAlreadyInMode = this.listStudentAlreadyInMode.filter((item) => item !== student.studentId)
    this.$bvToast.toast(`生徒を削除しました`, {
      title: '通知する',
      autoHideDelay: 3000,
      variant: 'success',
      solid: true,
    })
    await Vue.prototype.$http.httpWithToken.delete(
      `/v3/class_mode_students?classMode=${this.modeActive.code}&studentIds[]=${student.studentId}`
    )
  }
  private async addStudent() {
    const listIdStudent: number[] = []
    if (this.listStudentInMode.length > 0) {
      this.listStudentInMode.map((item) => {
        if (!this.listStudentAlreadyInMode.includes(item.studentId)) {
          listIdStudent.push(item.studentId)
        }
      })
      if (listIdStudent.length > 0) {
        await Vue.prototype.$http.httpWithToken
          .post(`/v3/class_mode_students`, { classMode: this.modeActive.code, studentIds: listIdStudent })
          .then(() => {
            this.listStudentAlreadyInMode.push(...listIdStudent)
            this.$bvToast.toast(`モードに生徒が追加されました。`, {
              title: '通知する',
              autoHideDelay: 3000,
              variant: 'success',
              solid: true,
            })
          })
          .catch((error: any) => {
            if (error.response.data.status === 400) {
              alert(error.response.data.message)
            } else if (error.response.data.status === 409) {
              alert('教室コードもしくは教室名が重複したものが申請中か、既に登録されています。')
            }
          })
      }
    }
  }

  private async loadStudentDatas(paramsSearch: object) {
    // 固定パラメータ設定
    const params = this.getSearchParams(paramsSearch)
    // 生徒情報を取得
    const data: GetStudentsResponseType = await this.loadStudents(params)
    this.searchCount = data.count
    this.tableBaseItems = data.students.map((student) => {
      return {
        id: {
          value: student.id,
          checked: false,
        },
        studentId: student.studentId,
        studentCode: student.studentCode,
        name: {
          variable: student,
          name: `${student.nickname}`,
          disabled: this.isGlinkBranch,
          variant: 'danger',
        },
        gdlsCode: [{ variable: student, name: '発行' }],
        school: student.schoolName,
        grade: student.gradeName,
        attribute: [student.userAttributeTitle ? student.userAttributeTitle : '－'].concat(
          student.linkedAttributeTitles || []
        ),
        status: [{ variable: student.id, name: '確認' }],
        condition: [{ variable: student.id, name: '確認' }],
        image: '',
      }
    })
  }

  private filterToRemoveOcrClassMode(classModes?: Mode[]) {
    return (classModes || []).filter(item => item.code !== 'ocr_llm')
  }
}
