
import { Component, Vue } from 'vue-property-decorator'

// mixinして使用する
@Component
export default class StyleSubjectBackground extends Vue {
  protected getSubjectStyle(subjectCode: string): {} {
    let backgroundImage = 'none'
    let backgroundColor = 'var(--color-white)'
    let backgroundLightColor = 'var(--color-white)'
    let subjectIcon = 'none'
    let linearGradient = 'var(--color-gray-3)'
    switch (subjectCode) {
      case 'su':
        backgroundImage = 'url("/img/back_graphic_sugaku.png")'
        backgroundColor = 'var(--color-subject-su)'
        backgroundLightColor = 'var(--color-subject-light-su)'
        subjectIcon = 'url("/img/icon_kyouka/icon_kyouka_sugaku.png")'
        linearGradient = 'linear-gradient(to right, var(--color-subject-gradient-su), var(--color-subject-su))'
        break
      case 'ko':
        backgroundImage = 'url("/img/back_graphic_kokugo.png")'
        backgroundColor = 'var(--color-subject-ko)'
        backgroundLightColor = 'var(--color-subject-light-ko)'
        subjectIcon = 'url("/img/icon_kyouka/icon_kyouka_kokugo.png")'
        linearGradient = 'linear-gradient(to right, var(--color-subject-gradient-ko), var(--color-subject-ko))'
        break
      case 'ei':
        backgroundImage = 'url("/img/back_graphic_eigo.png")'
        backgroundColor = 'var(--color-subject-ei)'
        backgroundLightColor = 'var(--color-subject-light-ei)'
        subjectIcon = 'url("/img/icon_kyouka/icon_kyouka_eigo.png")'
        linearGradient = 'linear-gradient(to right, var(--color-subject-gradient-ei), var(--color-subject-ei))'
        break
      case 'ri':
        backgroundImage = 'url("/img/back_graphic_rika.png")'
        backgroundColor = 'var(--color-subject-ri)'
        backgroundLightColor = 'var(--color-subject-light-ri)'
        subjectIcon = 'url("/img/icon_kyouka/icon_kyouka_rika.png")'
        linearGradient = 'linear-gradient(to right, var(--color-subject-gradient-ri), var(--color-subject-ri))'
        break
      case 'sh':
        backgroundImage = 'url("/img/back_graphic_syakai.png")'
        backgroundColor = 'var(--color-subject-sh)'
        backgroundLightColor = 'var(--color-subject-light-sh)'
        subjectIcon = 'url("/img/icon_kyouka/icon_kyouka_syakai.png")'
        linearGradient = 'linear-gradient(to right, var(--color-subject-gradient-sh), var(--color-subject-sh))'
        break
      case 'kj':
        backgroundImage = 'url("/img/back_graphic_rika.png")'
        backgroundColor = 'var(--color-subject-kj)'
        backgroundLightColor = 'var(--color-subject-light-ri)'
        subjectIcon = 'url("/img/icon_kyouka/icon_kyouka_kanji.png")'
        linearGradient = 'linear-gradient(to right, var(--color-subject-gradient-ri), var(--color-subject-kj))'
        break
    }
    return {
      '--subject-bg-image': backgroundImage,
      '--subject-bg-color': backgroundColor,
      '--subject-bg-color-light': backgroundLightColor,
      '--subject-icon-image': subjectIcon,
      '--subject-linear-gradiant': linearGradient,
    }
  }
}
