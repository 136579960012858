






































































import { Component, Mixins, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import TableBase from '@/components/atoms/TableBase.vue'

type ServiceCode = 'gdls' | 'gakken' | 'nyushi' | 'calendar_and_grade_book' | 'encouragement_text' | 'victory' | 'ocr_llm'

@Component({
  components: {
    ModalBase,
    TableBase,
  },
})
export default class ModalAcademyRequestService extends Mixins(ModalBaseMethod) {
  private DEFAULT_FIELDS = [{ key: 'name', label: 'サービス名' }]
  private requestServiceFields: object[] = this.DEFAULT_FIELDS
  private requestServiceDatas: object[] = []
  private done = false

  private onOffCalendar = false
  private onOffGpt = false
  private onOffOcrLLM = false

  public async loadRequestService(id: number) {
    if (this.done) {
      this.show()
      return
    }

    await Vue.prototype.$http.httpWithToken
      .get(`/requests/${id}}`)
      .then(({ data }: any) => {
        // フィールド情報生成
        data.services[0].subjects.forEach(({ code, name }: { code: string; name: string }) => {
          this.requestServiceFields.push({ key: code, label: name })
        })
        // データ情報生成
        data.services.forEach(
          ({ name, subjects, code: serviceCode }: { name: string; subjects: any[]; code: ServiceCode }) => {
            const data = { name }
            if (serviceCode === 'calendar_and_grade_book') {
              this.onOffCalendar = subjects[0]?.isEnabled === '1'
            } else if (serviceCode === 'encouragement_text') {
              this.onOffGpt = subjects[0]?.isEnabled === '1'
            } else if (serviceCode === 'ocr_llm') {
              this.onOffOcrLLM = subjects[0]?.isEnabled === '1'
            } else {
              subjects.forEach(({ code, isEnabled }: { code: string; isEnabled: string }) => {
                data[code] = isEnabled === '1'
              })
              this.requestServiceDatas.push(data)
            }
          }
        )
        this.show()
        this.done = true
      })
      .catch((error: any) => {
        this.requestServiceFields = this.DEFAULT_FIELDS
        this.requestServiceDatas = []
        alert('エラーが発生しました')
      })
  }
}
