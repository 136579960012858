

















import { Component, Mixins, Vue } from 'vue-property-decorator'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import LoginMethods from '@/components/organisms/LoginMethods.vue'

@Component({
  components: {
    InputWithLabel,
    ButtonBase,
  },
})
export default class SignIn1 extends Mixins(LoginMethods, Vue) {
  private gdlsCode = ''

  private created() {
    this.mode = this.MODE.TEACHER
  }

  private async submitLogin() {
    this.login(this.gdlsCode)
  }
  private mounted() {
    const isV3 = window.location.pathname === '/teacher/g-method/login'
    const isToolPage = window.location.pathname === '/teacher/tool/login'
    Vue.prototype.$gdlsCookiesV3.setV3(isV3 || isToolPage)
    Vue.prototype.$gdlsCookiesV3.setToolPage(isToolPage)
  }
}
