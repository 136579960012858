





import { Component, Mixins, Vue } from 'vue-property-decorator'

import QuestionImageHorizontal from '@/components/modules/drillsv3/organisms/QuestionImageHorizontal.vue'
import QuestionImageVertical from '@/components/modules/drillsv3/organisms/QuestionImageVertical.vue'
import Transition from '@/components/modules/drillsv3/organisms/Transition.vue'
import TitleWithPenSwitcher from '@/components/modules/drillsv3/molecules/TitleWithPenSwitcher.vue'
import { PAGE_TYPE } from '@/store/modules/DrillsV3'

import DrillsConfig from '@/mixins/drillsv3/Config'
import DrillTransition from '@/mixins/drillsv3/DrillTransition'
import DrillWebApi from '@/mixins/drillsv3/WebApi'

@Component({
  components: {
    QuestionImageHorizontal,
    QuestionImageVertical,
    Transition,
    TitleWithPenSwitcher,
  },
})
export default class Drill extends Mixins(DrillWebApi, DrillTransition) {
  // 読み込み完了フラグ
  private loadComplete = false

  private drillModules = DrillsConfig.drillModules

  private subjectCode = ''

  private get drillModulesByCode() {
    return this.subjectCode === 'ko' ? this.drillModules.SELF_VR : this.drillModules.SELF_HR
  }
  protected async mounted(): Promise<any> {
    Vue.prototype.$loading.start()
    Vue.prototype.$logger.info('-- Drill V3 mounted')

    // 読み込み完了フラグを未完了に変更
    this.$store.commit('drillsV3/setProcessed', false)


    // 演習対象の問題を取得
    const studyProblem = await this.getStudyProblems(this.resultDrillId)
    this.$store.commit('drills/setEndCondition', studyProblem.endConditions)
    this.$store.commit('drillsV3/setCurrentPage', studyProblem.currentPage)
    if (studyProblem.endConditions.left == 0) {
      await this.completeDrillApi(this.resultDrillId)
      Vue.prototype.$loading.complete()
      this.$router.push({
        path: this.urlResultAll,
      })
      return
    }
    Vue.prototype.$loading.complete()
    if (!studyProblem.sProblemIds?.length) {
      // 出題対象が存在しない場合は結果画面に遷移
      Vue.prototype.$loading.complete()
      this.$router.push({
        path: this.urlResultAll,
      })
      return
    }
    // ページタイプ設定
    this.$store.commit('drillsV3/setPageType', PAGE_TYPE.DRILL)
    // 授業モードと授業種別を保持
    this.$store.commit('drillsV3/setClassModeCode', studyProblem.classModeCode)
    this.$store.commit('drillsV3/setClassCategoryCode', studyProblem.classCategoryCode)

    // 現在ページをストアに保持
    this.$store.commit('drillsV3/setCurrentPage', studyProblem.currentPage)

    // 表示用の情報を取得し、ストアに保持
    const problems = await this.getProblems([studyProblem.sProblemIds])
    await this.$store.dispatch('drillsV3/setProblems', problems)

    this.subjectCode = this.$store.getters['drillsV3/subjectCode']

    // 読み込み完了フラグを設定
    this.$store.commit('drillsV3/setProcessed', true)
    this.loadComplete = true

    Vue.prototype.$loading.complete()
  }

}
