


























import { Component, Vue, Ref, Mixins } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ListItemBoxSchool from '@/components/organisms/ListItemBoxSchool.vue'
import ModalTeacherInput, { ModalParams } from '@/components/organisms/ModalTeacherInput.vue'
import GlinkBranchControlable from '@/mixins/teacher/GlinkBranchControlable'
import { TEACHER_SETTING_TEXT } from '@/constants'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    ColoredBox,
    ButtonBase,
    ListItemBoxSchool,
    ModalTeacherInput,
  },
})
export default class School extends Mixins(GlinkBranchControlable) {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
  private breadcrumbs = [
    { text: this.isV3 ? TEACHER_SETTING_TEXT : '設定', href: '/teacher/setting/top' },
    { text: '学校設定', active: true },
  ]

  private schoolDatas: { id: number; name: string }[] = []

  @Ref() modalTeacherInput!: ModalTeacherInput

  private modalInfo: ModalParams = {}

  private showModalInputAdd(): void {
    this.modalInfo = {
      title: '学校追加',
      buttonName: '追加',
      value: '',
      onClick: this.create,
    }
    this.modalTeacherInput.show()
  }

  private showModalInputEdit(school: { id: number; name: string }): void {
    this.modalInfo = {
      id: school.id,
      title: '学校編集',
      buttonName: '保存',
      value: school.name,
      onClick: this.update,
    }
    this.modalTeacherInput.show()
  }

  private async mounted() {
    Vue.prototype.$loading.start()
    await this.isGlinkBranchApi(this.branchId)
    await this.loadSchools()
    Vue.prototype.$loading.complete()
  }

  /**
   * 学校毎に表示するボタンパラメータを返却
   */
  private listBtnParams(id: number): { url: string; label: string }[] {
    return [
      { url: `/teacher/setting/school-curriculum/${id}`, label: '教科書・カリキュラム' },
    ]
  }

  /**
   * 学校一覧取得
   */
  private async loadSchools() {
    await Vue.prototype.$http.httpWithToken.get(`/schools?branchId=${this.branchId}`).then((res: any) => {
      this.schoolDatas = res.data.schools as { id: number; name: string }[]
    })
  }

  /**
   * 学校作成
   */
  private async create(): Promise<void> {
    this.modalInfo = { ...this.modalInfo, loading: true }
    await Vue.prototype.$http.httpWithToken
      .post('/schools', { branchId: this.branchId, name: this.modalInfo.value })
      .then(() => {
        this.modalTeacherInput.hide()
        this.loadSchools()
      })
      .catch((error: any) => {
        if (error.response.data.status === 409) {
          alert('学校名が重複しているため登録できません。')
        }
      })
      .finally(() => {
        this.modalInfo = { ...this.modalInfo, loading: false }
      })
  }

  /**
   * 学校名変更
   */
  private async update(): Promise<void> {
    await Vue.prototype.$http.httpWithToken
      .put(`/schools/${this.modalInfo.id}`, { name: this.modalInfo.value })
      .then(() => {
        this.modalTeacherInput.hide()
        this.loadSchools()
      })
      .catch((error: any) => {
        if (error.response.data.status === 409) {
          alert('学校名が重複しているため登録できません。')
        } else if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
  }

  /**
   * 学校削除
   */
  private async destroy(id: number): Promise<void> {
    if (!confirm('削除しますか？')) return

    await Vue.prototype.$http.httpWithToken
      .delete(`/schools/${id}`)
      .then(() => {
        this.loadSchools()
      })
      .catch((error: any) => {
        if (error.response.data.status === 422) {
          alert('生徒が存在しているため削除できません。')
        } else if (error.response.data.status === 409) {
          alert('生徒の登録予約情報が存在しているため削除できません。')
        } else if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
  }
}
