


































































import { Component, Mixins, Ref, Vue, Watch } from 'vue-property-decorator'
import StudentHistorySidebar from '@/components/organisms/StudentHistorySidebar.vue'
import TeacherHistoryHeader from '@/components/organisms/TeacherHistoryHeader.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import SelectSubject from '@/components/atoms/SelectSubject.vue'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import SelectPeriod from '@/components/organisms/SelectPeriod.vue'
import ChartLineUnderstandingRelative, { ChartRecord } from '@/components/atoms/ChartLineUnderstandingRelative.vue'
import AccordionStudentHistory, { GradeHistories } from '@/components/organisms/AccordionStudentHistory.vue'
import AccordionStudentHistoryNursing from '@/components/organisms/nursing/AccordionStudentHistory.vue'
import SubjectSituationWebApi from '@/mixins/history/SubjectSituationWebApi'
import SubjectSituationWebApiV3 from '@/mixins/historyv3/SubjectSituationWebApi'
import LoadEnableSubjectsApi from '@/mixins/utils/LoadEnableSubjectsApi'
import TeacherHistoryBottomPanel from '@/components/organisms/TeacherHistoryBottomPanel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import LearningType, { LearningTypePage } from '@/mixins/historyv3/LearningType'
import FilterMode from '@/components/organisms/v3/FilterMode.vue'
import NursingBranch from '@/mixins/v3/NursingBranch'

@Component({
  components: {
    StudentHistorySidebar,
    TeacherHistoryHeader,
    ColoredBox,
    ChartLineUnderstandingRelative,
    SelectSubject,
    TitleBase,
    TitleTextBase,
    SelectPeriod,
    AccordionStudentHistory,
    TeacherHistoryBottomPanel,
    ButtonBase,
    SubjectSituationWebApiV3,
    FilterMode,
    AccordionStudentHistoryNursing,
  },
})
export default class SubjectSituation extends Mixins(
  SubjectSituationWebApi,
  LoadEnableSubjectsApi,
  LearningType,
  NursingBranch
) {
  // クラスモード: 通常授業
  private readonly classMode = 'TJ'

  private userId = Vue.prototype.$cookies.get('dataGdls').student?.userId || 0

  @Ref() subjectSituationWebApiV3!: SubjectSituationWebApiV3

  private selectedSubject = this.$route.query.selectedSubject ? String(this.$route.query.selectedSubject) : ''

  // 教科プルダウン表示対象、教科コードの配列 ex.=> ['su', 'ei', 'ko']
  private enableSubjects: string[] = []

  private selectedTerm = '1week'

  private modeCode = ''

  private handleChangeMode(code: string) {
    this.modeCode = code
  }
  @Watch('modeCode')
  private async watchSelectMode() {
    Vue.prototype.$loading.start()
    await this.loadChartRecords()
    await this.loadGradeHistories()
    Vue.prototype.$loading.complete()
  }

  private get branchId() {
    return Vue.prototype.$cookies.get('dataGdls').branchId
  }

  private get studentUserId() {
    return Vue.prototype.$cookies.get('dataGdls').student.userId
  }

  private get studentGradeCode() {
    return Vue.prototype.$cookies.get('dataGdls').student.gradeCode
  }

  private get enableSelectedSubject() {
    return this.enableSubjects.includes(this.selectedSubject) ? this.selectedSubject : ''
  }

  // チャートデータ
  private chartDatas: ChartRecord[] = []

  // 学習結果
  private result = {
    questionCount: 0,
    correctCount: 0,
    hour: 0,
    minute: 0,
  }

  // 表示日付
  private dateSteps: string[] = []

  // 項目毎の累計理解度 rateの位置は最大値の位置で表示する
  private gradeHistories: GradeHistories[] = []

  private async mounted() {
    Vue.prototype.$loading.start()
    await this.loadEnableSubjects()
    if (!this.isNursingBranch) {
      await this.loadChartRecords()
      await this.loadGradeHistories()
    }
    Vue.prototype.$loading.complete()
  }

  // 期間プルダウン変更時
  private async onChangeTerm() {
    Vue.prototype.$loading.start()
    // チャート情報のみ取得
    await this.loadChartRecords()
    Vue.prototype.$loading.complete()
  }

  // 教科プルダウン変更時
  private async onChangeSubject() {
    Vue.prototype.$loading.start()
    // チャート情報と理解度リストどちらも取得
    await this.loadChartRecords()
    await this.loadGradeHistories()
    Vue.prototype.$loading.complete()
  }

  /**
   * チャート情報取得
   */
  private async loadChartRecords() {
    if (this.selectedSubject == '') return
    const params: any = {
      userId: this.studentUserId,
      term: this.selectedTerm,
      subjectCode: this.selectedSubject,
    }
    if (!this.callApiV3) {
      params.classMode = this.classMode
    }
    if (this.modeCode && this.isV3) {
      params.classModeCode = this.modeCode
    }
    if (this.isV3 && this.currentLearningType === LearningTypePage.Normal) {
      params.classModeCode = 'AI'
    }
    const { chartDatas, result, dateSteps } = this.callApiV3
      ? await this.subjectSituationWebApiV3.loadChartRecordsApi(params)
      : await this.loadChartRecordsApi(params)
    // チャート用のスコア配列をセット
    this.chartDatas = chartDatas
    // 学習結果セット
    this.result = result
    // 表示日付セット
    this.dateSteps = dateSteps
  }

  private addPropertyModecode(curriculums: GradeHistories[]) {
    if (this.isV3 && this.currentLearningType === LearningTypePage.Custom) {
      curriculums.forEach((curriculum) => {
        curriculum.histories.forEach((history: any) => {
          history.modeCode = this.modeCode
        })
      })
      return curriculums
    }
    return curriculums
  }

  /**
   * 項目毎の理解度取得
   */
  private async loadGradeHistories() {
    if (this.selectedSubject == '') return
    const params: any = {
      userId: this.studentUserId,
      subjectCode: this.selectedSubject,
    }
    if (!this.callApiV3) {
      params.classMode = this.classMode
    }
    if (this.modeCode && this.isV3) {
      params.classModeCode = this.modeCode
    }
    if (this.isV3 && LearningTypePage.Normal === this.currentLearningType) {
      params.classModeCode = 'AI'
    }
    const result = this.callApiV3
      ? await this.subjectSituationWebApiV3.loadGradeHistoriesApi(params)
      : await this.loadGradeHistoriesApi(params)
    // 項目毎の累計理解度セット
    this.gradeHistories = this.addPropertyModecode(result['curriculums'])
  }

  /**
   * サービス設定で有効な教科を取得し、教科プルダウンに反映
   */
  private async loadEnableSubjects() {
    // プルダウン候補の取得
    this.enableSubjects = (await this.loadGdlsSubjectsApi(this.branchId, this.studentUserId)).map((subject) => {
      return subject.code
    })
    // デフォルトの教科セット
    this.selectedSubject = this.selectedSubject === '' ? this.enableSubjects[0] : this.selectedSubject
  }
}
