




























import { Component, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import { TEACHER_SETTING_TEXT } from '@/constants'

@Component({
  components: {
    ColoredBox,
    TitleBase,
    TitleTextBase,
  },
})
export default class Top extends Vue {
  private menuDataBasic = [
    { url: '/teacher/setting/v3/student', name: '生徒設定' },
    { url: '/teacher/setting/v3/student-service', name: '生徒利用可能サービス設定' },
    { url: '/teacher/setting/teacher', name: '講師・メンター設定' },
    { url: '/teacher/setting/v3/attribute', name: 'グループ設定' },
    { url: '/teacher/setting/school', name: '学校設定' },
    { url: '/teacher/setting/mode-default', name: '通常学習' },
  ]
  private menuDataCustom = [
    { url: '/teacher/setting/list-mode', name: 'モード確認・追加・変更' },
    { url: '/teacher/setting/mode-student', name: 'モード割り当て' },
    { url: '/teacher/setting/homework', name: '宿題作成' },
  ]
  private menuClassSetting = [
    { url: '/teacher/setting/class-name', name: '集団クラス名設定' },
    { url: '/teacher/setting/lesson', name: '授業設定' },
    { url: '/teacher/setting/timetable', name: '時間割設定' },
  ]
  private menuToolSetting = [
    { url: '/teacher/setting/download-content', name: 'コンテンツダウンロード' },
    { url: '/teacher/setting/upload-content', name: 'コンテンツアップロード' },
    { url: '/teacher/setting/debug-mode', name: 'デバックモード' },
  ]
  private isToolPage = Vue.prototype.$gdlsCookiesV3.isToolPage()
  private breadcrumbs = [{ text: TEACHER_SETTING_TEXT, active: true }]
}
