





















































































import { Component, Ref, Mixins, Vue } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import TableBase from '@/components/atoms/TableBase1110.vue'
import { GetStudentsResponseType } from '@/models/api/students'
import { SubjectType, SubjectItemType } from '@/models/api/studentServices'
import FileDropArea from '@/components/atoms/FileDropArea.vue'
import ModalExcelLoadErrors, { ExcelLoadError } from '@/components/organisms/ModalExcelLoadErrors.vue'
import LoadClassOptionsApi from '@/mixins/teacher/LoadClassOptionsApi'
import LoadStudentsApi from '@/mixins/v3/LoadStudentsApi'
import RadioToggle from '@/components/atoms/RadioToggle.vue'
import { ServiceCodeEnum, ServiceCodeType } from '@/types/teacher/service'
import { TEACHER_SETTING_TEXT } from '@/constants'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    ColoredBox,
    ButtonBase,
    InputWithLabel,
    SelectBase,
    TableBase,
    FileDropArea,
    ModalExcelLoadErrors,
    RadioToggle,
  },
})
export default class StudentInformation extends Mixins(LoadClassOptionsApi, LoadStudentsApi) {
  // Excelアップロードエリア表示ステータス
  private isShowExcelDropArea = false
  private isKnewtonMaintenanceMode = true

  private isProcessing = false
  private isOnOffCalendar = false
  private isOnOffOcrLlm = false
  private isDisabledOnOffCalendar = false
  private isOnOffGpt = false
  private isDisabledOnOffGpt = false
  private isDisabledOnOffOcrLlm = false
  /*
   * ボタンの色（ボタン操作制御）
   * メンテナンスモードで状態切り替え
   */
  private get colortypeUploadBtn(): string {
    return this.isKnewtonMaintenanceMode ? 'disabled' : 'blue'
  }

  /**
   * ファイルドロップイベント
   */
  private async onDropFile(file: File) {
    if (await this.isMaintenanceMode()) return
    Vue.prototype.$logger.log('onDropFile')
    this.uploadStudentsExcel(file)
  }

  /**
   * 設定Excelを元に処理を TODO: 生徒利用可能サービス設定(Excelファイル)用に調整する
   */
  private uploadStudentsExcel(file: File) {
    const headers = { 'Content-Type': 'multipart/form-data' }
    const params = new FormData()
    params.append('excelFile', file)
    params.append('academyId', this.academyId)

    this.isProcessing = true
    Vue.prototype.$loading.start()

    Vue.prototype.$http.httpWithToken
      .post('/studentServices/importExcel', params, { headers: headers })
      .then(() => {
        Vue.prototype.$logger.log('uploadStudentsExcel success')
        alert('生徒登録が完了しました。')
      })
      .catch((error: any) => {
        if (error?.response?.data?.status === 400) {
          const errors: ExcelLoadError[] = error?.response?.data?.errors || []
          this.showExcelLoadErrors(errors)
        }
      })
      .finally(() => {
        Vue.prototype.$loading.complete()
        this.isShowExcelDropArea = false
        this.search()
        this.isProcessing = false
      })
  }

  @Ref() modalExcelLoadErrors!: ModalExcelLoadErrors

  private showExcelLoadErrors(errors: ExcelLoadError[]): void {
    this.modalExcelLoadErrors.showErrors(errors)
  }

  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  private academyId = Vue.prototype.$cookies.get('dataGdls').academyId

  private searchCount = 0

  private breadcrumbs = [
    { text: TEACHER_SETTING_TEXT, href: '/teacher/setting/top' },
    { text: '生徒利用可能サービス', active: true },
  ]

  private nickname = ''
  private studentCode = ''

  private schoolOptionDatas: { text: string; value: number }[] = []
  private schoolSelectedData: number | null = null

  private gradeOptionDatas: { text: string; value: number }[] = []
  private gradeSelectedData: number | null = null

  private classOptionDatas: { text: string; value: string }[] = []
  private classSelectedData: string | null = null

  private attributeOptionDatas: { text: string; value: number }[] = []
  private attributeSelectedData: number | null = null

  private serviceSubjects: {
    serviceId: number
    serviceCode: string
    subjects: { id: number; code: ServiceCodeType; enabled: boolean }[]
  }[] = []

  private studentTableBaseItems: {
    studentId: { value: number; checked: boolean }
    name: string
  }[] = []

  private studentTableBaseFields = [
    { key: 'studentId', label: '' },
    { key: 'name', label: '氏名・ID' },
  ]

  private studentTableBaseCheckboxs = ['studentId']

  private serviceTableBaseItems: {
    serviceName: string
    serviceCode: string
    su: SubjectItemType
    ei: SubjectItemType
    ko: SubjectItemType
    ri: SubjectItemType
    sh: SubjectItemType
  }[] = []
  private serviceTableBaseFields = [
    { key: 'serviceName', label: 'サービス名' },
    { key: 'su', label: '算数/数学' },
    { key: 'ei', label: '英語' },
    { key: 'ko', label: '国語' },
    { key: 'ri', label: '理科' },
    { key: 'sh', label: '社会' },
  ]

  private serviceTableBaseToggles = ['su', 'ei', 'ko', 'ri', 'sh']

  private async mounted() {
    Vue.prototype.$loading.start()
    await this.loadInitialize()
    Vue.prototype.$loading.complete()
  }

  // 初回情報読み込み
  private async loadInitialize() {
    // 学研連携対象の教室であれば、isGlinkBranch:true となる。各種生徒系の編集制御に使用
    // await this.isGlinkBranchApi(this.branchId)
    // this.setParamsFromQuery()
    // await this.loadSelectDatas()
    // await this.loadStudentDatas()

    await this.loadGrades()
    await this.loadAttributes()
    await this.loadSchools()
    await this.loadClasses()
    await this.loadStudentDatas()
    await this.loadServiceDatas()
    await this.loadKnewtonMaintenance()
  }

  // 学年情報プルダウン読み込み
  private async loadGrades() {
    await Vue.prototype.$http.httpWithToken.get(`/grades`).then((res: any) => {
      this.gradeOptionDatas = res.data.map((grade: { id: number; name: string; code: string; sortNum: string }) => {
        return {
          text: grade.name,
          value: grade.id,
        }
      })
    })
  }

  // 属性情報プルダウン読み込み
  private async loadAttributes() {
    await Vue.prototype.$http.httpWithToken
      .get(`/v3/student_groups`, { params: { branchId: this.branchId, withLinked: true } })
      .then((res: any) => {
        this.attributeOptionDatas = res.data.studentGroups.map((userAttributes: { id: number; title: string }) => {
          return {
            text: userAttributes.title,
            value: userAttributes.id,
          }
        })
      })
  }

  // 学校情報プルダウン読み込み
  private async loadSchools() {
    await Vue.prototype.$http.httpWithToken
      .get(`/schools`, { params: { branchId: this.branchId } })
      .then((res: any) => {
        this.schoolOptionDatas = res.data.schools.map((school: { id: number; name: string }) => {
          return {
            text: school.name,
            value: school.id,
          }
        })
      })
  }

  // クラス・コマ情報プルダウン読み込み
  private async loadClasses() {
    this.classOptionDatas = await this.loadClassOptions(this.branchId)
  }

  // 検索
  private async search() {
    Vue.prototype.$loading.start()
    await this.loadStudentDatas()
    this.resetServiceSubject()
    Vue.prototype.$loading.complete()
  }

  // 生徒検索用パラメータ生成
  private getStudentSearchParams(): any {
    const params = this.getSearchParamsBase(this.branchId)

    // 氏名
    if (this.nickname) {
      params['name'] = this.nickname
    }
    // 塾が管理する生徒ID（API上はstudentCode）
    if (this.studentCode) {
      params['studentCode'] = this.studentCode
    }
    // 学校
    if (this.schoolSelectedData) {
      params['schoolId'] = this.schoolSelectedData
    }
    // 学年
    if (this.gradeSelectedData) {
      params['gradeId'] = this.gradeSelectedData
    }
    // クラス
    if (this.classSelectedData) {
      const classData = this.classSelectedData.split(',')
      params['isGroup'] = classData[0]
      params['classSettingId'] = classData[1]
      if (classData.length > 2) {
        params['period'] = classData[2]
      }
    }
    // 属性
    if (this.attributeSelectedData) {
      params['userAttributeId'] = this.attributeSelectedData
    }
    return params
  }

  // 生徒情報読み込み・検索
  private async loadStudentDatas() {
    // 検索パラメータ設定
    const params = this.getStudentSearchParams()
    // 生徒情報を取得
    const data: GetStudentsResponseType = await this.loadStudents(params)
    this.searchCount = data.count
    this.studentTableBaseItems = data.students.map((student) => {
      return {
        studentId: {
          value: student.studentId,
          checked: false,
        },
        name: `${student.nickname}/${student.studentCode}`,
      }
    })
  }

  // サービス情報読み込み
  private async loadServiceDatas() {
    const params = { branchId: this.branchId, academyId: this.academyId }
    await Vue.prototype.$http.httpWithToken.get(`/v3/branches/services`, { params: params }).then((res: any) => {
      const { canChangeCalendarAndGradeBook, canChangeEncouragementText, canUseOcrLlm } = res.data
      this.isDisabledOnOffCalendar = !canChangeCalendarAndGradeBook
      this.isDisabledOnOffGpt = !canChangeEncouragementText
      this.isDisabledOnOffOcrLlm = !canUseOcrLlm
      // 全教科が無効のサービスは非表示
      let services = (res.data.branches[0]?.services || []).filter(
        (service: { id: number; code: string; name: string; subjects: SubjectType[] }) => {
          return service.subjects.some((subject: SubjectType) => {
            return subject.isEnabled === true
          })
        }
      )
      this.serviceSubjects = services.map(
        (service: { id: number; code: string; name: string; subjects: SubjectType[] }) => {
          return {
            serviceId: service.id,
            serviceCode: service.code,
            subjects: service.subjects.map((subject: SubjectType) => {
              return {
                id: subject.subjectId,
                code: subject.subjectCode,
                enabled: false,
              }
            }),
          }
        }
      )
      services = services.filter((services: any) => {
        return !(
          services.code === ServiceCodeEnum.CALENDAR_SERVICE_CODE || services.code === ServiceCodeEnum.GPT_SERVICE_CODE || services.code === ServiceCodeEnum.OCR_LLM
        )
      })
      this.serviceTableBaseItems = services.map(
        (service: { id: number; code: string; name: string; subjects: SubjectType[] }) => {
          return {
            serviceName: service.name,
            serviceCode: service.code,
            su: this.subjectItem(service, '算数/数学'),
            ei: this.subjectItem(service, '英語'),
            ko: this.subjectItem(service, '国語'),
            ri: this.subjectItem(service, '理科'),
            sh: this.subjectItem(service, '社会'),
          }
        }
      )
    })
  }

  // サービス情報と教科名からSubjectItemType型のobjectに値をセットする
  private subjectItem(
    service: { id: number; code: string; name: string; subjects: SubjectType[] },
    subjectName: string
  ): SubjectItemType {
    const subject = service.subjects.find((item) => {
      return item.name === subjectName
    })
    const disabled = !subject?.isEnabled || false

    return {
      checked: false,
      on: '',
      off: '',
      id: subject?.branchServiceId || 0,
      onChange: {
        func: this.changeServiceSubject,
        funcParams: { serviceId: service.id, subjectId: subject?.subjectId || 0 },
      },
      disabled: disabled,
      radioToggleConfirm: this.radioToggleConfirm(service.code, subject?.subjectCode || ''),
      comment: disabled ? '利用不可' : '',
    }
  }

  private radioToggleConfirm(serviceCode: string, subjectCode: string): { [key: string]: any } {
    return {
      onClick: this.changeConnectedToggle,
      onClickParams: {
        serviceCode: serviceCode,
        subjectCode: subjectCode,
      },
    }
  }

  private changeConnectedToggle(params: { serviceCode: string; subjectCode: string }): boolean {
    const serviceCode = params.serviceCode
    const subjectCode = params.subjectCode
    // GDLS変更
    if (serviceCode === 'gdls') {
      const gdlsItems = this.serviceTableBaseItems.find((item) => item.serviceCode === 'gdls')
      if (gdlsItems && gdlsItems[subjectCode].checked) {
        // GDLSがOFFになる場合
        const gakkenItems = this.serviceTableBaseItems.find((item) => item.serviceCode === 'gakken')
        if (gakkenItems && gakkenItems[subjectCode].checked) {
          // 対応する学研動画をOFFに
          gakkenItems[subjectCode].checked = false
          // 学研サービス・教科毎用の有効無効切り替え
          const gakkenServiceSubject = this.serviceSubjects.find((serviceSubject: { serviceCode: string }) => {
            return serviceSubject.serviceCode === 'gakken'
          })
          if (gakkenServiceSubject) {
            const gakkenSubject = gakkenServiceSubject.subjects.find((subject: { code: string }) => {
              return subject.code === subjectCode
            })
            if (gakkenSubject) gakkenSubject.enabled = false
          }
        }
      }
    }
    // 学研動画変更
    else if (serviceCode === 'gakken') {
      const gakkenItems = this.serviceTableBaseItems.find((item) => item.serviceCode === 'gakken')
      if (gakkenItems && !gakkenItems[subjectCode].checked) {
        // 学研動画がONになる場合
        const gdlsItems = this.serviceTableBaseItems.find((item) => item.serviceCode === 'gdls')
        if (gdlsItems && !gdlsItems[subjectCode].checked) {
          // 対応するGDLSがOFFの場合アラートを出してOFFに戻す
          alert('学研動画をONにする場合はGDLSをONにする必要があります')
          return false
        }
      }
    }
    return true
  }

  // サービス・教科毎の有効無効切り替え
  private changeServiceSubject(params: { serviceId: number; subjectId: number }, enabled: boolean) {
    const serviceSubject = this.serviceSubjects.find((serviceSubject: { serviceId: number }) => {
      return serviceSubject.serviceId === params.serviceId
    })
    if (serviceSubject) {
      const subject = serviceSubject.subjects.find((subject: { id: number }) => {
        return subject.id === params.subjectId
      })
      if (subject) {
        subject.enabled = enabled
      }
    }
  }

  // サービス・教科毎の有効無効リセット
  private resetServiceSubject() {
    this.serviceTableBaseItems.forEach(
      (item: {
        serviceName: string
        serviceCode: string
        su: SubjectItemType
        ei: SubjectItemType
        ko: SubjectItemType
        ri: SubjectItemType
        sh: SubjectItemType
      }) => {
        item.su.checked = false
        item.ei.checked = false
        item.ko.checked = false
        item.ri.checked = false
        item.sh.checked = false
      }
    )

    this.serviceSubjects.forEach((serviceSubject: { subjects: { enabled: boolean }[] }) => {
      serviceSubject.subjects.forEach((subject: { enabled: boolean }) => {
        subject.enabled = false
      })
    })
  }

  // チェックボックスがチェックされた生徒のIDを取得する
  private getCheckedStudentIds(): number[] {
    return this.studentTableBaseItems
      .filter((item) => item.studentId.checked)
      .map((item) => {
        return item.studentId.value
      })
  }

  // 生徒毎の利用可能サービス登録
  private async saveService() {
    const studentIds = this.getCheckedStudentIds()
    if (studentIds.length === 0) {
      alert('対象生徒を選択してください')
      return
    }
    this.serviceSubjects = this.serviceSubjects.map((serviceSubject) => {
      if (
        serviceSubject.serviceCode === ServiceCodeEnum.CALENDAR_SERVICE_CODE ||
        serviceSubject.serviceCode === ServiceCodeEnum.GPT_SERVICE_CODE ||
        serviceSubject.serviceCode === ServiceCodeEnum.OCR_LLM
      ) {
        const subjects: any = serviceSubject.subjects.map((subject) => ({
          ...subject,
          enabled:
            serviceSubject.serviceCode === ServiceCodeEnum.CALENDAR_SERVICE_CODE
              ? this.isOnOffCalendar
              : serviceSubject.serviceCode === ServiceCodeEnum.OCR_LLM ? this.isOnOffOcrLlm : this.isOnOffGpt,
        }))
        return { ...serviceSubject, subjects }
      }
      return serviceSubject
    })
    const params = { studentIds: studentIds, serviceSubjects: this.serviceSubjects, branchId: this.branchId }
    Vue.prototype.$loading.start()
    await Vue.prototype.$http.httpWithToken
      .post('/studentServices/save', params)
      .then(() => {
        alert('登録しました。')
      })
      .catch(() => {
        alert('エラーが発生しました')
      })
    Vue.prototype.$loading.complete()
  }

  // xlsxダウンロード
  private async download() {
    const studentIds = this.getCheckedStudentIds()
    if (studentIds.length === 0) {
      alert('対象生徒を選択してください')
      return
    }

    const params = { studentIds: studentIds, branchId: this.branchId }
    Vue.prototype.$loading.start()
    await Vue.prototype.$http.httpWithToken
      .get(`/studentServices/xlsx`, {
        responseType: 'blob',
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        params: params,
      })
      .then((res: any) => {
        const blob = new Blob([res.data], { type: res.data.type })
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(blob)
        a.download = this.getFilename(res.headers['content-disposition'])
        a.click()
      })
      .catch(() => {
        alert('エラーが発生しました')
      })
    Vue.prototype.$loading.complete()
  }

  // ヘッダー情報からファイル名を取得する
  private getFilename(contentDisposition: string): string {
    const filenameRegex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/
    const matches = filenameRegex.exec(contentDisposition)
    if (matches != null && matches[1]) {
      return decodeURI(matches[1].replace(/['"]/g, '').replace('utf-8', ''))
    } else {
      return 'student_service.xlsx'
    }
  }

  // Knewtonメンテナンスモードかの取得
  private async loadKnewtonMaintenance() {
    await Vue.prototype.$http.httpWithToken.get('/knewtonMaintenances/maintenance').then((res: any) => {
      this.isKnewtonMaintenanceMode = res.data.ongoing
    })
  }

  // Knewtonメンテナンスモードか否かの判定
  private async isMaintenanceMode() {
    // 既にメンテナンスモードの場合は即リターン
    if (this.isKnewtonMaintenanceMode) return this.isKnewtonMaintenanceMode

    // 非メンテナンスモード時は再取得し、アラートを表示してからリターン
    await this.loadKnewtonMaintenance()

    if (this.isKnewtonMaintenanceMode) {
      alert('現在メンテナンス中のため、生徒の登録機能はご利用いただけません。')
    }

    return this.isKnewtonMaintenanceMode
  }

  // アップロードボタン押下時イベント
  private async expandUploadArea() {
    if (await this.isMaintenanceMode()) return
    this.isShowExcelDropArea = !this.isShowExcelDropArea
  }
}
