























import { Component, Mixins, Vue, Prop } from 'vue-property-decorator'
import BaseQuiz from '@/mixins/quiz/BaseQuiz'
import { AnswerType, QuestionStoreType } from '@/types/quiz/units'
import { TIME_NEXT_QUESTION } from '@/constants'
import PlayAudioButton from '@/components/molecules/PlayAudioButon.vue'

@Component({
  components: {
    PlayAudioButton,
  },
})
export default class Question extends Mixins(BaseQuiz) {
  @Prop()
  private question!: QuestionStoreType
  @Prop()
  private submitAnswer!: (answer: AnswerType, time: number) => void
  @Prop()
  private selectAnswer!: AnswerType | null

  private get answers() {
    const listAnswer = this.shuffleAnswers(this.question.answers)
    return listAnswer
  }

  private get isAudio() {
    return !!this.question?.audios.length
  }

  private get playingAudio(): boolean {
    return this.$store.getters['media/playingAudio']
  }

  private replaceBreakLineHtml(text: string) {
    return text.replace(/\n/g, '<br>')
  }

  private getClassAnswer(answer: AnswerType) {
    return {
      correct: !!this.selectAnswer && answer.isCorrectText,
      incorrect: !!this.selectAnswer && this.selectAnswer?.choiceText === answer.choiceText && !answer.isCorrectText,
      disabled: answer?.disabled,
    }
  }

  private chooseAnswer(answer: AnswerType) {
    if (this.selectAnswer || answer?.disabled) {
      return
    }
    if (answer.isCorrectText) {
      this.submitAnswer(answer, TIME_NEXT_QUESTION)
      Vue.prototype.$check.correct('question-frame', TIME_NEXT_QUESTION)
    } else {
      this.submitAnswer(answer, TIME_NEXT_QUESTION * 2)
      Vue.prototype.$check.incorrect('question-frame', TIME_NEXT_QUESTION * 2)
    }
  }

  shuffleAnswers(answers: AnswerType[]) {
    const answersShuffle = [...answers]
    for (let i = answersShuffle.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[answersShuffle[i], answersShuffle[j]] = [answersShuffle[j], answersShuffle[i]]
    }
    return answersShuffle
  }
}
