


























































import { Component, Vue, Watch } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import RadioGroup from '@/components/molecules/RadioGroup.vue'
import { TEACHER_SETTING_TEXT } from '@/constants'
import { Environment, Mode } from './DownloadContent.vue'
import { AxiosError } from 'axios'

const GENERATE_CHILD_MASTER_VALUE = 1
const INSERT_CHILD_MASTER_VALUE = 2

type UpdateType = 'create' | 'update' | 'delete'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    ColoredBox,
    ButtonBase,
    RadioGroup,
  },
})
export default class UploadContent extends Vue {
  private breadcrumbs = [
    { text: TEACHER_SETTING_TEXT, href: '/teacher/setting/top' },
    { text: 'コンテンツアップロード', active: true },
  ]
  private environmentType: Environment = 'method'
  private modeType: Mode = 'TJ'
  private subjectType = '0'
  private methodType = 'update'

  private fileType = 1
  private fileUpload: File | null = null

  @Watch('fileType')
  onDateChanged(newFileType: number) {
    if (newFileType === INSERT_CHILD_MASTER_VALUE) {
      this.environmentType = 'method'
      this.fileUpload = null
    }
  }

  private getFilename(contentDisposition: string): string {
    const filenameRegex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/
    const matches = filenameRegex.exec(contentDisposition)
    if (matches != null && matches[1]) {
      return decodeURI(matches[1].replace(/['"]/g, '').replace('utf-8', ''))
    } else {
      return 'question_master_data.zip'
    }
  }

  private async downloadExcel() {
    const formData = new FormData()
    if (this.fileType === GENERATE_CHILD_MASTER_VALUE) {
      if (this.fileUpload) {
        formData.append('master_file', this.fileUpload)
      } else {
        alert(' アップロードファイルを選択してください')
        return
      }
    }
    const textConfirm = `ファイルをアップロードする前に、画面上で選択された項目を確認してください。
    ・環境：${this.environmentOptions.find(({ value }) => value === this.environmentType)?.label}
    ・コンテンツ種類： ${this.modeOptions.find(({ value }) => value === this.modeType)?.label}
    ・教科：${this.subjectOptions.find(({ value }) => value === this.subjectType)?.label}
    ・更新内容：${this.methodRadios.find(({ value }) => value === this.methodType)?.label}
    この内容でよろしいでしょうか？`
    const isConfirm = confirm(textConfirm)
    if (!isConfirm) return

    formData.append('environment', this.environmentType)
    formData.append('subject_id', this.subjectType)
    formData.append('curriculum_mode_code', this.modeType)
    formData.append('update_type', this.methodType)

    Vue.prototype.$loading.start()
    try {
      const headers = { 'Content-Type': 'multipart/form-data' }
      const res = await Vue.prototype.$http.httpWithToken.post(
        this.fileType === GENERATE_CHILD_MASTER_VALUE ? '/v3/question_data/generate_data' : 'v3/question_data/import',
        formData,
        {
          headers: headers,
          responseType: 'blob',
        }
      )
      if (this.fileType === INSERT_CHILD_MASTER_VALUE) {
        alert('一枚ファイルのアップロードに成功しました')
        Vue.prototype.$loading.complete()
        return
      }
      const blob = new Blob([res.data], { type: res.data.type })
      const a = document.createElement('a')
      a.href = window.URL.createObjectURL(blob)
      a.download = this.getFilename(res.headers['content-disposition'])
      a.click()
    } catch (error) {
      const e = error as AxiosError<{ message: string; status: number }>
      if (
        e.response &&
        e.response.data.status === 400 &&
        typeof e.response.data.message === 'string' &&
        e.response.data.message?.startsWith('File not found:')
      ) {
        alert(
          '対応する子ファイルがまだ存在しないため、DBに投入を行うことができません。子ファイルを反映できるために、子マスタ生成画面に戻り、対応する親ファイルをアップロードしてください。'
        )
        return
      } else if (e.response && e.response.data.message) {
        alert(e.response.data.message)
        return
      }
      alert((error as Error).message)
    }

    Vue.prototype.$loading.complete()
  }

  private subjectOptions: { label: string; value: string }[] = []
  private fileTypeRadios = [
    {
      label: '子マスタ生成',
      value: GENERATE_CHILD_MASTER_VALUE,
    },
    {
      label: '子マスタ投入',
      value: INSERT_CHILD_MASTER_VALUE,
    },
  ]
  private environmentOptions: { label: string; value: Environment }[] = [
    {
      label: 'メソッド環境',
      value: 'method',
    },
    {
      label: 'ステージング環境',
      value: 'staging',
    },
    {
      label: '本番環境',
      value: 'production',
    },
  ]

  private modeOptions: { label: string; value: Mode }[] = [
    {
      label: 'GDLS通常学習',
      value: 'TJ',
    },
  ]

  private methodRadios: { label: string; value: UpdateType }[] = [
    {
      label: '更新',
      value: 'update',
    },
    {
      label: '追加',
      value: 'create',
    },
    {
      label: '削除',
      value: 'delete',
    },
  ]

  private async mounted() {
    Vue.prototype.$loading.start()
    const subjects = await Vue.prototype.$http.httpWithToken.get('/subjects')
    this.subjectType = subjects.data?.[0].id
    this.subjectOptions = subjects.data.map(({ name, code, id }: { name: string; code: string; id: number }) => ({
      label: `${name}（${code}）`,
      value: id,
    }))
    Vue.prototype.$loading.complete()
  }
}
