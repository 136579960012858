





























import { Component, Mixins, Vue } from 'vue-property-decorator'
import ImageRound from '@/components/atoms/ImageRound.vue'
import LabelBase from '@/components/atoms/LabelBase.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import LearningType, { LearningTypePage } from '@/mixins/historyv3/LearningType'
import NursingBranch from '@/mixins/v3/NursingBranch'
@Component({
  components: {
    ImageRound,
    LabelBase,
    ButtonBase,
  },
})
export default class StudentHistorySidebar extends Mixins(LearningType, NursingBranch) {
  // 生徒情報
  private student: {
    userId: number
    nickname: string
    iconUrl: string
    studentId: number
    studentCode: string
    schoolName: string
    gradeName: string
  } = Vue.prototype.$cookies.get('dataGdls').student || {}

  private HISTORY_MODE = {
    NORMAL: {
      label: '通常',
      value: 1,
      url: '/teacher/history/normal/subject-situation',
    },
    TEST: {
      label: '対策',
      value: 2,
      url: '/teacher/history/test/step-situation',
    },
    SEMINAR: {
      label: '講習会',
      value: 3,
      url: '/teacher/history/seminar/target-situation',
    },
    ENTRANCE: {
      label: '入試',
      value: 4,
      url: '/teacher/history/entrance/pretest-situation',
    },
    ASSIST_MATERIAL: {
      label: '補助',
      value: 5,
      url: '/teacher/history/assist-material/lesson-situation',
    },
    CUSTOM: {
      label: 'カスタム',
      value: 6,
      url: '/teacher/history/custom/subject-situation',
    },
    NORMAL_V1: {
      label: '通常',
      value: 7,
      url: '/teacher/history/normal-v1/learning-situation',
    },
    BACK_V1: {
      label: '旧学習履歴',
      value: 8,
      url: '/teacher/history/normal-v1/learning-situation',
    },
  }

  // 授業タイプ情報
  private get learningTypeDatas() {
    if (this.isNursingBranch) {
      return [this.HISTORY_MODE.CUSTOM]
    }
    if (!this.isV3) {
      return [
        this.HISTORY_MODE.NORMAL,
        this.HISTORY_MODE.TEST,
        this.HISTORY_MODE.SEMINAR,
        this.HISTORY_MODE.ENTRANCE,
        this.HISTORY_MODE.ASSIST_MATERIAL,
      ]
    }
    if (this.historyModeV3.includes(this.currentLearningType)) {
      const list = [
        this.HISTORY_MODE.NORMAL,
        this.HISTORY_MODE.CUSTOM,
        this.HISTORY_MODE.ENTRANCE,
        this.HISTORY_MODE.ASSIST_MATERIAL,
      ]
      return list
    }
    if (this.historyModeV1.includes(this.currentLearningType)) {
      return [this.HISTORY_MODE.NORMAL_V1, this.HISTORY_MODE.TEST, this.HISTORY_MODE.SEMINAR]
    }
    return []
  }

  /**
   * アイコンURL取得
   */
  private get iconUrl() {
    return this.student.iconUrl
  }

  private beforeCreate(): void {
    if (!Vue.prototype.$cookies.get('dataGdls').student) {
      this.$router.push('/teacher/history')
    }
  }

  /**
   * タイムライン画面へ遷移する際、検索条件クッキーを削除する
   */
  private async removeTimelineCookie() {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie['timelineConditions'] = null
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }
}
